import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { merge } from "lodash";
import * as React from "react";
import { defaults } from "react-chartjs-2";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Admin from "./admin/components/Admin";
import ScrollToTop from "./components/util/ScrollToTop";
import { setupStore } from "./store";
import { AppRedux } from "components/App/AppRedux";

import "./index.css";
import "./fonts.css";

const store = setupStore();

merge(defaults, { global: { defaultFontFamily: '"Good Sans",sans-serif' } });

const RedirectToDefaultLanguage = ({ match }: { match: any }) => {
  const path = match.params.path !== undefined ? match.params.path : "";
  return <Redirect to={`/en/${path}`} />; /* Nowadays site is always in English only. */
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact={false} path="/:lang(en)" component={AppRedux} />
          <Route exact={false} path="/admin" component={Admin} />
          <Route path="/:path*" component={RedirectToDefaultLanguage} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root") as HTMLElement
);
