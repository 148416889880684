import React from "react";
import { IRouterProps } from "util/RouterPropsInterface";
import { Container, Message } from "semantic-ui-react";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { activateUser } from "apiclient/ActivateUserAPI";
import { ActivationForm } from "./ActivationForm";
import { RouteComponentProps } from "react-router-dom";
import { IAppLayoutProps } from "components/App/App";
import MainLayout from "components/App/MainLayout";

import "./ActivationPage.css";
import { AxiosError } from "axios";

interface UrlParams {
  token: string;
  id: string;
}

interface ActivationPageProps extends RouteComponentProps<UrlParams> {
  loginUrl: string;
  layoutProps: IAppLayoutProps;
}

export const ActivationPage: React.FC<ActivationPageProps & IRouterProps> = props => {
  const [view, setView] = React.useState<"success" | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const { match, loginUrl } = props;
  const token = match.params.token;
  const id = match.params.id;
  const layoutPropsWithTitle = { ...props.layoutProps, title: "Choose password" };

  const handleSubmit = async (password: string) => {
    try {
      await activateUser(id, password, token);
      setView("success");
    } catch (err) {
      if (err) {
        const error = err as AxiosError<{ error: string | undefined }>;
        if (error.response && error.response.status === 422) setErrorMessage("Password too short");
        else if (error.response && error.response.data.error) setErrorMessage(error.response.data.error);
        else setErrorMessage("Something went wrong");
      } else {
        setErrorMessage("Something went wrong");
      }
    }
  };

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div>
        <SectionWrap className="huge-padding-top" toned={true}>
          {view !== "success" ? (
            <Container text={true}>
              <div className="set-password-wrap">
                <h1>Enter new password</h1>
                <ActivationForm onClick={handleSubmit} />
                {errorMessage && <Message warning={true}>{errorMessage}</Message>}
              </div>
            </Container>
          ) : (
            <Container text={true}>
              <div className="reset-password-wrap">
                <h1>Welcome!</h1>
                <p>
                  Your account has been activated. You can now login (<a href={loginUrl}>here</a>).
                </p>
              </div>
            </Container>
          )}
        </SectionWrap>
      </div>
    </MainLayout>
  );
};
