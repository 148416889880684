import React from 'react';
import { Button } from 'semantic-ui-react';

import './PortfolioSelector.css'

// TODO: Replace any with real types!
interface IPortfolioSelectorProps {
    numPortfolios: number
    activeIndex: number | undefined
    primary: boolean
    isCustom: boolean
    onChangePortfolio(index: number): void
    onChooseCustomPortfolio(): void
}

export default class PortfolioSelector extends React.Component<IPortfolioSelectorProps> {
    public makeButtons() {
        const buttons: any = [];
        const numPortfolios: any = this.props.numPortfolios;
        for (let i = 0; i < numPortfolios; i++) {
            buttons.push(
                <Button
                    style={{ whiteSpace: 'nowrap' }}
                    key={i}
                    content={i + 1}
                    active={this.props.activeIndex === i && !this.props.isCustom}
                    onClick={(e) => this.props.onChangePortfolio(i)}
                />
            );
        }
        buttons.push(<Button key={numPortfolios} content='Cust.' active={this.props.isCustom} onClick={this.props.onChooseCustomPortfolio} />);
        return buttons;
    }

    public render(): React.ReactNode {
        return (<div className='PortfolioSelector'>
            <Button.Group widths='8' className={this.props.primary ? 'primary-portfolio' : ''}>
                {this.makeButtons()}
            </Button.Group>
            <div className='risk-gradient' />
            <div className='risk-labels'>
                <span className='less-risk'>Less risk</span>
                <span className='more-risk'>More risk</span>
            </div>
        </div>);
    }
}
