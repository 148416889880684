import React from "react";
import { Grid, Zoom } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormField } from "semantic-ui-react";

interface Props {
  activationURL: string;
}

export const ActivationField: React.FC<Props> = props => {
  const { activationURL } = props;
  const [linkVisible, setLinkVisible] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Grid item xs={12}>
      <FormField>
        <label>Activation Link</label>
      </FormField>
      <div className="ui fluid action input">
        <button
          className="ui icon left button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setLinkVisible(!linkVisible);
          }}
        >
          {linkVisible && (
            <Zoom in={linkVisible}>
              <i aria-hidden="true" className="eye slash icon"></i>
            </Zoom>
          )}
          {!linkVisible && (
            <Zoom in={!linkVisible}>
              <i aria-hidden="true" className="eye icon"></i>
            </Zoom>
          )}
        </button>
        <input defaultValue={activationURL} disabled type={linkVisible ? "text" : "password"} />
        <CopyToClipboard text={activationURL} onCopy={() => handleCopy()}>
          <button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
            style={{ backgroundColor: "#163665", color: "white" }}
            className="ui icon right button"
          >
            {copied && (
              <Zoom in={copied}>
                <i aria-hidden="true" className="check icon"></i>
              </Zoom>
            )}
            {!copied && (
              <Zoom in={!copied}>
                <i aria-hidden="true" className="copy icon"></i>
              </Zoom>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </Grid>
  );
};
