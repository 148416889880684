import React from 'react';
import { Form } from 'semantic-ui-react';
import { SaveState, SaveButton } from './SaveButton';

interface Props {
  passwordIsChanging: boolean;
  passwordJustChanged: boolean;
  passwordChangeError?: string;
  onChangeClicked: (currentPassword: string, newPassword: string) => void;
}

export const ChangePasswordForm: React.FC<Props> = ({ onChangeClicked, passwordIsChanging, passwordJustChanged, passwordChangeError }) => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPass1, setNewPass1] = React.useState('');
  const [newPass2, setNewPass2] = React.useState('');

  const calculateSaveState = (): SaveState => {
    if (passwordIsChanging) {
      return SaveState.SAVING;
    }
    if (passwordChangeError !== undefined) {
      return SaveState.FAILED;
    }
    if (passwordJustChanged) {
      return SaveState.JUST_SAVED;
    }
    return SaveState.NORMAL;
  };

  const saveState: SaveState = calculateSaveState();
  const editDisabled = saveState === SaveState.SAVING;
  const canSubmit = () => currentPassword.length > 0 && newPass1.length > 0 && newPass1 === newPass2;

  const submitForm = () => {
    onChangeClicked(currentPassword, newPass1);
    setCurrentPassword('');
    setNewPass1('');
    setNewPass2('');
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input
          label="Current password"
          className="carnegie__input"
          width={8}
          value={currentPassword}
          type="password"
          disabled={editDisabled}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          label="New password"
          className="carnegie__input"
          width={8}
          value={newPass1}
          type="password"
          disabled={editDisabled}
          onChange={(e) => {
            setNewPass1(e.target.value);
          }}
        />
        <Form.Input
          label="Confirm new password"
          className="carnegie__input"
          width={8}
          value={newPass2}
          type="password"
          disabled={editDisabled}
          error={newPass1 !== newPass2}
          onChange={(e) => {
            setNewPass2(e.target.value);
          }}
        />
      </Form.Group>
      <SaveButton
        saveState={saveState}
        canSave={canSubmit()}
        text="Update password"
        savedText="Password updated!"
        errorText={passwordChangeError}
        onClick={submitForm}
      />
    </Form>
  );
};
