import IStringNumberMap from 'util/StringNumberMap';
import { AssetClassData } from 'apiclient/responsePayloads/AssetClass';
import _ from 'lodash';

export class FundAllocation {
  public funds: IStringNumberMap; // Weights distributed across funds (maps from fund id to weight number)

  constructor(funds: IStringNumberMap = {}) {
    this.funds = funds;
  }

  public verifyFundsMatchingAssetClass(assetClass: AssetClassData): boolean {
    for (const fundID of assetClass.funds) {
      if (!(fundID in this.funds)) {
        return false;
      }
    }
    return true;
  }

  // Return a new FundAllocation normalized to targetWeight
  public normalizedTo(targetWeight: number): FundAllocation {
    const result: FundAllocation = new FundAllocation();

    const totalWeight = this.getTotalWeight();
    if (totalWeight === targetWeight) {
      return this.clone();
    }

    const normalizationFactor = targetWeight / totalWeight;
    Object.keys(this.funds).forEach((fundID) => {
      result.funds[fundID] = this.funds[fundID] * normalizationFactor;
    });
    return result;
  }

  // Returns the sum of all fund weights
  public getTotalWeight(): number {
    return Object.keys(this.funds)
      .map((key) => this.funds[key])
      .reduce((a, b) => a + b);
  }

  public clone(): FundAllocation {
    return new FundAllocation({ ...this.funds });
  }

  public add(other: FundAllocation): FundAllocation {
    const result = this.clone();
    Object.keys(other.funds).forEach((fundID) => {
      if (!(fundID in result.funds)) {
        result.funds[fundID] = 0.0;
      }
      result.funds[fundID] += other.funds[fundID];
    });
    return result;
  }

  public toJSON(): any {
    return this.funds;
  }
}

export interface IFundAllocationOverrides {
  [assetClassId: string]: FundAllocation;
}

export function fundAllocationOverridesFromJSON(json: any): IFundAllocationOverrides {
  if (json === undefined || json === null) {
    return {};
  }
  const result: IFundAllocationOverrides = {};
  Object.keys(json).forEach((key) => {
    if (typeof key === 'string') {
      result[key] = new FundAllocation(_.mapValues(json[key], (w) => Number(w)));
    }
  });
  return result;
}
