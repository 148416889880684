import axios, { AxiosResponse, AxiosError } from 'axios';
import { SimulationData } from './responsePayloads/Simulation';

const makeDate = (obj: any): Date | undefined => obj === undefined ? undefined : new Date(obj)
const fixIncomingSimData = (simData: SimulationData): SimulationData => {
    simData.createdAt = makeDate(simData.createdAt)
    simData.modifiedAt = makeDate(simData.modifiedAt)
    return simData
}

export default class SimulationsAPI {
    /** Fetch all simulations for a user */
    public static fetchSimulations(userID: string, onSuccess: (simulations: SimulationData[]) => void, onFailure?: (err: Error) => void) {
        axios.get<SimulationData[]>(`/api/users/${userID}/simulations`)
            .then((res) => {
                const simDataList = res.data.map(simData => fixIncomingSimData(simData))
                onSuccess(simDataList);
            })
            .catch((error: AxiosError) => {
                console.log('Error fetching user simulations', error);
                if (onFailure !== undefined) {
                    onFailure(new Error('failed fetching simulations'))
                }
            });
    }

    /** Fetch a simulation by token */
    public static fetchSimulationByToken(token: string, onSuccess?: (simulation: SimulationData) => void, onFailure?: (err: Error) => void) {
        axios.get<SimulationData>(`/api/simulations/token/${token}`)
            .then((res) => {
                if (onSuccess !== undefined) {
                    onSuccess(fixIncomingSimData(res.data))
                }
            })
            .catch((error: AxiosError) => {
                if (onFailure !== undefined) {
                    onFailure(new Error('failed fetching result'))
                }
            });
    }

    /** Saves an existing simulation */
    public static updateSimulation(simulation: SimulationData, onSuccess?: () => void, onFailure?: (err: Error) => void) {
        if (!simulation.ownerId || !simulation.id) {
            if (onFailure) {
                onFailure(new Error("can't update simulation that does not have an ID or an owner ID"))
            }
            return
        }
        axios.put(`/api/users/${simulation.ownerId}/simulations/${simulation.id}`, simulation)
            .then((res: AxiosResponse) => {
                if (onSuccess) {
                    onSuccess()
                }
            })
            .catch((error: AxiosError) => {
                if (onFailure) {
                    onFailure(error)
                }
            })
    }

    /** Saves an previously unsaved simulation */
    public static createNewSimulation(simulation: SimulationData, onSuccess?: (id: string) => void, onFailure?: (err: Error) => void) {
        // TODO: onSuccess should ideally pass on the new simulation's ID or the entire new Simulation object, but the
        //       backend need to be updated to support it.

        if (!simulation.ownerId) {
            if (onFailure !== undefined) {
                onFailure(new Error("error: can't save new simulation that does not have an owner ID"))
            }
            return
        }
        axios.post(`/api/users/${simulation.ownerId}/simulations`, simulation)
            .then((res: AxiosResponse) => {
                if (onSuccess !== undefined) {
                    onSuccess(res.data.id);
                }
            })
            .catch((error: AxiosError) => {
                if (onFailure !== undefined) {
                    onFailure(error)
                }
            })
    }

    /** Deletes an existing simulation */
    public static deleteSimulation(ownerId: string, id: string, onSuccess?: () => void, onFailure?: (err: Error) => void) {
        const path = `/api/users/${ownerId}/simulations/${id}`;

        axios.delete(path)
            .then((res: AxiosResponse) => {
                if (onSuccess) {
                    onSuccess()
                }
            })
            .catch((error: AxiosError) => {
                if (onFailure) {
                    onFailure(error)
                }
            })
    }
}
