import * as React from "react";
import { Grid, Button, Icon, Message } from "semantic-ui-react";
import { UserProfile } from "apiclient/responsePayloads/User";
import { formatDate } from "util/misc-utils";
import { Simulation } from "apiclient/responsePayloads/Simulation";
import Auth from "modules/Auth";
import SaveSimulationModal from "components/ProcessNavigator/SaveSimulationModal";

import "./ReportHeaderSection.css";

interface IReportDetailsTableProps {
  simulation: Simulation;
  user?: UserProfile;
}

export function ReportHeaderDetailsTable(props: IReportDetailsTableProps) {
  let ownerName = null;
  if (props.simulation.isSaved()) {
    ownerName = `${props.simulation.ownerFirstName} ${props.simulation.ownerLastName}`;
  } else if (props.user !== undefined) {
    ownerName = `${props.user.firstName} ${props.user.lastName}`;
  }

  return (
    <Grid className="ReportHeaderDetailsTable">
      {props.simulation.isSaved() && (
        <Grid.Row>
          <Grid.Column width={4}>Simulation:</Grid.Column>
          <Grid.Column width={12}>{props.simulation.name}</Grid.Column>
        </Grid.Row>
      )}
      {ownerName !== null && (
        <Grid.Row>
          <Grid.Column className="ReportDescription" width={4}>
            Created by:
          </Grid.Column>
          <Grid.Column className="ReportDescription" width={12}>
            {ownerName}
          </Grid.Column>
        </Grid.Row>
      )}

      {props.simulation.ownerOrganization && (
        <Grid.Row>
          <Grid.Column width={4}>Organization:</Grid.Column>
          <Grid.Column width={12}>{props.simulation.ownerOrganization}</Grid.Column>
        </Grid.Row>
      )}
      {props.simulation.createdAt && (
        <Grid.Row>
          <Grid.Column width={4}>Date:</Grid.Column>
          <Grid.Column width={12}>{formatDate(props.simulation.createdAt)}</Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}

interface IReportHeaderProps {
  user: UserProfile;
  simulation: Simulation;
}

export default function ReportHeaderSection(props: IReportHeaderProps) {
  const [savePortfolioModalVisible, setSavePortfolioModalVisible] = React.useState(false);
  const isLoggedIn = new Auth(localStorage).isLoggedIn();

  return (
    <div className="ReportHeaderSection">
      <div>
        <span style={{ float: "right", marginLeft: "15px", maxWidth: "35%" }}>
          {props.simulation.isSaved() && (
            <Button
              className="DownloadButton carnegie__button"
              style={{ marginTop: 5 }}
              as="a"
              href={props.simulation.pdfURL()}
              target="_pdfreport"
            >
              <Icon style={{ color: "#fff" }} name="file pdf outline" />
              Download PDF
            </Button>
          )}
          {!props.simulation.isSaved() && (
            // eslint-disable-next-line
            <Message className="ReportDescription animated-underline" size="small">
              <button style={{ fontWeight: "bold" }} onClick={e => setSavePortfolioModalVisible(true)}>
                Save simulation
              </button>{" "}
              to download PDF report
            </Message>
          )}

          {isLoggedIn && (
            <SaveSimulationModal
              visible={savePortfolioModalVisible}
              onClose={() => {
                setSavePortfolioModalVisible(false);
              }}
              simulation={props.simulation}
            />
          )}
        </span>
        <span className="ReportDescription">
          Here you have a summary of your selected portfolio. If you want to print the report you must first make it into a pdf report.
        </span>
      </div>
      <ReportHeaderDetailsTable simulation={props.simulation} user={props.user} />
    </div>
  );
}
