import React from 'react';
import { Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

interface Props {
  onClick: (email: string) => void;
}

export const RecoverPasswordForm: React.FC<Props> = (props) => {
  const { onClick } = props;
  const [currentEmail, setCurrentEmail] = React.useState('');

  const canSubmit = () => currentEmail !== '';

  return (
    <Form>
      <Form.Group>
        <Form.Input
          label="Your E-mail"
          width={16}
          className="carnegie__input"
          value={currentEmail}
          type="text"
          onChange={(e) => {
            setCurrentEmail(e.target.value);
          }}
        />
      </Form.Group>
      <p className="animated-underline">
        Back to <Link to="/login">login</Link>.
      </p>
      <Form.Group>
        <button className="ui button carnegie__button" type="submit" disabled={!canSubmit()} onClick={() => onClick(currentEmail)}>
          Submit
        </button>
      </Form.Group>
    </Form>
  );
};
