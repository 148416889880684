import axios, { AxiosError, AxiosResponse } from "axios";
import { IFund } from "../apiclient/responsePayloads/Fund";

export const updateFund = async (updatedFund: IFund): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .put(`/api/funds/${updatedFund.id}`, updatedFund)
    .then(res => {
      return res;
    })
    .catch((err: AxiosError) => {
      console.log(err);
      return err;
    });
};

export const createFund = async (newFund: IFund): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .post(`/api/funds/${newFund.id}`, newFund)
    .then(res => {
      return res;
    })
    .catch((err: AxiosError) => {
      console.log(err);
      return err;
    });
};
