import { mixValues } from "util/misc-utils";

// From Excel file, "Sammanställning material opmdigital 2.0 v6.xlsx"
const SCALE_FACTORS_UPSIDE = [1, 0.98, 0.97, 0.96, 0.95, 0.94, 0.93, 0.92, 0.91, 0.9, 0.89, 0.88, 0.87, 0.86, 0.85, 0.84, 0.83, 0.82, 0.81, 0.8, 0.79];
const SCALE_FACTORS_DOWNSIDE = [1, 1.45, 1.4, 1.3, 1.2, 1.1, 1.01, 0.94, 0.88, 0.83, 0.78, 0.73, 0.68, 0.63, 0.58, 0.54, 0.5, 0.47, 0.44, 0.41, 0.38];

class PortfolioSimulator {
    public portfolioYield: number
    public portfolioRisk: number

    constructor(portfolioRisk: number, portfolioYield: number) {
        this.portfolioRisk = portfolioRisk;
        this.portfolioYield = portfolioYield;
    }

    public calculateZResult(initialInvestment: number, year: number, z: number): number {
        return Math.exp((Math.log(initialInvestment) + (Math.log(1 + this.portfolioYield) - Math.pow(this.portfolioRisk, 2) / 2) * year) + (z * this.portfolioRisk * Math.sqrt(year)));
    }

    public getScaleFactorForYear = (year: number, scaleFactorContribution: number, series: number[]): number => ((scaleFactorContribution > 0 && year < series.length) ? series[year] : 1.0);

    public getExpectedResult(initialInvestment: number, year: number): number {
        return initialInvestment * Math.exp(Math.log(1 + this.portfolioYield) * year)
    }

    public getLowResult(initialInvestment: number, year: number, scaleFactorContribution: number = 0): number {
        const scaleFactor = this.getScaleFactorForYear(year, scaleFactorContribution, SCALE_FACTORS_DOWNSIDE);
        return this.calculateZResult(initialInvestment, year, mixValues(-1.96, -1.96 * scaleFactor, scaleFactorContribution));
    }

    public getHighResult(initialInvestment: number, year: number, scaleFactorContribution: number = 0): number {
        const scaleFactor = this.getScaleFactorForYear(year, scaleFactorContribution, SCALE_FACTORS_UPSIDE);
        return this.calculateZResult(initialInvestment, year, mixValues(1.96, 1.96 * scaleFactor, scaleFactorContribution));
    }
}

export default PortfolioSimulator;
