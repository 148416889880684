import * as React from "react";
import { Location } from "history";
import { Redirect } from "react-router";
import _ from "lodash";
import { SimulationData, Simulation } from "apiclient/responsePayloads/Simulation";
import Auth from "modules/Auth";
import Spinner from "util/Spinner";
import { IAppLayoutProps } from "./App";

export interface ISimulationComponentProps {
    simulation: Simulation;
    onSimulationChange(simulation: SimulationData): void;
}

export interface ISavedSimulationWrapperProps {
    component: new (props: ISimulationComponentProps) => React.Component<ISimulationComponentProps>;
    simulations?: SimulationData[];
    layoutProps: IAppLayoutProps;
    extraProps: any;
    lang: string;
    simulationId: string;
    location: Location;
    onSetSavedSimulation: (s: SimulationData) => void;
}

export default class SavedSimulationWrapper extends React.Component<ISavedSimulationWrapperProps> {
    constructor(props: ISavedSimulationWrapperProps) {
        super(props);
        this.state = {};
    }

    public handleSimulationChange = (simulation: SimulationData) => {
        this.props.onSetSavedSimulation(simulation);
    };

    public render(): React.ReactNode {
        const auth = new Auth(localStorage);
        if (!auth.isLoggedIn()) {
            return <Redirect to={{ pathname: `/${this.props.lang}`, state: { from: this.props.location } }} />;
        }
        if (this.props.simulations === undefined) {
            return <Spinner />;
        }
        const simulation = this.getSimulation(this.getSimulationId());
        if (simulation === null) {
            return <div>Can't load simulation with id {this.getSimulationId()}.</div>;
        }
        const Component = this.props.component;

        const simWrapper = new Simulation(simulation);
        return (
            <Component
                simulation={simWrapper}
                onSimulationChange={this.handleSimulationChange}
                {...(this.props.extraProps, { layoutProps: this.props.layoutProps })}
            />
        );
    }

    private getSimulationId(): string {
        if (!this.props.simulationId) {
            throw new Error("missing simulation id");
        }
        return this.props.simulationId;
    }

    private getSimulation(id: string): SimulationData | null {
        if (this.props.simulations === undefined) {
            return null;
        }
        for (const sim of this.props.simulations) {
            if (sim.id === id) {
                return _.cloneDeep(sim);
            }
        }
        return null;
    }
}
