import React from "react";
import { Line } from "react-chartjs-2";
import _ from "lodash";

import { percentageTooltipY } from "util/misc-utils";
import { buildHistoricReturnGraphData, buildChartOptions } from "./HistoricReturnGraphDataBuilders";
import { HelpPopupIcon } from "components/HelpPopupIcon/HelpPopupIcon.js";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import { FundReturns } from "apiclient/FundReturns";

interface IHistoricReturnGraphProps {
  portfolio: AssetClassPortfolio;
  comparisonPortfolio?: AssetClassPortfolio;
  assetClassReturns: AssetClassReturns;
  fundReturns?: FundReturns;
  showDisclaimerIcon?: boolean;
  titleSuffix?: string;
}

function HistoricReturnGraph(props: IHistoricReturnGraphProps): JSX.Element | null {
  if (!props.portfolio) {
    return null;
  }

  const data = buildHistoricReturnGraphData(props.portfolio, props.comparisonPortfolio, props.assetClassReturns, props.fundReturns);
  const opts = buildChartOptions();
  _.set(opts, "tooltips.callbacks.label", percentageTooltipY);
  const hasFunds = props.fundReturns !== undefined;

  return (
    <div className="HistoricReturnGraph chart-section">
      {props.showDisclaimerIcon && (
        <HelpPopupIcon style={{ float: "right" }}>
          <p>
            {hasFunds && <strong>Asset class returns.</strong>} The graph displays the historic return of the portfolio based on the
            historic return of the underlying asset classes. The series is built on index data for each asset class and does not incorporate
            costs or possible out-/underperformance that can be achieved through investments in funds or other financial instruments.
          </p>
        </HelpPopupIcon>
      )}

      <h3 style={{ marginBottom: 15 }}>Historic Return {props.titleSuffix}</h3>
      <Line data={data} options={opts} />
    </div>
  );
}

export { HistoricReturnGraph };
