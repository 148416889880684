import * as math from "mathjs";

const LANGUAGE = "en";

function roundTwoDecimals(val: number): number {
  return Math.round(val * 100) / 100;
}

function fractionToRoundedPercent(val: number) {
  return Math.round(val * 10000) / 100;
}

function formatPortfolioPercentage(portfolio: any, funcName: string): string {
  if (portfolio == null) {
    return "-%";
  }
  return formatPercentage(portfolio[funcName]());
}

function toString(x: number, decimals = 2): string {
  const countDecimals = (value: number) => {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split(".")[1].length || 0;
  };
  return Number(x).toFixed(Math.min(countDecimals(x), decimals));
}

function formatPercentage(val: number): string {
  return (val * 100).toLocaleString(LANGUAGE, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%";
}

function formatDate(date: Date): string {
  const y = date.getFullYear();
  let m = "" + (date.getMonth() + 1);
  let d = "" + date.getDate();

  if (m.length < 2) {
    m = "0" + m;
  }
  if (d.length < 2) {
    d = "0" + d;
  }
  return [y, m, d].join("-");
}

function formatDateTime(moment: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric" };
  return moment.toLocaleTimeString(LANGUAGE, dateOptions);
}

const getMatrixRow = (M: any, i: number) => math.flatten(M.subset(math.index(i, math.range(0, M._size[0]))));

const percentageTooltip = (tooltipItem: any, data: any, valueAxis: string = "y", factor: number = 1.0): string => {
  const value = valueAxis === "x" ? tooltipItem.xLabel : tooltipItem.yLabel;
  return `${data.datasets[tooltipItem.datasetIndex].label}: ${roundTwoDecimals(factor * value)}%`;
};
const percentageTooltipX = (tooltipItem: any, data: any) => percentageTooltip(tooltipItem, data, "x");
const percentageTooltipY = (tooltipItem: any, data: any) => percentageTooltip(tooltipItem, data, "y");

const fractionToPercentageTooltipY = (tooltipItem: any, data: any) => percentageTooltip(tooltipItem, data, "y", 100.0);

const getPortfolioName = (name: string, hasMultiple: boolean): string => {
  return hasMultiple ? `Portfolio ${name}` : "Portfolio";
};

/**
 * Makes a copy of obj and returns it. This is useful if you want to avoid accidentally change
 * an object by reference.
 * @param obj An anonymous javascript object
 */
function copyObject(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Removes double slashes in url
 * @param url Url to remove slashes from
 */
function removeDoubleSlashes(url: string) {
  return url.replace(/([^:]\/)\/+/g, "$1");
}

/**
 * Mixes numeric values a and b, where result is a when bContribution=0 and result is b when bContribution=1.
 *
 * @param a A numeric value
 * @param b Another numeric value
 * @param bContribution A value between 0 - 1 that defines how much of 'b' to blend in.
 */
function mixValues(a: number, b: number, bContribution: number): number {
  if (bContribution < 0 || bContribution > 1) {
    throw new Error("invalid bContribution");
  }
  return a * (1 - bContribution) + b * bContribution;
}

const screenWidth = window.innerWidth || document.documentElement!.clientWidth || document.body.clientWidth;
const isMobile = screenWidth <= 768;

export {
  fractionToRoundedPercent,
  formatPortfolioPercentage,
  toString,
  formatPercentage,
  formatDateTime,
  roundTwoDecimals,
  formatDate,
  getMatrixRow,
  percentageTooltipX,
  percentageTooltipY,
  fractionToPercentageTooltipY,
  getPortfolioName,
  removeDoubleSlashes,
  copyObject,
  mixValues,
  screenWidth,
  isMobile
};
