import React from "react";
import { Container } from "semantic-ui-react";
import { IRouterMatchProps } from "util/RouterPropsInterface";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { AssetWeights } from "business/AssetWeights";
import {
  buildForwardSimulationGraphData,
  buildForwardSimChartOptions
} from "components/charts/ForwardSimulationGraph/ForwardSimulationGraphDataBuilders";
import { Line, Pie } from "react-chartjs-2";
import { secondaryPortfolioColor, primaryPortfolioColor, fontColor } from "util/colors";
import ForwardSimulationNumbersPane from "components/charts/ForwardSimulationGraph/ForwardSimulationNumbersPane";
import { AssetClasses } from "apiclient/AssetClasses";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers";
import { ChartData } from "chart.js";
import { ScaleFactorContributionSlider } from "components/ScaleFactorContributionSlider/ScaleFactorContributionSlider";
import { Grid, Divider } from "@material-ui/core";
import { DistributionSlider } from "components/DistributionSlider/DistributionSlider";
import PortfolioSimulator from "business/PortfolioSimulator";
import { DesktopOrTablet, Mobile, Desktop, MobileOrTablet } from "components/util/responsive";
import MainLayout from "components/App/MainLayout";
import { IAppLayoutProps } from "components/App/App";

import "./TeaserPage.css";

interface Props extends RouteComponentProps<IRouterMatchProps> {
  nextUrl: string;
  layoutProps: IAppLayoutProps;
}

const Y_AXIS_MAX = 500;
const initialDestribution = 50; // 0-100

interface IForwardSimulationLineChartProps {
  portfolio: AssetClassPortfolio;
  displayYears: number;
  investedAmount: number;
  scaleFactorContribution: number;
  isSecondary: boolean;
  onHoverExpectedAmountCallback?: (year: number, expectedAmount: number) => void;
  onHoverLowAmountCallback?: (year: number, lowAmount: number) => void;
  onHoverHighAmountCallback?: (year: number, highAmount: number) => void;
}

function ForwardSimulationLineChart(props: IForwardSimulationLineChartProps) {
  const data = buildForwardSimulationGraphData(
    props.displayYears,
    props.portfolio,
    props.investedAmount,
    props.scaleFactorContribution,
    props.isSecondary ? secondaryPortfolioColor : primaryPortfolioColor
  );
  const opts = buildForwardSimChartOptions(
    1,
    2,
    3,
    Y_AXIS_MAX,
    props.onHoverExpectedAmountCallback,
    props.onHoverLowAmountCallback,
    props.onHoverHighAmountCallback
  );

  return <Line data={data} options={opts} height={130} />;
}

const usedAssetClasses1 = "tbonds";
const usedAssetClasses2 = "swedish-equitites";

export const TeaserPage: React.FC<Props> = props => {
  const { nextUrl, layoutProps } = props;
  const layoutPropsWithTitle = { ...layoutProps, title: "CF FRIGG - Asset allocation decision support" };
  const years = 20;
  const [year, setYear] = React.useState(years);
  const [scaleFactorContribution, setScaleFactorContribution] = React.useState(0);
  const [weightDistribution, setWeightDistribution] = React.useState(initialDestribution);
  const [weights, setWeights] = React.useState<AssetWeights>(
    new AssetWeights({ [usedAssetClasses1]: initialDestribution, [usedAssetClasses2]: 100 - initialDestribution })
  );
  const assetClasses = useSelector((state: AppState) => state.financeData.assetClasses.data);
  if (assetClasses === undefined || assetClasses.filter(ac => ac.id === usedAssetClasses1 || ac.id === usedAssetClasses2).length < 2) {
    return <Redirect to={nextUrl} />;
  }
  const portfolio = new AssetClassPortfolio(weights, new AssetClasses(assetClasses));
  const simulator = new PortfolioSimulator(portfolio.calculateRisk(), portfolio.calculateYield());
  const investedAmount = 100;
  const usedAssetClasses1Label = assetClasses.find(ac => ac.id === usedAssetClasses1)!.name;
  const usedAssetClasses2Label = assetClasses.find(ac => ac.id === usedAssetClasses2)!.name;

  const pieChardData: ChartData = {
    labels: [usedAssetClasses1Label, usedAssetClasses2Label],
    datasets: [
      {
        data: [weights.get(usedAssetClasses1), weights.get(usedAssetClasses2)],
        backgroundColor: [primaryPortfolioColor, secondaryPortfolioColor]
      }
    ]
  };

  const handleOnclickContinue = () => {
    props.history.push(nextUrl);
  };

  const handleDistributionChange = (value: number) => {
    setWeightDistribution(value);
    setWeights(new AssetWeights({ [usedAssetClasses2]: value, [usedAssetClasses1]: 100 - value }));
  };

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div>
        <main className="TeaserPage">
          <SectionWrap toned={true} className="main-section">
            <Container>
              <div className="teaser-wrap">
                <Grid container>
                  <Grid style={{ paddingBottom: 10 }} container item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" item xs={6}>
                      <Grid style={{ textAlign: "center", color: fontColor }} item xs={12}>
                        Asset allocation
                      </Grid>
                      <Grid style={{ textAlign: "center" }} item xs={12}>
                        <DistributionSlider
                          distribution={weightDistribution}
                          onDistributionChanged={(value: number) => handleDistributionChange(value)}
                          label1={usedAssetClasses1Label}
                          label2={usedAssetClasses2Label}
                          extraStyle={{ color: fontColor }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ textAlign: "center", color: fontColor }} item xs={12}>
                        Distribution
                      </Grid>
                      <Grid style={{ textAlign: "center" }} item xs={12}>
                        <ScaleFactorContributionSlider
                          scaleFactorContribution={scaleFactorContribution}
                          extraStyle={{ color: fontColor }}
                          onScaleFactorContributionChanged={(value: number) => setScaleFactorContribution(value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Desktop>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            position: "absolute",
                            marginLeft: "50px",
                            marginTop: "23px",
                            backgroundColor: "rgb(241,241,241)",
                            padding: 20,
                            fontFamily: "Good Sans"
                          }}
                        >
                          <Pie legend={{ position: "right", onClick: () => {} }} data={pieChardData} />
                        </div>
                        <ForwardSimulationLineChart
                          portfolio={portfolio}
                          displayYears={years}
                          investedAmount={investedAmount}
                          scaleFactorContribution={scaleFactorContribution}
                          isSecondary={false}
                          onHoverExpectedAmountCallback={(year: number) => setYear(year + 1)}
                          onHoverLowAmountCallback={(year: number) => setYear(year + 1)}
                          onHoverHighAmountCallback={(year: number) => setYear(year + 1)}
                        />
                      </Grid>
                    </Grid>
                  </Desktop>

                  <MobileOrTablet>
                    <Grid item xs={12}>
                      <ForwardSimulationLineChart
                        portfolio={portfolio}
                        displayYears={years}
                        investedAmount={investedAmount}
                        scaleFactorContribution={scaleFactorContribution}
                        isSecondary={false}
                        onHoverExpectedAmountCallback={(year: number) => setYear(year + 1)}
                        onHoverLowAmountCallback={(year: number) => setYear(year + 1)}
                        onHoverHighAmountCallback={(year: number) => setYear(year + 1)}
                      />
                    </Grid>
                  </MobileOrTablet>
                </Grid>
                <ForwardSimulationNumbersPane
                  investedAmount={investedAmount}
                  expectedAmount={simulator.getExpectedResult(investedAmount, year)}
                  lowAmount={simulator.getLowResult(investedAmount, year, scaleFactorContribution)}
                  highAmount={simulator.getHighResult(investedAmount, year, scaleFactorContribution)}
                  year={year}
                  isSecondary={false}
                />
                <Divider className="teaser-divider" />
                <Grid container>
                  <Grid className="teaser-plot" item xs={12}>
                    <p>
                      <b>
                        CF FRIGG applies fundamental value investing on asset classes together with relevant long term forward-looking
                        simulations.
                      </b>
                    </p>
                    <p>
                      The methodology provides guidance on how to dynamically adjust asset allocation over time when valuations, risks and
                      economy evolve.
                    </p>
                    <p>
                      FRIGG also provide input on how to improve portfolio efficiency and guides towards optimal risk taking based on
                      investment horizon and risk tolerance.
                    </p>
                    <p>
                      The simulation solution has 5 model portfolios as a starting point, but the main section is the simulation area where
                      unique portfolios can be analyzed and compared.
                    </p>
                    <p>
                      The FRIGG name comes from five characteristics of the methodology: Fundamental, Research Driven, Insightful, Global
                      and Green.
                    </p>
                    <br />
                    <p>
                      <b>
                        Frigg is also the Queen of Asgard, the highest of the goddesses and a friend of Thor. The FRIGG methodology will
                        help you determine the long term destiny of your investment portfolio.
                      </b>
                    </p>
                  </Grid>
                </Grid>
              </div>
              <Mobile>
                <Grid container justifyContent="center">
                  <Grid item className="continue-button-mobile">
                    <button className="ui button carnegie__button" onClick={handleOnclickContinue}>
                      To Full Simulation
                    </button>
                  </Grid>
                </Grid>
              </Mobile>

              <DesktopOrTablet>
                <Grid container justifyContent="center">
                  <button className="ui button carnegie__button" onClick={handleOnclickContinue}>
                    To Full Simulation
                  </button>
                </Grid>
              </DesktopOrTablet>
            </Container>
          </SectionWrap>
        </main>
      </div>
    </MainLayout>
  );
};
