import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import { IRouterMatchProps } from 'util/RouterPropsInterface';
import SimulationPage from 'components/pages/SimulationPage/SimulationPage';
import FundsPage from 'components/pages/FundsPage/FundsPage';
import Auth from 'modules/Auth';
import WelcomePage from 'components/pages/WelcomePage/WelcomePage';
import RiskProfilePage from 'components/pages/RiskProfilePage/RiskProfilePage';
import ReportPage from 'components/pages/ReportPage/ReportPage';
import { SimulationData, newSimulation, Simulation } from 'apiclient/responsePayloads/Simulation';
import { removeDoubleSlashes } from 'util/misc-utils';
import ReportPDFPageLoader from 'components/pages/ReportPage/ReportPDFPage';
import MySavedSimulationsPage from 'components/pages/MySavedSimulationsPage/MySavedSimulationsPage';
import { SavedSimulationRoute } from './SavedSimulationRoute';
import LoginPage from 'components/pages/LoginPage/LoginPage';
import { ForgotPasswordPage } from 'components/pages/ForgotPasswordPage/ForgotPasswordPage';
import AppRoute from './AppRoute';
import { AccountPage } from 'components/pages/AccountPage/AccountPage';
import { SavingState } from 'store/simulations/reducers';
import { ResetPasswordPage } from 'components/pages/ResetPasswordPage/ResetPasswordPage';
import { ActivationPage } from 'components/pages/ActivationPage/ActivationPage';
import { TeaserPage } from 'components/pages/TeaserPage/TeaserPage';
import RequestAccountPage from 'components/pages/RequestAccountPage/RequestAccountPage';
import { REACT_APP_FUNDS_ON } from './isFundsOn';

import 'semantic/dist/semantic.min.css';
import './App.css';
import './rangeslider.css';

interface IAppProps extends RouteComponentProps<IRouterMatchProps> {
  unsavedSimulation: SimulationData;
  userSimulations?: SimulationData[];
  savingState?: SavingState;
  onUnsavedSimulationChange(simulation: SimulationData): void;
  refreshSimulations(): void;
  updateSimulation(simulation: SimulationData): void;
}

interface IAppState {
  isLoggedIn: boolean;
  hasStartedFetchingSimulations: boolean;
  simulations?: SimulationData[];
}

export interface IAppLayoutProps {
  isLoggedIn: boolean;
  onNewSimulation: () => void;
  onLogout: () => void;
  lang: string;
  white?: boolean;
  blue?: boolean;
}

export class App extends React.Component<IAppProps, IAppState> {
  private auth: Auth;

  constructor(props: IAppProps) {
    super(props);
    this.auth = new Auth(localStorage);
    this.state = {
      isLoggedIn: this.auth.isLoggedIn(),
      hasStartedFetchingSimulations: false,
    };
  }

  public componentDidUpdate(prevProps: IAppProps, prevState: IAppState) {
    this.updateSimulations(prevState.isLoggedIn);
  }

  public render(): React.ReactNode {
    const { match } = this.props;
    const loginUrl = removeDoubleSlashes(`${match.url}/login`);
    const forgotPassUrl = removeDoubleSlashes(`${match.url}/recover`);
    const resetPassUrl = removeDoubleSlashes(`${match.url}/reset-password/:token`);
    const welcomeUrl = removeDoubleSlashes(`${match.url}/welcome`);
    const activationUrl = removeDoubleSlashes(`${match.url}/user/:id/activate/:token`);
    const riskprofileUrl = removeDoubleSlashes(`${match.url}/riskprofile`);
    const requestAccountUrl = removeDoubleSlashes(`${match.url}/request-account`);
    const simulationUrl = removeDoubleSlashes(`${match.url}/simulation`);
    const fundsUrl = removeDoubleSlashes(`${match.url}/funds`);
    const reportUrl = removeDoubleSlashes(`${match.url}/report`);

    const layoutProps: IAppLayoutProps = {
      isLoggedIn: this.auth.isLoggedIn(),
      onNewSimulation: () => this.handleStartNewPortfolio(welcomeUrl),
      onLogout: () => this.onLogout(),
      lang: match.params.lang,
    };

    function getRedirectUrl(loggedIn: boolean, hasRiskLevel: boolean): string {
      if (!loggedIn) {
        return loginUrl;
      }
      if (hasRiskLevel) {
        return simulationUrl;
      }
      return welcomeUrl;
    }

    const simWrapper = new Simulation(this.props.unsavedSimulation);

    return (
      <Switch>
        <AppRoute
          exact={true}
          path={`${match.path}/`}
          component={TeaserPage}
          layoutProps={{ blue: true, ...layoutProps }}
          extraProps={{
            nextUrl: getRedirectUrl(this.auth.isLoggedIn(), simWrapper.hasRiskLevel()),
          }}
        />
        <AppRoute
          exact={true}
          path={loginUrl}
          component={LoginPage}
          layoutProps={{ white: true, ...layoutProps }}
          extraProps={{
            nextUrl: getRedirectUrl(true, simWrapper.hasRiskLevel()),
            onForgotPassClick: () => this.onForgotPassClick(forgotPassUrl),
            forgotPassUrl,
          }}
        />
        <AppRoute
          exact={true}
          path={resetPassUrl}
          component={ResetPasswordPage}
          layoutProps={{ white: true, ...layoutProps }}
          extraProps={{
            loginUrl,
          }}
        />
        <AppRoute
          exact={true}
          path={activationUrl}
          component={ActivationPage}
          layoutProps={{ white: true, ...layoutProps }}
          extraProps={{
            loginUrl,
          }}
        />
        <AppRoute
          exact={true}
          path={forgotPassUrl}
          component={ForgotPasswordPage}
          layoutProps={{ white: true, ...layoutProps }}
          extraProps={{}}
        />
        <AppRoute
          exact={true}
          protect={true}
          path={welcomeUrl}
          component={WelcomePage}
          layoutProps={{ blue: true, ...layoutProps }}
          extraProps={{
            nextUrl: riskprofileUrl,
          }}
        />
        <AppRoute
          exact={true}
          protect={true}
          path={riskprofileUrl}
          component={RiskProfilePage}
          layoutProps={{ blue: true, ...layoutProps }}
          extraProps={{
            simulation: simWrapper,
            onSimulationChange: this.setSimulation,
          }}
        />
        <AppRoute
          exact={true}
          protect={true}
          path={simulationUrl}
          component={SimulationPage}
          layoutProps={layoutProps}
          extraProps={{
            simulation: simWrapper,
            onSimulationChange: this.setSimulation,
          }}
        />
        {REACT_APP_FUNDS_ON && (
          <AppRoute
            exact={true}
            protect={true}
            path={fundsUrl}
            component={FundsPage}
            layoutProps={{ ...layoutProps }}
            extraProps={{
              simulation: simWrapper,
              onSimulationChange: this.setSimulation,
              savingState: this.props.savingState,
            }}
          />
        )}
        <AppRoute
          exact={true}
          protect={false}
          path={requestAccountUrl}
          component={RequestAccountPage}
          layoutProps={{ white: true, ...layoutProps }}
          extraProps={{}}
        />
        <AppRoute
          exact={true}
          protect={true}
          path={reportUrl}
          component={ReportPage}
          layoutProps={layoutProps}
          extraProps={{
            simulation: simWrapper,
            onSimulationChange: this.setSimulation,
          }}
        />
        <SavedSimulationRoute
          path={`${match.path}/s/:id/riskprofile`}
          simulations={this.props.userSimulations}
          component={RiskProfilePage}
          layoutProps={{ blue: true, ...layoutProps }}
          onSetSavedSimulation={this.props.updateSimulation}
          extraProps={{
            savingState: this.props.savingState,
          }}
        />
        <SavedSimulationRoute
          path={`${match.path}/s/:id/simulation`}
          simulations={this.props.userSimulations}
          component={SimulationPage}
          layoutProps={layoutProps}
          onSetSavedSimulation={this.props.updateSimulation}
          extraProps={{
            savingState: this.props.savingState,
          }}
        />
        <SavedSimulationRoute
          path={`${match.path}/s/:id/funds`}
          simulations={this.props.userSimulations}
          component={FundsPage}
          layoutProps={layoutProps}
          onSetSavedSimulation={this.props.updateSimulation}
          extraProps={{
            savingState: this.props.savingState,
          }}
        />
        <SavedSimulationRoute
          path={`${match.path}/s/:id/report`}
          simulations={this.props.userSimulations}
          component={ReportPage}
          layoutProps={layoutProps}
          onSetSavedSimulation={this.props.updateSimulation}
          extraProps={{
            savingState: this.props.savingState,
          }}
        />
        <AppRoute
          exact={true}
          protect={true}
          path={`${match.path}/account`}
          component={AccountPage}
          layoutProps={layoutProps}
          extraProps={{}}
        />
        <AppRoute
          exact={true}
          protect={true}
          path={`${match.path}/my-saved-simulations`}
          component={MySavedSimulationsPage}
          layoutProps={layoutProps}
          extraProps={{
            simulations: this.props.userSimulations,
          }}
        />
        <Route path={`${match.path}/pdf-report/:token`} component={ReportPDFPageLoader} />
      </Switch>
    );
  }

  private clearJwtHeader() {
    axios.defaults.headers.common.Authorization = null;
  }

  private onLogout() {
    this.clearJwtHeader();
    this.setSimulation(newSimulation());
    this.setState({
      isLoggedIn: false,
    });
  }

  private handleStartNewPortfolio = (redirectTo: string) => {
    // user has chosen to start new portfolio
    this.setSimulation(newSimulation());
    this.props.history.push(redirectTo);
  };

  private onForgotPassClick = (redirectTo: string) => {
    this.props.history.push(redirectTo);
  };

  private updateSimulations(wasLoggedIn: boolean) {
    // TODO: This is a mess. Will it run only once?
    // TODO: Redux: Fetch simulations
    if (this.state.isLoggedIn && (!this.state.hasStartedFetchingSimulations || !wasLoggedIn)) {
      this.props.refreshSimulations();
      this.setState({ hasStartedFetchingSimulations: true });
    } else if (!this.state.isLoggedIn && wasLoggedIn) {
      // User did log out
      this.setState({ simulations: undefined });
    }
  }

  private setSimulation = (simulation: SimulationData) => {
    this.props.onUnsavedSimulationChange(simulation);
  };
}
