import React from 'react';
import { Form } from 'semantic-ui-react';
import { SaveButton, SaveState } from './SaveButton';
import { UserProfile } from 'apiclient/responsePayloads/User';

interface Props {
  user: UserProfile;
  isSaving: boolean;
  saveHasErrored: boolean;
  justSaved: boolean;
  onSaveClicked: (firstName: string, lastName: string, organization: string) => void;
}

const calculateSaveState = (isSaving: boolean, saveHasErrored: boolean, justSaved: boolean) => {
  if (isSaving) {
    return SaveState.SAVING;
  }
  if (saveHasErrored) {
    return SaveState.FAILED;
  }
  if (justSaved) {
    return SaveState.JUST_SAVED;
  }
  return SaveState.NORMAL;
};

export const UserDetailsForm: React.FC<Props> = ({ user, onSaveClicked, isSaving, saveHasErrored, justSaved }) => {
  const [hasChanged, setHasChanged] = React.useState(false);
  const [firstName, setFirstName] = React.useState(user.firstName);
  const [lastName, setLastName] = React.useState(user.lastName);
  const [organization, setOrganization] = React.useState(user.organization);

  const saveState = calculateSaveState(isSaving, saveHasErrored, justSaved);
  const editDisabled = saveState === SaveState.SAVING;
  const saveError = saveHasErrored ? 'Failed to save' : undefined;

  const onChange = () => {
    setHasChanged(true);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input
          label="First name"
          className="carnegie__input"
          width={8}
          value={firstName}
          disabled={editDisabled}
          onChange={(e) => {
            setFirstName(e.target.value);
            onChange();
          }}
        />
        <Form.Input
          label="Last Name"
          width={8}
          className="carnegie__input"
          value={lastName}
          disabled={editDisabled}
          onChange={(e) => {
            setLastName(e.target.value);
            onChange();
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          label="Organization"
          className="carnegie__input"
          width={12}
          value={organization}
          disabled={editDisabled}
          onChange={(e) => {
            setOrganization(e.target.value);
            onChange();
          }}
        />
      </Form.Group>
      <SaveButton
        saveState={saveState}
        canSave={hasChanged}
        onClick={() => onSaveClicked(firstName, lastName, organization)}
        errorText={saveError}
      />
    </Form>
  );
};
