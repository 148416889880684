import * as React from "react";

import { TopMenu } from "components/TopMenu/TopMenu";
import { Footer } from "components/Footer/Footer";
import "./MainLayout.css";
import PageHeader from "components/PageHeader/PageHeader";
import { IAppLayoutProps } from "./App";
import { Snackbar, IconButton, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

interface IMainLayoutProps extends IAppLayoutProps {
  children: any;
  title: string;
}

const MainLayout = (props: IMainLayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [openLanguageNotice, setOpenLanguageNotice] = React.useState(window.location.href.includes("?showLanguageNotice"));

  const handleMessageClose = () => {
    setOpenLanguageNotice(false);
  };

  const topInfoBar = (
    <div className="top-bar" style={{ zIndex: 1000 }}>
      <div className="risk-box bg-black p-4 -z-1">
        <p className="text-cream text-sm">
          <span className="font-bold"></span> <strong>Riskinformation</strong> Historisk avkastning är ingen garanti för framtida
          avkastning. De pengar som placeras i fonden kan både öka och minska i värde och det är inte säkert att du får tillbaka hela det
          insatta kapitalet.
        </p>
      </div>
    </div>
  );

  return (
    <div className="MainLayout wrapper">
      {topInfoBar}
      <div className="HeaderBlock">
        <TopMenu
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isLoggedIn={props.isLoggedIn}
          onNewSimulation={props.onNewSimulation}
          onLogout={props.onLogout}
          lang={props.lang}
        />
        <PageHeader text={props.title}></PageHeader>
      </div>
      <main className={props.blue ? "blue" : props.white ? "white" : ""}>
        {props.children}
        {
          <Snackbar open={openLanguageNotice} onClose={handleMessageClose}>
            <Alert severity="warning">
              <Typography display="inline" style={{ fontFamily: "GoodSans" }}>
                Frigg is only available in English / Frigg är bara tillgänglig på Engelska
              </Typography>
              <IconButton aria-label="close" color="inherit" style={{ padding: 2, marginLeft: 10 }} onClick={handleMessageClose}>
                <CloseIcon />
              </IconButton>
            </Alert>
          </Snackbar>
        }
      </main>
      <Footer />
    </div>
  );
};
export default MainLayout;
