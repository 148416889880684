import * as React from "react";
import { HistoricReturnGraph } from "components/charts/HistoricReturnGraph/HistoricReturnGraph";
import { DrawdownChartSection } from "components/charts/DrawdownChart/DrawdownChart";
import { FundReturns } from "apiclient/FundReturns";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { FundsTable } from "components/charts/FundsTable/FundsTableChart";
import { ModalData } from "components/CustomPortfolioModal/CustomPortfolioModal";
import { ChartSection } from "components/ChartSection/ChartSection";
import Funds from "apiclient/Funds";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";

// This component switches contents depending on whether returns is funds or assets.
interface IFundsChartsProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  funds: Funds;
  onSwitch: (portfolio: AssetClassPortfolio, splitAmong: "Indexes" | "Funds") => void;
  assetClasses: AssetClassData[] | undefined;
  assetClassReturns: AssetClassReturns;
  fundReturns: FundReturns;
  onChooseCustomFundAllocation(data: ModalData, forAssetClassID: string, portfolio: AssetClassPortfolio): void;
}

export class FundsCharts extends React.Component<IFundsChartsProps> {
  public render(): React.ReactNode {
    const { portfolioA, portfolioB, funds, onSwitch, assetClasses } = this.props;
    return (
      <div className="simulation">
        <ChartSection toned={true}>
          <FundsTable
            portfolio={portfolioA}
            onSwitch={onSwitch}
            assetClasses={assetClasses}
            funds={funds}
            onClickAdjustFunds={(data: ModalData, forAssetClassID: string) =>
              this.props.onChooseCustomFundAllocation(data, forAssetClassID, portfolioA)
            }
          />
          <FundsTable
            portfolio={portfolioB}
            onSwitch={onSwitch}
            assetClasses={assetClasses}
            funds={funds}
            onClickAdjustFunds={(data: ModalData, forAssetClassID: string) =>
              this.props.onChooseCustomFundAllocation(data, forAssetClassID, portfolioB)
            }
          />
        </ChartSection>
        <div style={{ paddingTop: 60 }}>
          <ChartSection toned={false}>
            <HistoricReturnGraph
              portfolio={portfolioA}
              comparisonPortfolio={portfolioB}
              assetClassReturns={this.props.assetClassReturns}
              fundReturns={this.props.fundReturns}
              showDisclaimerIcon={true}
            />
          </ChartSection>
        </div>
        <DrawdownChartSection
          toned={true}
          portfolio={portfolioA}
          comparisonPortfolio={portfolioB}
          assetClassReturns={this.props.assetClassReturns}
          fundReturns={this.props.fundReturns}
        />
      </div>
    );
  }
}
