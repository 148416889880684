import React, { useState } from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import { fundsStyles } from "./FundsStyles";
import { IFund } from "apiclient/responsePayloads/Fund";

interface AddFundFormProps {
  handleCreateFund: (fund: IFund) => void;
}

export const AddFundForm: React.FC<AddFundFormProps> = props => {
  const { handleCreateFund } = props;
  const classes = fundsStyles();
  const [name, setName] = useState("");
  const [id, setID] = useState("");
  const [description, setDescription] = useState("");
  const [displayErrors, setDisplayErrors] = useState(false);

  const handleChangeName = (name: string) => {
    setDisplayErrors(true);
    setName(name);
  };

  const handleChangeId = (id: string) => {
    setDisplayErrors(true);
    setID(id);
  };

  const handleChangeDescription = (desc: string) => {
    setDisplayErrors(true);
    setDescription(desc);
  };

  const handleCreateClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFund: IFund = {
      id: id,
      name: name,
      description: description
    };

    handleCreateFund(newFund);
  };

  const formIsValid = (): boolean => {
    if (name !== "" && id !== "") return true;
    else return false;
  };

  return (
    <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleCreateClick(e)}>
      <Grid container item xs={12} className={classes.form} spacing={2}>
        <Grid item xs={12}>
          <h3 className={classes.blueColor}>Add fund</h3>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            error={name === "" && displayErrors}
            helperText={name === "" && displayErrors && "Name cannot be empty"}
            size="small"
            label="Name"
            placeholder="Ex: Hedgefonder"
            type="text"
            required
            value={name}
            onChange={e => handleChangeName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            error={id === "" && displayErrors}
            helperText={id === "" && displayErrors && "ID cannot be empty"}
            size="small"
            label="ID"
            required
            placeholder="Ex: hedge-funds"
            type="text"
            value={id}
            onChange={e => handleChangeId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Description(optional)"
            multiline
            rows="5"
            type="text"
            value={description}
            onChange={e => handleChangeDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button style={{ float: "right" }} type="submit" disabled={!formIsValid()} variant="outlined" color="primary">
            Add fund
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
