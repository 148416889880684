import React from 'react';
import { Button, Loader } from 'semantic-ui-react';

export enum SaveState {
  NORMAL,
  SAVING,
  FAILED,
  JUST_SAVED,
}

interface SaveButtonProps {
  saveState: SaveState;
  canSave: boolean;
  onClick: () => void;
  text?: string;
  savedText?: string;
  errorText?: string;
}

export const SaveButton: React.FC<SaveButtonProps> = ({ saveState, canSave, onClick, text, savedText, errorText }) => {
  return (
    <span>
      <Button disabled={saveState === SaveState.SAVING || !canSave} onClick={onClick} className="carnegie__button">
        {text || 'Save'}
      </Button>
      <span style={{ marginLeft: 10 }}>
        <Loader active={saveState === SaveState.SAVING} inline size="small" />
        {saveState === SaveState.JUST_SAVED && <span>{savedText || 'Saved!'}</span>}
        {saveState === SaveState.FAILED && <span>{errorText || 'Something went wrong when saving'}</span>}
      </span>
    </span>
  );
};
