import React from "react";
import math from "mathjs";
import { Button, Form, Grid, Image, Input, Message, Modal, Statistic } from "semantic-ui-react";
import { AssetWeights } from "business/AssetWeights";
import "./CustomPortfolioModal.css";

export class ModalData {
  public readonly ids: string[];
  public readonly names: string[];
  public readonly weights: AssetWeights;
  public readonly classNames?: string[];
  public readonly title?: string;

  constructor(ids: string[], names: string[], weights: AssetWeights, classNames?: string[], title?: string) {
    this.ids = ids;
    this.names = names;
    this.weights = weights;
    this.classNames = classNames;
    this.title = title;
  }

  public totalWeights(): number {
    return this.weights.total();
  }
}

interface ICustomPortfolioModalProps {
  visible: boolean;
  title: string;
  data: ModalData;
  totalWeights: number;
  warningText: string;
  onClose(): void;
  onSave(weights: AssetWeights): void;
}

interface ISimulationPageState {
  weights: AssetWeights;
  hasChanged: boolean;
}

export class CustomPortfolioModal extends React.Component<ICustomPortfolioModalProps, ISimulationPageState> {
  constructor(props: ICustomPortfolioModalProps) {
    super(props);

    this.state = {
      weights: props.data.weights,
      hasChanged: false
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ICustomPortfolioModalProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({
        weights: nextProps.data.weights,
        hasChanged: false
      });
    }
    return true;
  }

  public handleClose = () => {
    this.props.onClose();
  };

  public handleSave = () => {
    if (!this.sumIsEqualToTotal()) {
      return;
    }
    if (this.state.hasChanged) {
      this.props.onSave(this.state.weights);
    } else {
      this.props.onClose();
    }
  };

  public handleFieldChange(assetClassId: string, newValueInPercent: number) {
    if (isNaN(newValueInPercent)) {
      return; // reject
    }
    newValueInPercent = math.min(100, math.max(0, newValueInPercent));

    if (this.state.weights.get(assetClassId) !== newValueInPercent) {
      this.setState({
        weights: this.state.weights.updateWith(assetClassId, newValueInPercent),
        hasChanged: true
      });
    }
  }

  public fixIos11ScrollBug(enable: boolean) {
    const bodyElem = document.getElementById("body");
    if (bodyElem) {
      if (enable) {
        bodyElem.classList.add("ios11-modal-scroll-bug-fix");
      } else {
        bodyElem.classList.remove("ios11-modal-scroll-bug-fix");
      }
    }
  }

  public onMount() {
    this.fixIos11ScrollBug(true);
  }

  public onUnmount() {
    this.fixIos11ScrollBug(false);
  }

  public getInputField(id: string, label: string, className?: string): JSX.Element {
    return (
      <Form.Field key={id} inline={true} className={className + " carnegie__inline__input"}>
        <label>{label}</label>
        <Input
          type="number"
          value={this.state.weights.get(id)}
          size="huge"
          // tslint:disable-next-line:jsx-no-lambda
          onChange={(e, data: any) => this.handleFieldChange(id, data.value)}
        />
        <span style={{ marginLeft: 10 }}>%</span>
      </Form.Field>
    );
  }

  public getInputFields(data: ModalData, fromIndex: number, toIndex: number): JSX.Element[] {
    const fields: JSX.Element[] = [];
    for (let i = fromIndex; i < toIndex; i++) {
      const className = data.classNames && data.classNames[i];
      fields.push(this.getInputField(data.ids[i], data.names[i], className));
    }
    return fields;
  }

  public getFirstInputFields(data: ModalData): JSX.Element[] {
    return this.getInputFields(data, 0, Math.ceil(data.ids.length / 2));
  }

  public getRestInputFields(data: ModalData): JSX.Element[] {
    return this.getInputFields(data, Math.ceil(data.ids.length / 2), data.ids.length);
  }

  public render(): React.ReactNode {
    const totalSum = this.state.weights.total();

    return (
      <Modal
        open={this.props.visible}
        onClose={this.handleClose}
        onMount={() => this.onMount()}
        onUnmount={() => this.onUnmount()}
        className="CustomPortfolioModal"
      >
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
          <Form>
            <Grid columns={2} stackable={true}>
              <Grid.Column textAlign="right">{this.getFirstInputFields(this.props.data)}</Grid.Column>
              <Grid.Column textAlign="right">{this.getRestInputFields(this.props.data)}</Grid.Column>
            </Grid>
          </Form>

          <Grid columns={2} stackable={true}>
            <Grid.Column width={10} verticalAlign="bottom">
              {!this.sumIsEqualToTotal() && (
                <Message className="sum-warning" warning={true} icon={true}>
                  <Image src="/images/warning-symbol.png" size="small" floated="left" style={{ width: 40, marginBottom: 0 }} />
                  <Message.Content>{this.props.warningText}</Message.Content>
                </Message>
              )}
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <Statistic className={!this.sumIsEqualToTotal() ? "orange total-sum" : "blue total-sum"}>
                <Statistic.Label className="carnegie__font">Total</Statistic.Label>
                <Statistic.Value>{totalSum.toLocaleString("sv-SE")}%</Statistic.Value>
              </Statistic>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button className="secondary-btn carnegie__button__secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button className="carnegie__button" onClick={this.handleSave} disabled={!this.sumIsEqualToTotal()}>
            Use allocation
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  /** Helper function to avoid most floating point errors when comparing */
  private sumIsEqualToTotal() {
    return Math.abs(this.state.weights.total() - this.props.totalWeights) < 0.001;
  }
}
