export const responseMessageFromCode = (errCode: number | string | undefined): string => {
  let code = errCode;
  if (typeof code === "string") code = parseInt(code);
  else if (code === undefined) return "Something went wrong (Server Error)";

  if (code.toString().startsWith("2")) {
    return "Success";
  } else if (code.toString().startsWith("4")) {
    if (code === 400) return "Bad Request";
    else if (code === 401) return "You lack permissions to perform this action";
    else if (code === 403) return "You are not allowed to perform this action";
    else if (code === 404) return "Not Found";
    else if (code === 409) return "Duplicate";
    else return "Error occurred";
  } else if (code.toString().startsWith("5")) {
    return "Something went wrong (Server Error)";
  } else return "Unknown response code";
};
