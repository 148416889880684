import React from "react";
import { Container, Card } from "semantic-ui-react";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { REACT_APP_FUNDS_ON } from "components/App/isFundsOn";
import MainLayout from "components/App/MainLayout";
import { IAppLayoutProps } from "components/App/App";

import "./WelcomePage.css";

interface IWelcomePageProps {
  nextUrl: string;
  layoutProps: IAppLayoutProps;
}

const WelcomePage: React.FC<IWelcomePageProps> = props => {
  const layoutPropsWithTitle = { ...props.layoutProps, title: "Introduction" };

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="WelcomePage">
        <SectionWrap className="main" toned={true}>
          <Container textAlign="center">
            <Card.Group centered={true}>
              <Card className="step">
                <Card.Content>
                  <Card.Header className="number">
                    <h1>1</h1>
                  </Card.Header>
                  <h3>Risk Profile</h3>
                  <Card.Description>Select an initial portfolio risk profile.</Card.Description>
                </Card.Content>
              </Card>
              <Card className="step">
                <Card.Content>
                  <Card.Header className="number">
                    <h1>2</h1>
                  </Card.Header>
                  <h3>Asset Allocation</h3>
                  <Card.Description>
                    Build efficient portfolios and compare with your present portfolio.
                    <br />
                    <br />
                    Simulate long term future outcomes, guiding you towards optimal risk-taking.
                  </Card.Description>
                </Card.Content>
              </Card>
              {REACT_APP_FUNDS_ON && (
                <Card className="step">
                  <Card.Content>
                    <Card.Header className="number">
                      <h1>3</h1>
                    </Card.Header>
                    <h3>Fund Allocation</h3>
                    <Card.Description>
                      Build investible portfolios with Carneo group funds or use indexes as proxy for other funds.
                    </Card.Description>
                  </Card.Content>
                </Card>
              )}
              <Card className="step">
                <Card.Content>
                  <Card.Header className="number">
                    <h1>{REACT_APP_FUNDS_ON ? 4 : 3}</h1>
                  </Card.Header>
                  <h3>Report</h3>
                  <Card.Description>Save your progress and generate portfolio report.</Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
          </Container>
          <Container className="got-it-button">
            <a href={props.nextUrl}>
              <button style={{ float: "right", marginRight: 15 }} className="ui button carnegie__button">
                Got it, let's go!
              </button>
            </a>
          </Container>
        </SectionWrap>
      </div>
    </MainLayout>
  );
};

export default WelcomePage;
