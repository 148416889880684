import React from 'react';
import { Input } from 'semantic-ui-react';
import './ScaleFactorContributionSlider.css';

interface IScaleFactorContributionSliderProps {
    extraStyle?: any
    scaleFactorContribution: number
    onScaleFactorContributionChanged?: (newContribution: number) => void
}

export const ScaleFactorContributionSlider = ({ extraStyle, scaleFactorContribution, onScaleFactorContributionChanged }: IScaleFactorContributionSliderProps) => (
    <div className='ScaleFactorContributionSlider' style={extraStyle}>
        <label onClick={() => onScaleFactorContributionChanged && onScaleFactorContributionChanged(0)}>Normal</label>
        <Input
            type="range"
            min="0"
            max="1"
            step="0.005"
            value={scaleFactorContribution}
            onChange={(e, data) => onScaleFactorContributionChanged && onScaleFactorContributionChanged(Number(data.value))}
        />
        <label onClick={() => onScaleFactorContributionChanged && onScaleFactorContributionChanged(1)}>Empiric</label>
    </div>
);
