import React from "react";
import { Line } from "react-chartjs-2";
import { Menu } from "semantic-ui-react";

import { buildForwardSimulationGraphData, buildForwardSimChartOptions } from "./ForwardSimulationGraphDataBuilders";
import { HelpPopupIcon } from "components/HelpPopupIcon/HelpPopupIcon";
import { ScaleFactorContributionSlider } from "components/ScaleFactorContributionSlider/ScaleFactorContributionSlider";
import { Desktop, Mobile, Tablet } from "components/util/responsive";
import { primaryPortfolioColor, secondaryPortfolioColor } from "util/colors";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import ForwardSimulationNumbersPane from "./ForwardSimulationNumbersPane";

import "./ForwardSimulationGraph.css";
import PortfolioSimulator from "business/PortfolioSimulator";

const Y_AXIS_MAX = 500;

interface IForwardSimulationLineChartProps {
  portfolio: AssetClassPortfolio;
  displayYears: number;
  investedAmount: number;
  scaleFactorContribution: number;
  isSecondary: boolean;
  onHoverExpectedAmountCallback?: (year: number, expectedAmount: number) => void;
  onHoverLowAmountCallback?: (year: number, lowAmount: number) => void;
  onHoverHighAmountCallback?: (year: number, highAmount: number) => void;
}

function ForwardSimulationLineChart(props: IForwardSimulationLineChartProps) {
  const data = buildForwardSimulationGraphData(
    props.displayYears,
    props.portfolio,
    props.investedAmount,
    props.scaleFactorContribution,
    props.isSecondary ? secondaryPortfolioColor : primaryPortfolioColor
  );
  const opts = buildForwardSimChartOptions(
    1,
    2,
    3,
    Y_AXIS_MAX,
    props.onHoverExpectedAmountCallback,
    props.onHoverLowAmountCallback,
    props.onHoverHighAmountCallback
  );

  return <Line data={data} options={opts} />;
}

interface IForwardSimulationGraphProps {
  portfolio: AssetClassPortfolio;
  scaleFactorContribution: number;
  secondary: boolean;
  onScaleFactorContributionChange: (contribution: number) => void;
}

interface IForwardSimulationGraphState {
  investedAmount: number;
  year: number;
  displayYears: number;
}

export class ForwardSimulationGraph extends React.Component<IForwardSimulationGraphProps, IForwardSimulationGraphState> {
  public activeTooltip: any | null;
  constructor(props: IForwardSimulationGraphProps) {
    super(props);
    this.activeTooltip = null;
    this.state = {
      investedAmount: 100,
      year: 20,
      displayYears: 20
    };
  }

  public render(): React.ReactNode {
    const me = this;

    return (
      <div className="ForwardSimulationGraph">
        <div className="graph-header-container">
          <HelpPopupIcon style={{ float: "right", marginTop: 8 }}>
            The graph displays the expected value of an investment for different time horizons together with a 95% confidence interval. That
            means that there is a 2.5% probability of an actual outcome that is below (above) the worst (best) expected outcome.
          </HelpPopupIcon>
          <Desktop>
            <div style={{ float: "right", marginRight: "3em" }}>{this.renderYearsMenu()}</div>
            {this.scaleFactorSlider({ float: "right", marginRight: "4em", marginTop: 5 })}
          </Desktop>

          <h3 className="section-first" style={{ paddingTop: 0 }}>
            Simulation Forward
          </h3>
        </div>
        <Tablet>
          <div style={{ marginBottom: "2em" }}>
            <div style={{ float: "right" }}>{this.renderYearsMenu()}</div>
            {this.scaleFactorSlider({})}
          </div>
        </Tablet>

        <Mobile>
          <div style={{ textAlign: "center" }}>{this.renderYearsMenu()}</div>
          {this.scaleFactorSlider({ textAlign: "center", marginTop: "0.5em", marginBottom: "1em" })}
        </Mobile>
        <ForwardSimulationLineChart
          portfolio={this.props.portfolio}
          displayYears={this.state.displayYears}
          investedAmount={this.state.investedAmount}
          scaleFactorContribution={this.props.scaleFactorContribution}
          isSecondary={this.props.secondary}
          onHoverExpectedAmountCallback={(year: number) => {
            me.setState({ year: year + 1 });
          }}
          onHoverLowAmountCallback={(year: number) => {
            me.setState({ year: year + 1 });
          }}
          onHoverHighAmountCallback={(year: number) => {
            me.setState({ year: year + 1 });
          }}
        />
        <ForwardSimulationNumbersPane
          investedAmount={this.state.investedAmount}
          expectedAmount={this.simulator.getExpectedResult(this.state.investedAmount, this.state.year)}
          lowAmount={this.simulator.getLowResult(this.state.investedAmount, this.state.year, this.props.scaleFactorContribution)}
          highAmount={this.simulator.getHighResult(this.state.investedAmount, this.state.year, this.props.scaleFactorContribution)}
          year={this.state.year}
          isSecondary={this.props.secondary}
        />
      </div>
    );
  }

  private renderYearsMenu(): JSX.Element {
    const yearsList = [3, 5, 7, 10, 20];
    const items = [];
    for (const years of yearsList) {
      items.push(
        <Menu.Item
          key={years}
          active={this.state.displayYears === years}
          className="year__selector"
          onClick={() => this.setState({ displayYears: years, year: years })}
        >{`${years} yrs`}</Menu.Item>
      );
    }
    return (
      <Menu compact={true} className="square-corners">
        {items}
      </Menu>
    );
  }

  private simulator = new PortfolioSimulator(this.props.portfolio.calculateRisk(), this.props.portfolio.calculateYield());

  private setScaleFactorContribution(contribution: number) {
    if (typeof this.props.onScaleFactorContributionChange === "function") {
      this.props.onScaleFactorContributionChange(contribution);
    }
  }

  private scaleFactorSlider(extraStyle: any) {
    return (
      <ScaleFactorContributionSlider
        extraStyle={extraStyle}
        scaleFactorContribution={this.props.scaleFactorContribution}
        onScaleFactorContributionChanged={(value: number) => this.setScaleFactorContribution(value)}
      />
    );
  }
}

interface IForwardSimulationReportGraphProps {
  portfolio: AssetClassPortfolio;
  scaleFactorContribution: number;
  titleSuffix?: string;
  isSecondary?: boolean;
}

export function ForwardSimulationReportGraph(props: IForwardSimulationReportGraphProps) {
  const displayYears = 20;
  const investedAmout = 100;
  return (
    <div className="ForwardSimulationGraph chart-section">
      <h3 className="section-first">Simulation Forward {props.titleSuffix}</h3>
      <ForwardSimulationLineChart
        portfolio={props.portfolio}
        displayYears={displayYears}
        investedAmount={investedAmout}
        scaleFactorContribution={props.scaleFactorContribution}
        isSecondary={props.isSecondary ? props.isSecondary : false}
      />
    </div>
  );
}
