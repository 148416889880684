import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Container } from "semantic-ui-react";
import { dialogStyles } from "./AssetClassesStyles";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { AddAssetClassForm } from "./AddAssetClassForm";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers";

interface AddAssetClassDialogProps {
  open: boolean;
  handleClose: () => void;
  handleCreateAssetClass: (assetClass: AssetClassData) => void;
}

export const AddAssetClassDialog: React.FC<AddAssetClassDialogProps> = props => {
  const { open, handleClose, handleCreateAssetClass } = props;
  const classes = dialogStyles();
  const existingAssetClasses = useSelector((store: AppState) => store.financeData.assetClasses.data);

  const handleCreateAssetClassAndClose = (assetClass: AssetClassData) => {
    handleCreateAssetClass(assetClass);
    handleClose();
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle className={classes.dialogTitle} id="add-asset-class-dialog">
          Create asset class
        </DialogTitle>
        <DialogContent>
          <div className={classes.wrapperGrid}>
            <AddAssetClassForm handleCreateAssetClass={handleCreateAssetClassAndClose} existingAssetClasses={existingAssetClasses} />
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
