import React from "react";
import { Container } from "semantic-ui-react";
import { Scatter } from "react-chartjs-2";

import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import {
  buildRiskReturnScatterplotData,
  calculateBoundaries,
  buildRiskReturnScatterplotChartOptions
} from "./RiskReturnScatterplotDataBuilders";

interface IRiskReturnScatterplotProps {
  portfolio: AssetClassPortfolio;
  comparisonPortfolio?: AssetClassPortfolio;
  titleSuffix?: string;
}

export const RiskReturnScatterplotChart = (props: IRiskReturnScatterplotProps) => {
  const data = buildRiskReturnScatterplotData(props.portfolio, props.comparisonPortfolio);
  const boundaries = calculateBoundaries(data);
  const maxX: number = boundaries.maxX ? Number(boundaries.maxX) : 0;
  const maxY: number = boundaries.maxY ? Number(boundaries.maxY) : 0;
  const opts = buildRiskReturnScatterplotChartOptions(maxX, maxY);

  return (
    <div className="RiskReturnScatterplotChart chart-section">
      <h3 style={{ marginBottom: 15 }}>Risk and Return {props.titleSuffix}</h3>
      <Scatter data={data} options={opts} />
    </div>
  );
};

interface IRiskReturnScatterplotSectionProps {
  portfolio: AssetClassPortfolio;
  comparisonPortfolio?: AssetClassPortfolio;
  toned: boolean;
}

export const RiskReturnScatterplotSection = ({ portfolio, comparisonPortfolio, toned }: IRiskReturnScatterplotSectionProps) => (
  <SectionWrap toned={toned}>
    <Container>
      <RiskReturnScatterplotChart portfolio={portfolio} comparisonPortfolio={comparisonPortfolio} />
    </Container>
  </SectionWrap>
);
