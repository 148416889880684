import React, {useState} from "react";
import { Line } from "react-chartjs-2";
import { Menu } from "semantic-ui-react";

import { buildJoinedForwardSimulationGraphData, buildJoinedForwardSimChartOptions } from "./JoinedForwardSimulationGraphDataBuilders";
import { HelpPopupIcon } from "components/HelpPopupIcon/HelpPopupIcon";
import { ScaleFactorContributionSlider } from "components/ScaleFactorContributionSlider/ScaleFactorContributionSlider";
import { Desktop, Mobile, Tablet } from "components/util/responsive";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import JoinedForwardSimulationNumbersPane from "./JoinedForwardSimulationNumbersPane";

import "./JoinedForwardSimulationGraph.css";
import PortfolioSimulator from "business/PortfolioSimulator";

const Y_AXIS_MAX = 500;

interface IGraphLabelsProps {
  active: number;
  labels: string[];
  setActive: (index: number) => void;
}

function GraphLabels(props: IGraphLabelsProps) {
  return (
    <div className="graph-label-container">
      {props.labels.map((label, index) => (
        <div key={index} onClick={() => props.setActive(index)} className={`graph-label ${index === props.active ? "active" : ""}`}>
          {label}
        </div>
      ))}
    </div>
  );
}
interface IJoinedForwardSimulationLineChartProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  isASelected: boolean;
  isBSelected: boolean;
  isBestSelected: boolean;
  isWorstSelected: boolean;
  isExpectedSelected: boolean;
  displayYears: number;
  investedAmount: number;
  scaleFactorContribution: number;
  onHoverExpectedAmountCallback?: (year: number, expectedAmount: number) => void;
  onHoverLowAmountCallback?: (year: number, lowAmount: number) => void;
  onHoverHighAmountCallback?: (year: number, highAmount: number) => void;
}

function JoinedForwardSimulationLineChart(props: IJoinedForwardSimulationLineChartProps) {
  const data = buildJoinedForwardSimulationGraphData(
    props.displayYears,
    props.portfolioA,
    props.portfolioB,
    props.isASelected,
    props.isBSelected,
    props.isBestSelected,
    props.isWorstSelected,
    props.isExpectedSelected,
    props.investedAmount,
    props.scaleFactorContribution
  );
  const opts = buildJoinedForwardSimChartOptions(
    1,
    2,
    3,
    !props.isBestSelected && !props.isExpectedSelected && !props.isWorstSelected ? Y_AXIS_MAX : undefined,
    props.onHoverExpectedAmountCallback,
    props.onHoverLowAmountCallback,
    props.onHoverHighAmountCallback
  );

  return <Line data={data} options={opts} />;
}

interface IJoinedForwardSimulationGraphProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  scaleFactorContribution: number;
  onScaleFactorContributionChange: (contribution: number) => void;
}

export const JoinedForwardSimulationGraph = (props: IJoinedForwardSimulationGraphProps) => {
  // State declarations using useState
  const investedAmount = 100;
  const [year, setYear] = useState(20);
  const [displayYears, setDisplayYears] = useState(20);
  const [activeLabel, setActiveLabel] = useState(0);

  const simulatorA = new PortfolioSimulator(props.portfolioA.calculateRisk(), props.portfolioA.calculateYield());
  const simulatorB = new PortfolioSimulator(props.portfolioB.calculateRisk(), props.portfolioB.calculateYield());

  // Helper functions
  const renderYearsMenu = () => {
    const yearsList = [3, 5, 7, 10, 20];
    const items = yearsList.map(years => (
      <Menu.Item
        key={years}
        active={displayYears === years}
        className="year__selector"
        onClick={() => {
          setDisplayYears(years);
          setYear(years);
        }}
      >
        {`${years} yrs`}
      </Menu.Item>
    ));

    return (
      <Menu compact={true} className="square-corners">
        {items}
      </Menu>
    );
  };

  const scaleFactorSlider = (extraStyle: any) => (
    <ScaleFactorContributionSlider
      extraStyle={extraStyle}
      scaleFactorContribution={props.scaleFactorContribution}
      onScaleFactorContributionChanged={(value) => setScaleFactorContribution(value)}
    />
  );

  const setScaleFactorContribution = (contribution: number) => {
    if (typeof props.onScaleFactorContributionChange === "function") {
      props.onScaleFactorContributionChange(contribution);
    }
  };

  return (
    <>
      <GraphLabels active={activeLabel} labels={["A", "B", "A & B", "Expected A & B", "Worst A & B", "Best A & B"]} setActive={setActiveLabel} />
      <div className="JoinedForwardSimulationGraph">
      <div className="graph-header-container">
              <HelpPopupIcon style={{ float: "right", marginTop: 8 }}>
                The graph displays the expected value of an investment for different time horizons together with a 95% confidence interval. That
                means that there is a 2.5% probability of an actual outcome that is below (above) the worst (best) expected outcome.
              </HelpPopupIcon>
              <Desktop>
                <div style={{ float: "right", marginRight: "3em" }}>{renderYearsMenu()}</div>
                {scaleFactorSlider({ float: "right", marginRight: "4em", marginTop: 5 })}
              </Desktop>

              <h3 className="section-first" style={{ paddingTop: 0 }}>
                Simulation Forward
              </h3>
            </div>
            <Tablet>
              <div style={{ marginBottom: "2em" }}>
                <div style={{ float: "right" }}>{renderYearsMenu()}</div>
                {scaleFactorSlider({})}
              </div>
            </Tablet>

            <Mobile>
              <div style={{ textAlign: "center" }}>{renderYearsMenu()}</div>
              {scaleFactorSlider({ textAlign: "center", marginTop: "0.5em", marginBottom: "1em" })}
            </Mobile>
            <JoinedForwardSimulationLineChart
              portfolioA={props.portfolioA}
              portfolioB={props.portfolioB}
              displayYears={displayYears}
              investedAmount={investedAmount}
              scaleFactorContribution={props.scaleFactorContribution}
              isASelected={activeLabel !== 1}
              isBSelected={activeLabel !== 0}
              isBestSelected={activeLabel === 5}
              isWorstSelected={activeLabel === 4}
              isExpectedSelected={activeLabel === 3}
              onHoverExpectedAmountCallback={(year: number) => {
                setYear(year + 1);
              }}
              onHoverLowAmountCallback={(year: number) => {
                setYear(year + 1);
              }}
              onHoverHighAmountCallback={(year: number) => {
                setYear(year + 1);
              }}
            />
            <JoinedForwardSimulationNumbersPane
              investedAmount={investedAmount}
              expectedAmountA={simulatorA.getExpectedResult(investedAmount, year)}
              lowAmountA={simulatorA.getLowResult(investedAmount, year, props.scaleFactorContribution)}
              highAmountA={simulatorA.getHighResult(investedAmount, year, props.scaleFactorContribution)}
              expectedAmountB={simulatorB.getExpectedResult(investedAmount, year)}
              lowAmountB={simulatorB.getLowResult(investedAmount, year, props.scaleFactorContribution)}
              highAmountB={simulatorB.getHighResult(investedAmount, year, props.scaleFactorContribution)}

              year={year}
            />
      </div>
    </>
  );
};

interface IJoinedForwardSimulationReportGraphProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  scaleFactorContribution: number;
}

export function JoinedForwardSimulationReportGraph(props: IJoinedForwardSimulationReportGraphProps) {
  const displayYears = 20;
  const investedAmout = 100;
  return (
    <div className="JoinedForwardSimulationGraph chart-section">
      <h3 className="section-first">Simulation Forward</h3>
      <JoinedForwardSimulationLineChart
        portfolioA={props.portfolioA}
        portfolioB={props.portfolioB}
        isASelected={true}
        isBSelected={true}
        isBestSelected={false}
        isWorstSelected={false}
        isExpectedSelected={false}
        displayYears={displayYears}
        investedAmount={investedAmout}
        scaleFactorContribution={props.scaleFactorContribution}
      />
    </div>
  );
}
