import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UserProfile, UpdateUserRequest, CreateUserRequest } from "apiclient/responsePayloads/User";
import { Grid, Typography, Zoom } from "@material-ui/core";
import { Container, Form, FormInput } from "semantic-ui-react";
import { ActivationField } from "./ActivationField";
import { dialogStyles } from "./EditDialogStyles";
import { AxiosError } from "axios";
import { responseMessageFromCode } from "../../../components/util/responses";

interface DialogProps {
  open: boolean;
  user: UserProfile | null;
  handleClose: () => void;
  handleCreateUser: (createUserRequest: CreateUserRequest) => Promise<void | AxiosError>;
  handleUpdateUser: (id: string, updateUserRequest: UpdateUserRequest) => Promise<void | AxiosError>;
}

interface ErrorResponse {
  data: {
    error: string;
  };
}

interface ErrorResponse {
  data: {
    error: string;
  };
}

export const EditDialog: React.FC<DialogProps> = props => {
  const { user, open, handleClose, handleCreateUser, handleUpdateUser } = props;
  const classes = dialogStyles();
  const [firstName, setFirstName] = React.useState(user ? user.firstName : "");
  const [lastName, setLastName] = React.useState(user ? user.lastName : "");
  const [email, setEmail] = React.useState(user ? user.email : "");
  const [organization, setOrganization] = React.useState(user ? user.organization : "");
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isDisabled, setIsDisabled] = React.useState(user ? user.isDisabled : false);

  const userToSave = (): UpdateUserRequest => {
    return {
      firstName,
      lastName,
      organization,
      isDisabled
    };
  };

  const userToCreate = (): CreateUserRequest => {
    return {
      firstName,
      lastName,
      email: email.toLowerCase(),
      organization
    };
  };

  const disableUser = (isDisabled: boolean) => {
    setIsDisabled(!isDisabled);
  };

  const isChanged = () => {
    if (user) {
      if (
        user.firstName !== firstName ||
        user.lastName !== lastName ||
        user.organization !== organization ||
        user.isDisabled !== isDisabled
      ) {
        return true;
      } else return false;
    } else return true;
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!isChanged) return;

    if (user) {
      handleUpdateUser(user.id, userToSave())
        .then(res => {
          if (res instanceof Error) {
            const response = (res as AxiosError<{ error: string }>).response;
            if (res.response) setErrorMessage(response!.data.error ? response!.data.error : responseMessageFromCode(res.code));
            else responseMessageFromCode(res.code);
          } else {
            handleClose();
          }
        })
        .catch(err => setErrorMessage(responseMessageFromCode(err.code)));
    } else {
      handleCreateUser(userToCreate())
        .then(res => {
          if (res instanceof Error) {
            const response = res.response as ErrorResponse;
            setErrorMessage(
              res.response
                ? response.data.error
                  ? response.data.error
                  : responseMessageFromCode(res.code)
                : responseMessageFromCode(res.code)
            );
          } else {
            handleClose();
          }
        })
        .catch(err => setErrorMessage(responseMessageFromCode(err.code)));
    }
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="edit-dialog-title" aria-describedby="edit-dialog-description">
        <DialogTitle className={classes.dialogTitle} id="edit-dialog-title">
          {user ? `Edit user: ${user.firstName} ${user.lastName}` : "Create new user"}
        </DialogTitle>
        <DialogContent>
          <Form autoComplete="off" id="edit-form" onSubmit={e => handleSubmit(e)}>
            <Grid container spacing={2} className={classes.wrapperGrid}>
              <Grid item container spacing={1} style={{ textAlign: "center" }} xs={12}>
                {user && !user.isActivated && (
                  <Grid item xs={6}>
                    <Typography className={classes.noPadTop} variant="overline" style={{ color: "darkOrange" }}>
                      This user account is not activated.
                    </Typography>
                  </Grid>
                )}
                <Zoom in={isDisabled} mountOnEnter unmountOnExit>
                  <Grid item xs={6}>
                    <Typography className={classes.noPadTop} variant="overline" color="secondary">
                      This user account is disabled.
                    </Typography>
                  </Grid>
                </Zoom>
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  icon="address card"
                  iconPosition="left"
                  size="small"
                  id="firstName"
                  type="text"
                  label="First Name"
                  placeholder="John"
                  value={firstName}
                  required
                  onChange={e => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  icon="address card"
                  iconPosition="left"
                  size="small"
                  id="lastName"
                  type="text"
                  label="Last Name"
                  placeholder="Smith"
                  value={lastName}
                  required
                  onChange={e => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  fluid
                  icon="mail"
                  label="Email"
                  iconPosition="left"
                  placeholder="john.smith@mail.com"
                  size="small"
                  id="email"
                  type="email"
                  disabled={user !== null}
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  fluid
                  icon="building"
                  iconPosition="left"
                  label="Organization"
                  name="organization"
                  placeholder="JohnSmith AB"
                  size="small"
                  id="organization"
                  type="text"
                  value={organization}
                  onChange={e => setOrganization(e.target.value)}
                />
              </Grid>
              {user && user.activationURL && <ActivationField activationURL={user.activationURL} />}
              <Grid item xs={12}>
                {errorMessage && (
                  <div className="ui small negative message">
                    <div className="header">Error</div>
                    <p>{errorMessage}</p>
                  </div>
                )}
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Zoom in={user !== null && isChanged()} mountOnEnter unmountOnExit>
            <Typography variant="overline" color="secondary">
              {" "}
              You have unsaved changes
            </Typography>
          </Zoom>
          {user && (
            <Button variant="outlined" color={isDisabled ? "primary" : "secondary"} onClick={() => disableUser(isDisabled)}>
              {isDisabled ? "Enable " : "Disable "} account
            </Button>
          )}
          <Button onClick={() => handleClose()}>Close</Button>
          {user ? (
            <Button disabled={!isChanged()} variant="contained" color="primary" type="submit" form="edit-form">
              Save
            </Button>
          ) : (
            <Button variant="contained" color="primary" type="submit" form="edit-form">
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};
