import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

interface CustomLegendProps {
  data: {
    name: string;
    color: string;
  }[];
}

const CustomLegend: React.FC<CustomLegendProps> = props => {
  const { data } = props;

  return (
    <Grid container item xs={12}>
      {data.map((d, i) => {
        return (
          <Grid key={i} item style={{ textAlign: "center" }}>
            <div style={{ width: 100, height: 25, border: "1px solid lightGrey", backgroundColor: d.color }} />
            <Typography variant="overline">{d.name}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CustomLegend;
