import React, { useState } from "react";
import { TextField, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Zoom } from "@material-ui/core";
import { assetClassesStyles } from "./AssetClassesStyles";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers";
import { AddFundSelector } from "./AddFundSelector";
import _ from "lodash";
import { ListFunds } from "./ListFunds";
import { updateAssetClassOrder } from "admin/AssetClassesAPI";
import { fetchAssetClasses } from "store/financedata/actions";
import { AxiosError, AxiosResponse } from "axios";

interface EditAssetClassProps {
  open: boolean;
  assetClass: AssetClassData;
  assetClasses: AssetClassData[];
  handleUpdateAssetClass: (assetClass: AssetClassData) => void;
  handleClose: () => void;
}

export const EditAssetClass: React.FC<EditAssetClassProps> = props => {
  const { assetClass, open, handleUpdateAssetClass, handleClose, assetClasses } = props;
  const classes = assetClassesStyles();
  const dispatch = useDispatch();
  const existingFunds = useSelector((state: AppState) => state.financeData.funds.data);
  const [name, setName] = useState(assetClass.name);
  const [risk, setRisk] = useState(assetClass.risk);
  const [yld, setYield] = useState(assetClass.yield);
  const [order, setOrder] = useState(assetClass.order);
  const assetClassesCopy: AssetClassData[] = [];
  Object.assign(assetClassesCopy, assetClasses);
  const highestOrderClass: AssetClassData = assetClassesCopy.sort((a: AssetClassData, b: AssetClassData) => b.order - a.order)[0];
  const funds = assetClass.funds;
  const [fundsChanged, setFundsChanged] = useState(assetClass.funds);

  const handleChangeYield = (value: string) => {
    if (value === "") setYield(0);
    else setYield(parseFloat(value));
  };

  const handleChangeRisk = (value: string) => {
    if (value === "") setRisk(0);
    else setRisk(parseFloat(value));
  };

  const handleChangeOrder = (value: string) => {
    if (value === "") setOrder(1);
    else setOrder(parseInt(value));
  };

  const handleRemoveFund = (fund: string) => {
    setFundsChanged(fundsChanged.filter(f => f !== fund));
  };

  const handleAddFund = (fund: string) => {
    if (fund !== "") {
      setFundsChanged([...fundsChanged, fund]);
    }
  };

  const adjustOrder = (newOrder: number) => {
    if (highestOrderClass) {
      const highestOrder = highestOrderClass.order;
      //if admin wants to...
      if (newOrder > assetClass.order) {
        //...increase order of asset class...
        if (newOrder + 1 > highestOrder) {
          //...and new order is higher than highest by more than 1
          if (assetClass.order === highestOrder) return highestOrder;
          //...and original order is aleady highest
          else return highestOrder + 1;
          //...and original order is not aleady highest
        } else {
          //...and new order is not higher than highest
          return newOrder;
        }
      } else if (newOrder < assetClass.order) {
        //...decrease order of asset class...
        if (newOrder < 0) return 1;
        //...and new order is less than 0
        else return newOrder;
        //...and new order is 1
      } else return newOrder;
      //...keep same order
    } else return 1;
    //if this is first asset class to create
  };

  const updateSortOrder = (oldOrder: number, newOrder: number): Promise<(AxiosResponse | AxiosError)[]> => {
    let allPromises: Promise<AxiosResponse | AxiosError>[] = [];

    if (oldOrder !== newOrder) {
      if (newOrder < oldOrder) {
        //change values of all asset classes "above" chosen one
        for (let i = oldOrder; i > newOrder; i--) {
          let obj: AssetClassData | undefined = undefined;
          if (assetClasses) obj = assetClasses.find(ac => ac.order === i - 1);
          if (obj) allPromises.push(updateAssetClassOrder(obj, i));
        }
      } else if (newOrder > oldOrder) {
        //change values of all asset classes "below" chosen one
        for (let i = oldOrder; i < newOrder; i++) {
          let obj: AssetClassData | undefined = undefined;
          if (assetClasses) obj = assetClasses.find(ac => ac.order === i + 1);
          if (obj) allPromises.push(updateAssetClassOrder(obj, i));
        }
      }
    }
    return Promise.all(allPromises);
  };

  const handleSaveButtonClick = () => {
    let newOrder = order;
    if (order + 1 > highestOrderClass.order) {
      newOrder = adjustOrder(order);
    }

    const updatedAssetClass: AssetClassData = {
      id: assetClass.id,
      name,
      risk,
      yield: yld,
      order: newOrder,
      funds: fundsChanged,
      correlations: assetClass.correlations
    };
    updateSortOrder(assetClass.order, newOrder)
      .then(() => handleUpdateAssetClass(updatedAssetClass))
      .catch((error: Error) => {
        console.log(error);
        dispatch(fetchAssetClasses());
      });
    handleClose();
  };

  const isChanged = () => {
    if (name !== assetClass.name || risk !== assetClass.risk || yld !== assetClass.yield || (order !== assetClass.order && order > 0)) return true;
    else if (!_.isEqual(funds.sort(), fundsChanged.sort())) return true;
    else return false;
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="edit-dialog-title" aria-describedby="edit-dialog-description">
        <DialogTitle className={classes.borderBot} id="edit-dialog-title">
          Edit asset class
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="overline">Name</Typography>
              <TextField id="name" type="text" size="small" variant="outlined" fullWidth value={name} required onChange={e => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline">Risk</Typography>
              <TextField id="risk" type="number" variant="outlined" size="small" fullWidth value={risk} onChange={e => handleChangeRisk(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline">Yield</Typography>
              <TextField id="yield" type="number" variant="outlined" size="small" fullWidth value={yld} onChange={e => handleChangeYield(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline">Order</Typography>
              <TextField
                id="order"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={order}
                error={order < 1}
                helperText={order < 1 && "Order cannot be less than 1"}
                onChange={e => handleChangeOrder(e.target.value)}
              />
            </Grid>
            <Grid container item xs={12}>
              <ListFunds handleRemoveFund={handleRemoveFund} funds={fundsChanged.filter(f => !f.startsWith("index:"))} />
              {existingFunds && <AddFundSelector handleAddFund={handleAddFund} existingFunds={existingFunds} selectedFunds={fundsChanged} />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Zoom in={isChanged()} mountOnEnter unmountOnExit>
            <Typography variant="overline" color="secondary">
              You have unsaved changes
            </Typography>
          </Zoom>
          <Button onClick={() => handleClose()}>Close</Button>
          <Button
            disabled={fundsChanged.filter(f => !f.startsWith("index:"))[0] === undefined || !isChanged()}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSaveButtonClick();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
