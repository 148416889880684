import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, CircularProgress, Zoom, Typography, Grid } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { correlationMatrixStyles } from "./CorrelationMatrixStyles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers";
import { updateCorrelation } from "admin/CorrelationMatrixAPI";
import { Container } from "semantic-ui-react";
import { fetchAssetClasses } from "store/financedata/actions";
import { AxiosError, AxiosResponse } from "axios";
import { requestProgress } from "admin/XLUtil";

interface CorrelationMatrixProps {}

interface ChangedCorrelationsType {
  [k: string]: number;
}

export const CorrelationMatrix: React.FC<CorrelationMatrixProps> = _ => {
  const classes = correlationMatrixStyles();
  const dispatch = useDispatch();
  const assetClasses = useSelector((store: AppState) => store.financeData.assetClasses.data);
  const [changedCorrelations, setChangedCorrelations] = React.useState<ChangedCorrelationsType>({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [progress, setProgress] = React.useState(0);

  const progress_cb = (value: number) => {
    setProgress(value);
  };

  React.useEffect(() => {
    setChangedCorrelations({});
    setLoading(false);
    setProgress(0);
  }, [assetClasses]);

  const handleSave = () => {
    let allPromises: Promise<AxiosResponse<any> | AxiosError>[] = [];
    setLoading(true);
    setError(undefined);
    for (let key in changedCorrelations) {
      allPromises.push(updateCorrelation(decodeKey(key)[0], decodeKey(key)[1], changedCorrelations[key]));
    }

    requestProgress(allPromises, progress_cb)
      .then(res => {
        if (res.find(result => result instanceof Error)) {
          setError("Could not update some of correlations. Please, try again.");
        }
      })
      .finally(() => {
        dispatch(fetchAssetClasses());
      });
  };

  const handleAssetChange = (key: string, value: string) => {
    let newValue = 0;
    if (value !== "") newValue = parseFloat(value);

    setChangedCorrelations({ ...changedCorrelations, [key]: newValue });
  };

  const checkIfChanged = (key: string, value: number): number => {
    if (changedCorrelations[key] !== undefined) return changedCorrelations[key];
    else return value;
  };

  const createKey = (firstAssetClassID: string, secondAssetClassID: string): string => {
    const key = [firstAssetClassID, secondAssetClassID].sort().join("|");
    return key;
  };

  const decodeKey = (key: string): string[] => {
    return key.split("|");
  };

  return (
    <div style={{ margin: "16px auto" }}>
      {assetClasses && (
        <div>
          <Container style={{ marginBottom: "2em" }}>
            <h2>Correlation Matrix</h2>
          </Container>
          <div className={classes.wrapper}>
            <Table size="small" className={classes.table} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {assetClasses.map((aClass, i) => (
                    <TableCell key={i} className={classes.tableHeaderCell}>
                      {aClass.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {assetClasses.map((rowAssetClass, rowIndex) => {
                  return (
                    <TableRow key={rowIndex}>
                      <TableCell className={classes.tableHeaderCell}>{rowAssetClass.name}</TableCell>

                      {assetClasses.map((columnAssetClass, columnIndex) => {
                        const key = createKey(rowAssetClass.id, columnAssetClass.id);
                        const value = checkIfChanged(key, rowAssetClass.correlations[columnAssetClass.id]);

                        return (
                          <TableCell key={columnIndex} className={classes.tableCell}>
                            {columnIndex < rowIndex ? (
                              <TextField
                                className={classes.textField}
                                type="number"
                                value={value}
                                disabled={loading}
                                onChange={e => handleAssetChange(key, e.target.value)}
                                variant="outlined"
                                size="small"
                              ></TextField>
                            ) : (
                              <span className={classes.disabledValue}>{value}</span>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>

          <Container>
            <div className={classes.footer}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={loading || Object.keys(changedCorrelations).length === 0}
                    className={classes.saveButton}
                    onClick={handleSave}
                    startIcon={loading ? <CircularProgress size={16} /> : <SaveIcon />}
                  >
                    {loading ? `Saving... ${progress}%` : "Save"}
                  </Button>
                </Grid>
                {error && (
                  <Grid item>
                    <Zoom in={error !== undefined}>
                      <Typography noWrap className={classes.errorBox} variant="overline">
                        {error}
                      </Typography>
                    </Zoom>
                  </Grid>
                )}
              </Grid>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};
