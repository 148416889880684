import React from "react";
import { Line } from "react-chartjs-2";
import { Container } from "semantic-ui-react";
import _ from "lodash";

import { buildChartOptions, buildDrawdownChartData } from "./DrawdownChartDataBuilders";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { HelpPopupIcon } from "components/HelpPopupIcon/HelpPopupIcon";
import { fractionToPercentageTooltipY } from "util/misc-utils";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import { FundReturns } from "apiclient/FundReturns";

interface IDrawdownChartProps {
  portfolio: AssetClassPortfolio;
  comparisonPortfolio?: AssetClassPortfolio;
  assetClassReturns: AssetClassReturns;
  showDisclaimerIcon?: boolean;
  fundReturns?: FundReturns;
  titleSuffix?: string;
}

// TODO: Make use of returnsType all the way to data builder.

export function DrawdownChart(props: IDrawdownChartProps) {
  const opts = buildChartOptions();
  _.set(opts, "tooltips.callbacks.label", fractionToPercentageTooltipY);

  // TODO: Fix this!
  // const returns: AssetClassReturns | FundReturns = props.fundReturns !== undefined ? props.fundReturns : props.assetClassReturns
  const data = buildDrawdownChartData(props.portfolio, props.comparisonPortfolio, props.assetClassReturns, props.fundReturns);

  return (
    <div className="DrawdownChart chart-section">
      {props.showDisclaimerIcon && (
        <HelpPopupIcon style={{ float: "right" }}>
          The graph displays the percentage fall in value from the maximum value that the portfolio had previously reached. The series is
          negative as long as the current value is below the previous maximum value and until a new peak has been reached.
        </HelpPopupIcon>
      )}
      <h3 style={{ marginBottom: 15 }}>Drawdown from Previous Highest Value {props.titleSuffix}</h3>
      <Line data={data} options={opts} />
    </div>
  );
}

interface IDrawdownChartSectionProps {
  toned: boolean;
  portfolio: AssetClassPortfolio;
  comparisonPortfolio: AssetClassPortfolio;
  assetClassReturns: AssetClassReturns;
  fundReturns?: FundReturns;
}

export const DrawdownChartSection = ({
  portfolio,
  comparisonPortfolio,
  assetClassReturns,
  fundReturns,
  toned
}: IDrawdownChartSectionProps) => (
  <SectionWrap toned={toned}>
    <Container style={{ paddingTop: 1 }}>
      <DrawdownChart
        portfolio={portfolio}
        comparisonPortfolio={comparisonPortfolio}
        showDisclaimerIcon={true}
        assetClassReturns={assetClassReturns}
        fundReturns={fundReturns}
      />
    </Container>
  </SectionWrap>
);
