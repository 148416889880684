import React from "react";
import "./Footer.css";

interface FooterProps {}

export const Footer: React.FC<FooterProps> = props => {
  return (
    <section className="footer">
      <div className="relative">
        <div className="grid-lines text-black-grid showing">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line hidden md:block"></div>
          <div className="line hidden md:block"></div>
        </div>
        <div className="grid relative mb-16">
          <div className="col col-third mb-12 lg:mb-0">
            <h6 className="h5">Carnegie Fonder</h6>
            <nav className="nav-footer" aria-label="Carnegie Fonder">
              <div className="menu-carnegie-fonder-container">
                <ul id="menu-carnegie-fonder" className="nav">
                  <li id="menu-item-674" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-674 showing">
                    <a href="https://www.carnegiefonder.se/om-oss/">Om oss</a>
                  </li>
                  <li id="menu-item-24834" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24834 showing">
                    <a href="https://www.carnegiefonder.se/karriar/">Karriär</a>
                  </li>
                  <li id="menu-item-1075" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1075 showing">
                    <a href="https://www.carnegiefonder.se/kontakta-oss/">Kontakta oss</a>
                  </li>
                  <li id="menu-item-23792" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-23792 showing">
                    <a href="https://www.carnegiefonder.se/samhallsengagemang/">Samhällsengagemang</a>
                  </li>
                  <li id="menu-item-23791" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-23791 showing">
                    <a href="https://www.carnegiefonder.se/styrelse-och-ledning/">Styrelse och ägare</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="col col-third mb-8 lg:mb-0">
            <h6 className="h5">Upptäck</h6>
            <nav className="nav-footer" aria-label="Upptäck">
              <div className="menu-upptack-container">
                <ul id="menu-upptack" className="nav">
                  <li
                    id="menu-item-23802"
                    className="menu-item menu-item-type-post_type_archive menu-item-object-fund menu-item-23802 showing"
                  >
                    <a href="https://www.carnegiefonder.se/fonder/">Våra fonder</a>
                  </li>
                  <li id="menu-item-26164" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26164 showing">
                    <a href="https://www.carnegiefonder.se/select-fonder/">Select-fonder</a>
                  </li>
                  <li id="menu-item-675" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-675 showing">
                    <a href="https://www.carnegiefonder.se/hallbarhet/">Hållbarhet</a>
                  </li>
                  <li id="menu-item-24835" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24835 showing">
                    <a href="https://www.carnegiefonder.se/nyhetsbrev/">Nyhetsbrev</a>
                  </li>
                  <li id="menu-item-676" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-676 showing">
                    <a href="https://www.carnegiefonder.se/fragor-svar/">Frågor &amp; svar</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="col col-third mb-8 lg:mb-0">
            <h6 className="h5">Legalt</h6>
            <nav className="nav-footer" aria-label="Legalt">
              <div className="menu-legalt-container">
                <ul id="menu-legalt" className="nav">
                  <li id="menu-item-24838" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-24838 showing">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.carnegiefonder.se/wp-content/uploads/file-manager/pdfer/informationsbroschyr/svenska/Informationsbroschyr.pdf"
                    >
                      Informationsbroschyr
                    </a>
                  </li>
                  <li id="menu-item-24839" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24839 showing">
                    <a href="https://www.carnegiefonder.se/handelsvillkor/">Handelsvillkor</a>
                  </li>
                  <li id="menu-item-24840" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24840 showing">
                    <a href="https://www.carnegiefonder.se/blanketter/">Blanketter</a>
                  </li>
                  <li id="menu-item-24841" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24841 showing">
                    <a href="https://www.carnegiefonder.se/legala-dokument/">Legala dokument</a>
                  </li>
                  <li id="menu-item-26085" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26085 showing">
                    <a href="https://www.carnegiefonder.se/legala-dokument/">Behandling av personuppgifter</a>
                  </li>
                  <li
                    id="menu-item-677"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-677 showing"
                  >
                    <a href="https://www.carnegiefonder.se/om-kakor-cookies/">Cookies</a>
                  </li>
                  <li id="menu-item-24889" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24889 showing">
                    <a href="https://www.carnegiefonder.se/arsrapporter/">Årsrapporter</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="flex flex-wrap justify-between items-end relative">
          <div className="flex-1 pl-4 md:pl-8">
            <p className="text-sm">© 1988-2023</p>
          </div>
          <div className="flex-1 flex justify-center"></div>
          <div className="flex flex-1 justify-end pr-4 md:pr-8">
            <div className="flex gap-4">
              <a
                className="footer-social"
                href="https://www.linkedin.com/company/carnegie-fonder-ab/"
                aria-label="Follow us on LinkedIn"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#F3F1E7"></circle>
                  <path
                    d="M24.8786 16.3362L25.0235 16.338C28.0721 16.338 29.3122 17.7556 29.7664 19.903L29.8114 20.1317L29.8508 20.3656L29.8849 20.6046C29.8902 20.6448 29.8953 20.6852 29.9001 20.7259L29.9268 20.972L29.949 21.2228L29.9667 21.478L29.9804 21.7374L29.9903 22.0008L29.9966 22.2682L29.9996 22.5392L30 29.9531H25.8685L25.8677 23.1304L25.8641 22.867L25.8556 22.6016L25.8461 22.4246L25.8329 22.2484L25.8154 22.0737C25.8121 22.0448 25.8085 22.0159 25.8048 21.9872L25.7795 21.816C25.6108 20.7978 25.1225 19.9531 23.7089 19.9531C22.0383 19.9531 21.4633 20.9645 21.2886 22.2267L21.2658 22.4131L21.2483 22.6025L21.2355 22.7945L21.2269 22.9885L21.221 23.282L21.2207 29.9531H17.0892V16.6667H21.0798V18.4977H21.1268C21.9007 17.1774 23.3368 16.3428 24.8786 16.3362ZM14.507 16.6667V29.9531H10.3756V16.6667H14.507ZM12.3944 10C13.7559 10 14.8357 11.0798 14.8357 12.4413C14.8357 13.7559 13.7089 14.8357 12.3944 14.8357C11.0798 14.8357 10 13.7089 10 12.3944C10 11.0798 11.0798 10 12.3944 10Z"
                    fill="#081519"
                  ></path>
                </svg>
              </a>
              <a
                className="footer-social"
                href="https://twitter.com/carnegiefonder"
                aria-label="Follow us on Twitter"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
                    fill="#F3F1E7"
                  ></path>
                  <path
                    d="M16.3397 30.55C25.2097 30.55 30.0597 23.2 30.0597 16.83C30.0597 16.62 30.0597 16.41 30.0497 16.21C30.9897 15.53 31.8097 14.68 32.4597 13.71C31.5997 14.09 30.6697 14.35 29.6897 14.47C30.6897 13.87 31.4497 12.93 31.8097 11.8C30.8797 12.35 29.8497 12.75 28.7497 12.97C27.8697 12.03 26.6197 11.45 25.2297 11.45C22.5697 11.45 20.4097 13.61 20.4097 16.27C20.4097 16.65 20.4497 17.02 20.5397 17.37C16.5297 17.17 12.9797 15.25 10.5997 12.33C10.1897 13.04 9.94973 13.87 9.94973 14.75C9.94973 16.42 10.7997 17.9 12.0997 18.76C11.3097 18.74 10.5697 18.52 9.91973 18.16V18.22C9.91973 20.56 11.5797 22.5 13.7897 22.95C13.3897 23.06 12.9597 23.12 12.5197 23.12C12.2097 23.12 11.9097 23.09 11.6097 23.03C12.2197 24.95 13.9997 26.34 16.1097 26.38C14.4597 27.67 12.3797 28.44 10.1197 28.44C9.72973 28.44 9.34973 28.42 8.96973 28.37C11.0797 29.75 13.6197 30.55 16.3397 30.55Z"
                    fill="#081519"
                  ></path>
                </svg>
              </a>
              <a
                className="footer-social"
                href="https://www.instagram.com/carnegiefonder/"
                aria-label="Follow us on Instagram"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
                    fill="#F8F7EF"
                  ></path>
                  <path
                    d="M20 10.163C23.204 10.163 23.584 10.175 24.85 10.233C28.102 10.381 29.621 11.924 29.769 15.152C29.827 16.417 29.838 16.797 29.838 20.001C29.838 23.206 29.826 23.585 29.769 24.85C29.62 28.075 28.105 29.621 24.85 29.769C23.584 29.827 23.206 29.839 20 29.839C16.796 29.839 16.416 29.827 15.151 29.769C11.891 29.62 10.38 28.07 10.232 24.849C10.174 23.584 10.162 23.205 10.162 20C10.162 16.796 10.175 16.417 10.232 15.151C10.381 11.924 11.896 10.38 15.151 10.232C16.417 10.175 16.796 10.163 20 10.163V10.163ZM20 8C16.741 8 16.333 8.014 15.053 8.072C10.695 8.272 8.273 10.69 8.073 15.052C8.014 16.333 8 16.741 8 20C8 23.259 8.014 23.668 8.072 24.948C8.272 29.306 10.69 31.728 15.052 31.928C16.333 31.986 16.741 32 20 32C23.259 32 23.668 31.986 24.948 31.928C29.302 31.728 31.73 29.31 31.927 24.948C31.986 23.668 32 23.259 32 20C32 16.741 31.986 16.333 31.928 15.053C31.732 10.699 29.311 8.273 24.949 8.073C23.668 8.014 23.259 8 20 8V8ZM20 13.838C16.597 13.838 13.838 16.597 13.838 20C13.838 23.403 16.597 26.163 20 26.163C23.403 26.163 26.162 23.404 26.162 20C26.162 16.597 23.403 13.838 20 13.838ZM20 24C17.791 24 16 22.21 16 20C16 17.791 17.791 16 20 16C22.209 16 24 17.791 24 20C24 22.21 22.209 24 20 24ZM26.406 12.155C25.61 12.155 24.965 12.8 24.965 13.595C24.965 14.39 25.61 15.035 26.406 15.035C27.201 15.035 27.845 14.39 27.845 13.595C27.845 12.8 27.201 12.155 26.406 12.155Z"
                    fill="#0B1519"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
