import React, { useState } from "react";
import { Grid, Button, Typography, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { IFund } from "apiclient/responsePayloads/Fund";

interface AddFundSelectorProps {
  handleAddFund: (fund: string) => void;
  existingFunds: IFund[];
  selectedFunds: string[];
}

export const AddFundSelector: React.FC<AddFundSelectorProps> = props => {
  const { handleAddFund, existingFunds, selectedFunds } = props;
  const [selectedFund, setSelectedFund] = useState("");
  const filteredExistingFunds = existingFunds.filter(function(fund) {
    for (var key in selectedFunds) {
      if (fund.id === selectedFunds[key] || fund.id.startsWith("index:")) return false;
    }
    return true;
  });

  const handleFundSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFund(event.target.value as string);
  };

  const handleAddFundClick = (selectedFund: string) => {
    handleAddFund(selectedFund);
    setSelectedFund("");
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="overline">Add fund</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl disabled={filteredExistingFunds.length < 1} variant="outlined" size="small" style={{ minWidth: 150 }}>
          <InputLabel id="fund">Choose fund</InputLabel>
          <Select labelWidth={80} labelId="fund" id="fund-select" value={selectedFund} onChange={handleFundSelect}>
            {filteredExistingFunds &&
              filteredExistingFunds.map((f, i) => (
                <MenuItem key={i} value={f.id}>
                  {f.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          disabled={selectedFund === ""}
          style={{ marginLeft: 10, padding: "7px 0 7px 0" }}
          variant="outlined"
          onClick={() => handleAddFundClick(selectedFund)}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};
