import PortfolioSimulator from "business/PortfolioSimulator";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { primaryPortfolioColor, secondaryPortfolioColor, primaryPortfolioColorTransparent, secondaryPortfolioColorTransparent, primaryBackgroundColor, secondaryBackgroundColor } from "util/colors";

// TODO: Replace any types with real types !!!

/**
 * Builds graph data for forward simulation of a portfolio's value development.
 *
 * @param numYears Number of years to simulate
 * @param portfolio Portfolio on which to run simulation
 * @param investedAmount Initial value of portfolio at year 0.
 * @param scaleFactorContribution If `scaleFactors` supplied, how much should it contribute? (Value between 0 - 1)
 * @param scaleFactors A list of scale factors to turn forward simulation into empiric distribution instead of normal distribution. Optional.
 * @returns {{labels: string[], datasets: Array}}
 */
function buildJoinedForwardSimulationGraphData(
  numYears: number,
  portfolioA: AssetClassPortfolio,
  portfolioB: AssetClassPortfolio,
  isASelected: boolean,
  isBSelected: boolean,
  isBestSelected: boolean,
  isWorstSelected: boolean,
  isExpectedSelected: boolean,
  investedAmount: number,
  scaleFactorContribution: number = 0,
  borderWidth: number = 3
): Chart.ChartData {
  const data: Chart.ChartData = {};
  data.labels = [];
  data.datasets = [];

  for (let yr = 1; yr <= numYears; yr++) {
    data.labels.push(`Year ${yr}`);
  }

  if (portfolioA && portfolioB) {
    const simulatorA = new PortfolioSimulator(portfolioA.calculateRisk(), portfolioA.calculateYield());
    const simulatorB = new PortfolioSimulator(portfolioB.calculateRisk(), portfolioB.calculateYield());


    const referenceDataset: Chart.ChartDataSets = {};
    referenceDataset.label = "Invested amount";
    referenceDataset.fill = false;
    referenceDataset.backgroundColor = "rgba(170, 170, 170, 1.0)";
    referenceDataset.borderColor = "rgba(170, 170, 170, 1.0)";
    referenceDataset.borderWidth = 1;
    referenceDataset.borderDash = [5, 5];
    referenceDataset.pointRadius = 0;
    referenceDataset.data = [] as number[];

    const expectedDatasetA: Chart.ChartDataSets = {};
    expectedDatasetA.label = "Expected result A";
    expectedDatasetA.fill = false;
    expectedDatasetA.backgroundColor = primaryPortfolioColor;
    expectedDatasetA.borderColor = primaryPortfolioColor;
    expectedDatasetA.borderWidth = borderWidth;
    expectedDatasetA.pointRadius = 2;
    expectedDatasetA.data = [] as number[];

    const lowDatasetA: Chart.ChartDataSets = {};
    lowDatasetA.label = "Worst outcome A";
    lowDatasetA.borderColor = isWorstSelected ? primaryPortfolioColor : primaryPortfolioColorTransparent;
    lowDatasetA.borderWidth = borderWidth;
    lowDatasetA.backgroundColor = isWorstSelected ? primaryPortfolioColor : primaryPortfolioColorTransparent;
    lowDatasetA.pointRadius = 2;
    lowDatasetA.fill = false;
    lowDatasetA.data = [] as number[];

    const highDatasetA: Chart.ChartDataSets = {};
    highDatasetA.label = "Best outcome A";
    highDatasetA.borderColor = isBestSelected ? primaryPortfolioColor : primaryPortfolioColorTransparent;
    highDatasetA.borderWidth = borderWidth;
    highDatasetA.backgroundColor = isBestSelected ? primaryPortfolioColor : primaryBackgroundColor;
    highDatasetA.pointRadius = 2;
    highDatasetA.fill = isBestSelected ? false : "-1"; // fill until previous dataset
    highDatasetA.data = [] as number[];

    for (let year = 0; year <= numYears; year++) {
      expectedDatasetA.data.push(simulatorA.getExpectedResult(investedAmount, year));
      lowDatasetA.data.push(simulatorA.getLowResult(investedAmount, year, scaleFactorContribution));
      highDatasetA.data.push(simulatorA.getHighResult(investedAmount, year, scaleFactorContribution));
      referenceDataset.data.push(investedAmount);
    }

    const expectedDatasetB: Chart.ChartDataSets = {};
    expectedDatasetB.label = "Expected result B";
    expectedDatasetB.fill = false;
    expectedDatasetB.backgroundColor = secondaryPortfolioColor;
    expectedDatasetB.borderColor = secondaryPortfolioColor;
    expectedDatasetB.borderWidth = borderWidth;
    expectedDatasetB.pointRadius = 2;
    expectedDatasetB.data = [] as number[];

    const lowDatasetB: Chart.ChartDataSets = {};
    lowDatasetB.label = "Worst outcome B";
    lowDatasetB.borderColor = isWorstSelected ? secondaryPortfolioColor : secondaryPortfolioColorTransparent;
    lowDatasetB.borderWidth = borderWidth;
    lowDatasetB.backgroundColor = isWorstSelected ? secondaryPortfolioColor :  secondaryPortfolioColorTransparent;
    lowDatasetB.pointRadius = 2;
    lowDatasetB.fill = false;
    lowDatasetB.data = [] as number[];

    const highDatasetB: Chart.ChartDataSets = {};
    highDatasetB.label = "Best outcome B";
    highDatasetB.borderColor = isBestSelected ? secondaryPortfolioColor : secondaryPortfolioColorTransparent;
    highDatasetB.borderWidth = borderWidth;
    highDatasetB.backgroundColor = isBestSelected ? secondaryPortfolioColor :  secondaryBackgroundColor;
    highDatasetB.pointRadius = 2;
    highDatasetB.fill = isBestSelected ? false : "-1"; // fill until previous dataset
    highDatasetB.data = [] as number[];

    for (let year = 0; year <= numYears; year++) {
      expectedDatasetB.data.push(simulatorB.getExpectedResult(investedAmount, year));
      lowDatasetB.data.push(simulatorB.getLowResult(investedAmount, year, scaleFactorContribution));
      highDatasetB.data.push(simulatorB.getHighResult(investedAmount, year, scaleFactorContribution));
      referenceDataset.data.push(investedAmount);
    }

    data.datasets!.push(referenceDataset);

    if(isExpectedSelected) {
      if(isASelected) data.datasets!.push(expectedDatasetA);
      if(isBSelected) data.datasets!.push(expectedDatasetB);
    } else if (isWorstSelected) {
      if(isASelected)data.datasets!.push(lowDatasetA);
      if(isBSelected)data.datasets!.push(lowDatasetB);
    } else if(isBestSelected) {
      if(isASelected)data.datasets!.push(highDatasetA);
      if(isBSelected)data.datasets!.push(highDatasetB);
    } else {
      if(isASelected)data.datasets!.push(expectedDatasetA);
      if(isBSelected)data.datasets!.push(expectedDatasetB);
      if(isASelected)data.datasets!.push(lowDatasetA);
      if(isBSelected)data.datasets!.push(lowDatasetB);
      if(isASelected)data.datasets!.push(highDatasetA);
      if(isBSelected)data.datasets!.push(highDatasetB);
    }
  }

  return data;
}

function getJoinedForwardSimulationKeyValues(portfolio: AssetClassPortfolio, investedAmount: number, year: number) {
  const simulator = new PortfolioSimulator(portfolio.calculateRisk(), portfolio.calculateYield());
  return {
    invested: investedAmount,
    expected: simulator.getExpectedResult(investedAmount, year),
    low: simulator.getLowResult(investedAmount, year),
    high: simulator.getHighResult(investedAmount, year),
    year: year
  };
}

function buildJoinedForwardSimChartOptions(
  expectedIndex: number,
  lowIndex: number,
  highIndex: number,
  yAxisMax?: number,
  onHoverExpectedAmountCallback?: (year: number, expectedAmount: number) => void,
  onHoverLowAmountCallback?: (year: number, expectedAmount: number) => void,
  onHoverHighAmountCallback?: (year: number, expectedAmount: number) => void
): Chart.ChartOptions {
  return {
    legend: {
      display: false
    },
    animation: {
      duration: 300
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, tooltipData: any) {
          switch (tooltipItem.datasetIndex) {
            case expectedIndex:
              if (onHoverExpectedAmountCallback) {
                onHoverExpectedAmountCallback(tooltipItem.index, tooltipItem.yLabel);
              }
              break;
            case lowIndex:
              if (onHoverLowAmountCallback) {
                onHoverLowAmountCallback(tooltipItem.index, tooltipItem.yLabel);
              }
              break;
            case highIndex:
              if (onHoverHighAmountCallback) {
                onHoverHighAmountCallback(tooltipItem.index, tooltipItem.yLabel);
              }
              break;
          }
          return `${tooltipData.datasets[tooltipItem.datasetIndex].label}: ${Math.round(tooltipItem.yLabel)}`;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: yAxisMax,
            min: 0
          }
        }
      ]
    }
  };
}

export { buildJoinedForwardSimulationGraphData, getJoinedForwardSimulationKeyValues, buildJoinedForwardSimChartOptions };
