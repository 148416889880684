import React from "react";
import { Button, Container, Grid } from "semantic-ui-react";
import { formatPortfolioPercentage } from "util/misc-utils";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { totalNumberOfPreProgrammedPortfolios } from "business/AssetWeights";
import PortfolioSelector from "components/PortfolioSelector/PortfolioSelector";

import "./DoubleSuggesterPortfolioSelector.css";

interface IDoubleSuggesterPortfolioSelectorProps {
  portfolio: AssetClassPortfolio;
  suggestedIndex?: number;
  activeIndex: number;
  activeComparisonIndex: number;
  suggestedComparisonIndex?: number;
  isCustom: boolean;
  isCustomComparison: boolean;
  comparisonPortfolio: AssetClassPortfolio;
  onChangePortfolioA(i: number): void;
  onChooseCustomPortfolioA(): void;
  onChooseCustomPortfolioB(): void;
  onChangePortfolioB(i: number): void;
}

class DoubleSuggesterPortfolioSelector extends React.Component<IDoubleSuggesterPortfolioSelectorProps> {
  public render(): React.ReactNode {
    return (
      <div className="DoubleSuggesterPortfolioSelector">
        <Container>
          <Grid stackable={true}>
            <Grid.Row verticalAlign="top">
              <Grid.Column width={16}>
                <h3 className="portfolio-name">Portfolio A</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="top">
              <Grid.Column width={11}>
                <PortfolioSelector
                  primary={true}
                  numPortfolios={totalNumberOfPreProgrammedPortfolios}
                  activeIndex={this.props.activeIndex}
                  isCustom={this.props.isCustom}
                  onChangePortfolio={this.props.onChangePortfolioA}
                  onChooseCustomPortfolio={this.props.onChooseCustomPortfolioA}
                />
              </Grid.Column>
              <Grid.Column width={5} textAlign="center">
                <Button
                  className="choose-portfolio-btn carnegie__button"
                  size="medium"
                  fluid={true}
                  onClick={this.props.onChooseCustomPortfolioA}
                >
                  {!this.props.portfolio ? "Create Portfolio" : "Adjust Portfolio"}
                </Button>

                <div className="risk-return-numbers">
                  <div className="figure">
                    <span>{formatPortfolioPercentage(this.props.portfolio, "calculateYield")}</span>
                    <label>Expected return</label>
                  </div>
                  <div className="figure">
                    <span>{formatPortfolioPercentage(this.props.portfolio, "calculateRisk")}</span>
                    <label>Expected risk</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row verticalAlign="top" className="portfolio-b-header-row">
              <Grid.Column width={16}>
                <h3 className="portfolio-name">Portfolio B (Comparison / Current Portfolio)</h3>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row verticalAlign="top">
              <Grid.Column width={11}>
                <PortfolioSelector
                  primary={false}
                  numPortfolios={totalNumberOfPreProgrammedPortfolios}
                  activeIndex={this.props.activeComparisonIndex}
                  isCustom={this.props.isCustomComparison}
                  onChangePortfolio={this.props.onChangePortfolioB}
                  onChooseCustomPortfolio={this.props.onChooseCustomPortfolioB}
                />
              </Grid.Column>
              <Grid.Column width={5} textAlign="center">
                <Button
                  className="choose-portfolio-btn carnegie__button__secondary"
                  size="medium"
                  secondary={true}
                  fluid={true}
                  onClick={this.props.onChooseCustomPortfolioB}
                >
                  {!this.props.portfolio ? "Create Portfolio" : "Adjust Portfolio"}
                </Button>

                <div className="risk-return-numbers">
                  <div className="figure secondary">
                    <span>{formatPortfolioPercentage(this.props.comparisonPortfolio, "calculateYield")}</span>
                    <label>Expected return</label>
                  </div>
                  <div className="figure secondary">
                    <span>{formatPortfolioPercentage(this.props.comparisonPortfolio, "calculateRisk")}</span>
                    <label>Expected risk</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}
export default DoubleSuggesterPortfolioSelector;
