import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const fundsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3)
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {},
    tableCell: {
      fontSize: "10pt",
      color: "#4c596a"
    },
    fund: {
      padding: 6,
      verticalAlign: "center"
    },
    tableHeaderCell: {
      fontSize: "11pt",
      color: "#e8ebef"
    },
    tableWrapper: {
      overflowX: "auto"
    },
    primaryColor: {
      color: "#e8ebef !important"
    },
    blueColor: {
      color: "rgb(22,53,101) !important"
    },
    form: {
      padding: theme.spacing(2)
    },
    borderBot: {
      borderBottom: "1px solid lightGrey"
    },
    dialogActions: {
      padding: "0 25px 10px 25px"
    },
    normalButton: {
      color: "rgb(22,53,101)",
      borderColor: "rgb(22,53,101)"
    },
    successButton: {
      color: "#00b248",
      borderColor: "#00b248"
    },
    errorButton: {
      color: "#f44336",
      borderColor: "#f44336"
    }
  })
);

export const dialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      padding: "0 25px 10px 25px"
    },
    dialogTitle: {
      borderBottom: "1px solid lightGrey"
    },
    noPadTop: {
      paddingTop: 0
    },
    wrapperGrid: {
      paddingTop: 10
    }
  })
);
