import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import { IAssetClassReturn } from "apiclient/responsePayloads/AssetClassReturn";
import { IFundReturn } from "apiclient/responsePayloads/FundReturn";
import { IFund } from "apiclient/responsePayloads/Fund";

/*
 * Action types
 */

export const ASSET_CLASSES_LOADING = 'financedata.ASSET_CLASSES_LOADING';
export const ASSET_CLASSES_LOAD_SUCCESS = 'financedata.ASSET_CLASSES_LOAD_SUCCESS';
export const ASSET_CLASSES_LOAD_FAILED = 'financedata.ASSET_CLASSES_LOAD_FAILED';

export const ASSET_CLASS_RETURNS_LOADING = 'financedata.ASSET_CLASS_RETURNS_LOADING';
export const ASSET_CLASS_RETURNS_LOAD_SUCCESS = 'financedata.ASSET_CLASS_RETURNS_LOAD_SUCCESS';
export const ASSET_CLASS_RETURNS_LOAD_FAILED = 'financedata.ASSET_CLASS_RETURNS_LOAD_FAILED';

export const FUND_RETURNS_LOADING = 'financedata.FUND_RETURNS_LOADING';
export const FUND_RETURNS_LOAD_SUCCESS = 'financedata.FUND_RETURNS_LOAD_SUCCESS';
export const FUND_RETURNS_LOAD_FAILED = 'financedata.FUND_RETURNS_LOAD_FAILED';

export const FUNDS_LOADING = 'financedata.FUNDS_LOADING';
export const FUNDS_LOAD_SUCCESS = 'financedata.FUNDS_LOAD_SUCCESS';
export const FUNDS_LOAD_FAILED = 'financedata.FUNDS_LOAD_FAILED';

interface AssetClassesLoading {
  type: typeof ASSET_CLASSES_LOADING
}

interface AssetClassesLoadSuccess {
  type: typeof ASSET_CLASSES_LOAD_SUCCESS
  assetClasses: AssetClassData[]
}

interface AssetClassesLoadFailed {
  type: typeof ASSET_CLASSES_LOAD_FAILED
  message: string
}

interface AssetClassReturnsLoading {
  type: typeof ASSET_CLASS_RETURNS_LOADING
}

interface AssetClassReturnsLoadSuccess {
  type: typeof ASSET_CLASS_RETURNS_LOAD_SUCCESS
  returns: IAssetClassReturn[]
}

interface AssetClassReturnsLoadFailed {
  type: typeof ASSET_CLASS_RETURNS_LOAD_FAILED
  message: string
}

interface FundReturnsLoading {
  type: typeof FUND_RETURNS_LOADING
}

interface FundReturnsLoadSuccess {
  type: typeof FUND_RETURNS_LOAD_SUCCESS
  returns: IFundReturn[]
}

interface FundReturnsLoadFailed {
  type: typeof FUND_RETURNS_LOAD_FAILED
  message: string
}

interface FundsLoading {
  type: typeof FUNDS_LOADING
}

interface FundsLoadSuccess {
  type: typeof FUNDS_LOAD_SUCCESS
  funds: IFund[]
}

interface FundsLoadFailed {
  type: typeof FUNDS_LOAD_FAILED
  message: string
}

export type FinanceDataAction = AssetClassesLoading | AssetClassesLoadSuccess | AssetClassesLoadFailed |
  AssetClassReturnsLoading | AssetClassReturnsLoadSuccess | AssetClassReturnsLoadFailed |
  FundReturnsLoading | FundReturnsLoadSuccess | FundReturnsLoadFailed |
  FundsLoading | FundsLoadSuccess | FundsLoadFailed;

/*
 * Action creators
 */

// Asset classes

export function assetClassesLoading(): AssetClassesLoading {
  return { type: ASSET_CLASSES_LOADING }
}

export function assetClassesLoadSuccess(assetClasses: AssetClassData[]): AssetClassesLoadSuccess {
  return { type: ASSET_CLASSES_LOAD_SUCCESS, assetClasses }
}

export function assetClassesLoadFailed(message: string): AssetClassesLoadFailed {
  return { type: ASSET_CLASSES_LOAD_FAILED, message }
}

// Asset class returns

export function assetClassReturnsLoading(): AssetClassReturnsLoading {
  return { type: ASSET_CLASS_RETURNS_LOADING }
}

export function assetClassReturnsLoadSuccess(returns: IAssetClassReturn[]): AssetClassReturnsLoadSuccess {
  return { type: ASSET_CLASS_RETURNS_LOAD_SUCCESS, returns }
}

export function assetClassReturnsLoadFailed(message: string): AssetClassReturnsLoadFailed {
  return { type: ASSET_CLASS_RETURNS_LOAD_FAILED, message }
}

// Fund returns

export function fundReturnsLoading(): FundReturnsLoading {
  return { type: FUND_RETURNS_LOADING }
}

export function fundReturnsLoadSuccess(returns: IFundReturn[]): FundReturnsLoadSuccess {
  return { type: FUND_RETURNS_LOAD_SUCCESS, returns }
}

export function fundReturnsLoadFailed(message: string): FundReturnsLoadFailed {
  return { type: FUND_RETURNS_LOAD_FAILED, message }
}

// Funds

export function fundsLoading(): FundsLoading {
  return { type: FUNDS_LOADING }
}

export function fundsLoadSuccess(funds: IFund[]): FundsLoadSuccess {
  return { type: FUNDS_LOAD_SUCCESS, funds }
}

export function fundsLoadFailed(message: string): FundsLoadFailed {
  return { type: FUNDS_LOAD_FAILED, message }
}


/*
 * Thunks
 */

export function fetchAssetClasses() {
  return (dispatch: Dispatch) => {
    dispatch(assetClassesLoading())

    axios.get<AssetClassData[]>(`/api/asset-classes`)
      .then((res) => {
        dispatch(assetClassesLoadSuccess(res.data))
      })
      .catch((error: AxiosError) => {
        console.log('Error fetching asset classes', error);
        dispatch(assetClassesLoadFailed('Error loading financial data'))
      });
  }
}

export function fetchAssetClassReturns() {
  return (dispatch: Dispatch) => {
    dispatch(assetClassReturnsLoading())

    axios.get<IAssetClassReturn[]>(`/api/asset-class-returns`)
      .then((res) => {
        dispatch(assetClassReturnsLoadSuccess(res.data))
      })
      .catch((error: AxiosError) => {
        console.log('Error fetching asset class returns', error);
        dispatch(assetClassReturnsLoadFailed('Error loading financial data'))
      });
  }
}

export function fetchFundReturns() {
  return (dispatch: Dispatch) => {
    dispatch(fundReturnsLoading())

    axios.get<IFundReturn[]>(`/api/fund-returns`)
      .then((res) => {
        dispatch(fundReturnsLoadSuccess(res.data))
      })
      .catch((error: AxiosError) => {
        console.log('Error fetching fund returns', error);
        dispatch(fundReturnsLoadFailed('Error loading financial data'))
      });
  }
}

export function fetchFunds() {
  return (dispatch: Dispatch) => {
    dispatch(fundsLoading())

    axios.get<IFund[]>(`/api/funds`)
      .then((res) => {
        dispatch(fundsLoadSuccess(res.data))
      })
      .catch((error: AxiosError) => {
        console.log('Error fetching funds', error);
        dispatch(fundsLoadFailed('Error loading financial data'))
      });
  }
}
