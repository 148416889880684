import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const usersAdminStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3)
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {},
    tableCell: {
      fontSize: "12pt",
      color: "#4c596a"
    },
    tableHeaderCell: {
      fontSize: "13pt",
      color: "#e8ebef"
    },
    tablePagination: {
      color: "#4c596a"
    },
    tableWrapper: {
      overflowX: "auto"
    },
    createButton: {
      margin: theme.spacing(1)
    },
    primaryColor: {
      color: "#e8ebef !important"
    }
  })
);
