import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Typography } from "@material-ui/core";
import { Container } from "semantic-ui-react";
import { dialogStyles } from "./ConfirmationDialogStyles";

interface DialogProps {
  open: boolean;
  message?: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConfirmationDialog: React.FC<DialogProps> = props => {
  const { open, handleClose, handleConfirm, message } = props;
  const classes = dialogStyles();

  const handleConfirmClick = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="confirmation-dialog-title" aria-describedby="confirmation-dialog-description">
        <DialogTitle className={message && classes.dialogTitle} id="confirmation-dialog-title">
          Are you sure?
        </DialogTitle>
        {message && (
          <DialogContent>
            <Grid container spacing={2} className={classes.wrapperGrid}>
              <Typography variant="subtitle1">{message}</Typography>
            </Grid>
          </DialogContent>
        )}

        <DialogActions className={classes.dialogActions}>
          <Button onClick={() => handleClose()}>Cancel</Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirmClick();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
