// const schema = {
//     id: Joi.string().required(),
//     name: Joi.string().required(),
//     description: Joi.string().allow(''),
//     indexForAssetClass: Joi.string(),
// }

export interface IFund {
    id: string
    name: string
    description: string
    indexForAssetClass?: string
}

export const isFundIndex = (fund: IFund) => isFundIdIndexFund(fund.id)
export const isFundIdIndexFund = (fundId: string) => fundId.startsWith('index:');
