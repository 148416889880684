import * as React from "react";
import { Container, Grid, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { Simulation } from "apiclient/responsePayloads/Simulation";
import { Desktop, MobileOrTablet } from "components/util/responsive";
import SimulationNameRibbon from "./SimulationNameRibbon";
import { SavingState } from "store/simulations/reducers";
import { REACT_APP_FUNDS_ON } from "components/App/isFundsOn";

import "./ProcessNavigator.css";

export enum NavigationStep {
  RiskProfile = 0,
  Simulation = 1,
  Funds = 2,
  Report = 3
}
export enum NavigationStepMode {
  Previous,
  Current,
  Future
}

interface IProcessNavigatorProps {
  simulation: Simulation;
  step: NavigationStep;
  savingState?: SavingState;
  backUrl?: string;
  nextUrl?: string;
  nextDisabled?: boolean;
  onNewSimulation: () => void;
  onNext?: () => void;
  onLogout?(): void;
  withoutSaveNewButton?: boolean;
}

const getStepMode = (step: NavigationStep, currentStep: NavigationStep): NavigationStepMode => {
  if (step === currentStep) {
    return NavigationStepMode.Current;
  } else if (step < currentStep) {
    return NavigationStepMode.Previous;
  } else {
    return NavigationStepMode.Future;
  }
};

const renderSaveNewButtons = (props: IProcessNavigatorProps) => {
  return (
    <>
      <Button className="simulate-btn carnegie__button" size="small" primary={true} onClick={props.onNewSimulation}>
        New
      </Button>
      {!(props.step === NavigationStep.RiskProfile && !props.simulation.isSaved()) && (
        <SimulationNameRibbon simulation={props.simulation} savingState={props.savingState} />
      )}
    </>
  );
};

const getStepClassName = (step: NavigationStep, currentStep: NavigationStep) => {
  if (getStepMode(step, currentStep) === NavigationStepMode.Previous) {
    return "step-previous";
  } else if (getStepMode(step, currentStep) === NavigationStepMode.Future) {
    return "step-future";
  }
  return "step-current";
};

const getStepIcon = (step: NavigationStep, currentStep: NavigationStep) => {
  if (getStepMode(step, currentStep) === NavigationStepMode.Previous) {
    return "check circle";
  }
  return "circle";
};

function ProcessNavigator(props: IProcessNavigatorProps) {
  const renderNextButton = () => (
    <>
      {
        <Button
          className="next-btn carnegie__button"
          content="Next"
          as={Link}
          to={props.nextUrl ? props.nextUrl : "#"}
          disabled={props.nextDisabled || !props.nextUrl}
          onClick={e => props.onNext && props.onNext()}
        />
      }
    </>
  );

  const renderBackButton = () => (
    <>
      {
        <Button
          disabled={!props.backUrl}
          className="prev-btn carnegie__button"
          content="Previous"
          as={Link}
          to={props.backUrl ? props.backUrl : "#"}
        />
      }
    </>
  );

  return (
    <div className={"ProcessNavigator " + (props.simulation.isSaved() ? "saved" : "unsaved")}>
      <Desktop>
        {renderBackButton()}
        <ProcessSteps step={props.step} />
        {renderNextButton()}
        {!props.withoutSaveNewButton && renderSaveNewButtons(props)}
      </Desktop>
      <MobileOrTablet>
        <Grid textAlign="center">
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8} textAlign="left">
              {renderBackButton()}
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {renderNextButton()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </MobileOrTablet>
    </div>
  );
}

interface IProcessStepsProps {
  step: NavigationStep;
}

function ProcessSteps(props: IProcessStepsProps) {
  return (
    <ul className="process-steps">
      {/* eslint-disable */}
      <li className={getStepClassName(NavigationStep.RiskProfile, props.step)}>
        <a href="#">
          <Icon name={getStepIcon(NavigationStep.RiskProfile, props.step)} className="stepicon" />
        </a>
        <Icon name="long arrow alternate right" />
      </li>
      <li className={getStepClassName(NavigationStep.Simulation, props.step)}>
        <a href="#">
          <Icon name={getStepIcon(NavigationStep.Simulation, props.step)} className="stepicon" />
        </a>
        <Icon name="long arrow alternate right" />
      </li>
      {REACT_APP_FUNDS_ON && (
        <li className={getStepClassName(NavigationStep.Funds, props.step)}>
          <a href="#">
            <Icon name={getStepIcon(NavigationStep.Funds, props.step)} className="stepicon" />
          </a>
          <Icon name="long arrow alternate right" />
        </li>
      )}
      <li className={getStepClassName(NavigationStep.Report, props.step)}>
        <a href="#">
          <Icon name={getStepIcon(NavigationStep.Report, props.step)} className="stepicon" />
        </a>
      </li>
      {/* eslint-enable */}
    </ul>
  );
}

interface IProcessNavPageHeaderProps extends IProcessNavigatorProps {
  savingState?: SavingState;
}

export function ProcessNavPageHeader(props: IProcessNavPageHeaderProps) {
  return (
    <div className="ProcessNavPageHeader">
      <Container>
        <div className="container">
          <ProcessNavigator {...props} />
        </div>
      </Container>
    </div>
  );
}

export function ProcessNavPageFooter(props: IProcessNavigatorProps) {
  return (
    <div className="ProcessNavFooter">
      <Container>
        <ProcessNavigator {...props} withoutSaveNewButton={true} />
      </Container>
    </div>
  );
}
