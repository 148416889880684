import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Container, Dropdown, Menu, Message } from "semantic-ui-react";
import axios from "axios";
import Spinner from "util/Spinner";
import AdminIndex from "./AdminIndex";
import Auth from "modules/Auth";
import { UsersAdmin } from "./UsersAdmin/UsersAdmin";
import { UserProfile } from "apiclient/responsePayloads/User";
import { CorrelationMatrix } from "./CorrelationMatrix/CorrelationMatrix";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssetClasses, fetchAssetClassReturns, fetchFundReturns, fetchFunds } from "store/financedata/actions";
import { AppState } from "store/reducers";
import { getUsers, getProfile } from "admin/UserAPI";
import { AssetClassesComponent } from "./Assumptions/AssetClasses/AssetClassesComponent";
import { HistoricReturns } from "./HistoricReturns/HistoricReturns";
import { FundsComponent } from "./Assumptions/Funds/FundsComponent";
import { logo } from "./Logo";

interface IAdminProps {
  backendURL: string;
}

const Admin: React.FC<IAdminProps> = props => {
  const { backendURL } = props;
  const auth = new Auth(localStorage);
  const [isLoggedIn, setIsLoggedIn] = React.useState(auth.isLoggedIn());
  const dispatch = useDispatch();
  const assetClassesStore = useSelector((store: AppState) => store.financeData.assetClasses);
  const [userSource, setUserSource] = React.useState<UserProfile | undefined>(undefined);
  const [usersSource, setUsersSource] = React.useState<UserProfile[] | undefined>(undefined);
  const [isFetchingUsers, setIsFetchingUsers] = React.useState(true);
  const [isFetchingProfile, setIsFetchingProfile] = React.useState(true);
  axios.defaults.headers.common.Authorization = localStorage.getItem("jwtToken");
  axios.defaults.baseURL = backendURL;

  React.useEffect(() => {
    if (isLoggedIn) {
      getUsers()
        .then(res => setUsersSource(res))
        .catch(err => {
          return <Message negative={true} header="Error" content={`An error occurred while loading user data from server.`} />;
        })
        .finally(() => setIsFetchingUsers(false));
      getProfile()
        .then(res => setUserSource(res))
        .catch(err => console.log(err))
        .finally(() => setIsFetchingProfile(false));
      dispatch(fetchAssetClasses());
      dispatch(fetchAssetClassReturns());
      dispatch(fetchFunds());
      dispatch(fetchFundReturns());
    }
  }, [dispatch, isLoggedIn]);

  const handleLogout = () => {
    auth.logout();
    setIsLoggedIn(false);
  };

  const onRefresh = () => {
    getUsers()
      .then(res => setUsersSource(res))
      .catch(err => {
        return <Message negative={true} header="Error" content={`An error occurred while loading user data from server.`} />;
      })
      .finally(() => setIsFetchingUsers(false));
  };

  if (!isLoggedIn) {
    return <Message negative={true} header="Error" content={`You have to be logged in (as administrator) to view this page.`} />;
  } else if (isFetchingUsers || isFetchingProfile || (!assetClassesStore.data && assetClassesStore.loading)) {
    return <Spinner />;
  } else if (userSource && userSource.isAdmin !== true) {
    return <Message negative={true} header="Error" content={`You do not have access to this page.`} />;
  }

  if (usersSource === undefined) return <Spinner />;

  return (
    <div>
      <Menu fixed="top" inverted={true} style={{ backgroundColor: "#163565" }}>
        <Container>
          <Menu.Item as={Link} to="/admin">
            <span style={{ height: "3em", marginRight: "1em" }}>{logo}</span>
            Admin
          </Menu.Item>
          <Dropdown item={true} simple={true} text="Assumptions">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/admin/assetclasses">
                Asset classes
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/admin/funds">
                Funds
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/admin/correlationmatrix">
                Correlation matrix
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item={true} simple={true} text="Historic returns">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/admin/history/assetclasses">
                Asset class history
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/admin/history/funds">
                Fund history
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as={Link} to="/admin/users">
            Users
          </Menu.Item>

          {isLoggedIn && userSource && (
            <Menu.Item as="a" position="right" onClick={handleLogout}>
              Log out ({userSource.email})
            </Menu.Item>
          )}
        </Container>
      </Menu>

      <main className="site-content" style={{ marginTop: "7em", marginBottom: "4em", backgroundColor: "white" }}>
        <Switch>
          <Route exact={true} path="/admin/assetclasses" component={AssetClassesComponent} />
          <Route exact={true} path="/admin/funds" component={FundsComponent} />
          <Route exact={true} path="/admin/history/funds" component={HistoricReturns} />
          <Route exact={true} path="/admin/history/assetclasses" component={HistoricReturns} />
          <Route exact={true} path="/admin/correlationmatrix" component={CorrelationMatrix} />
          <Route exact={true} path="/admin/users" render={props => <UsersAdmin {...props} users={usersSource} onRefresh={onRefresh} />} />
          <Route path="/admin" component={AdminIndex} />
        </Switch>
      </main>
    </div>
  );
};

export default Admin;
