import * as React from "react";
import { useSelector } from "react-redux";
import { IRouterMatchProps } from "util/RouterPropsInterface";
import ReportHeaderSection from "./ReportHeaderSection";
import { NavigationStep, ProcessNavPageHeader } from "components/ProcessNavigator/ProcessNavigator";
import { Simulation, SimulationData } from "apiclient/responsePayloads/Simulation";
import { RouteComponentProps, withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { AssetClassAllocationReportSection } from "./AssetClassAllocationReportSection";
import { FundAllocationReportSection } from "./FundAllocationReportSection";
import { AppState } from "store/reducers";
import { ProfileSliceState } from "store/profile/reducers";
import Spinner from "util/Spinner";
import { useAssetClasses, useAssetClassReturns, useFundReturns, useFunds } from "store/financedata/helpers";
import { getFundsURL, getSimulationURL } from "urls";
import { SavingState } from "store/simulations/reducers";
import { REACT_APP_FUNDS_ON } from "components/App/isFundsOn";
import MainLayout from "components/App/MainLayout";
import { IAppLayoutProps } from "components/App/App";

import "./ReportPage.css";

interface ReportPageProps extends RouteComponentProps<IRouterMatchProps> {
  simulation: Simulation;
  savingState?: SavingState;
  onSimulationChange(simulation: SimulationData): void;
  layoutProps: IAppLayoutProps;
}

export const ReportPage: React.FC<ReportPageProps> = props => {
  const { user } = useSelector<AppState, ProfileSliceState>(state => state.profile);
  const assetClasses = useAssetClasses();
  const assetClassReturns = useAssetClassReturns();
  const fundReturns = useFundReturns();
  const funds = useFunds();

  if (
    user === undefined ||
    assetClasses === undefined ||
    assetClassReturns === undefined ||
    fundReturns === undefined ||
    funds === undefined
  ) {
    return <Spinner />;
  }

  const portfolioA = props.simulation.portfolioA(assetClasses);
  const portfolioB = props.simulation.portfolioB(assetClasses);

  const layoutPropsWithTitle = { ...props.layoutProps, title: "Report" };

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="ReportPage">
        <ProcessNavPageHeader
          onNewSimulation={props.layoutProps.onNewSimulation}
          step={NavigationStep.Report}
          simulation={props.simulation}
          backUrl={REACT_APP_FUNDS_ON ? getFundsURL(props.simulation) : getSimulationURL(props.simulation)}
          onLogout={props.layoutProps.onLogout}
          savingState={props.savingState}
        />

        <SectionWrap toned={true} className="huge-padding-top">
          <Container text={true}>
            <ReportHeaderSection user={user} simulation={props.simulation} />
            <AssetClassAllocationReportSection
              portfolioA={portfolioA}
              portfolioB={portfolioB}
              assetClassReturns={assetClassReturns}
              scaleFactorContribution={props.simulation.scaleFactorContribution}
            />
            {REACT_APP_FUNDS_ON && (
              <FundAllocationReportSection
                simulation={props.simulation}
                assetClasses={assetClasses}
                assetClassReturns={assetClassReturns}
                fundReturns={fundReturns}
                funds={funds}
              />
            )}
          </Container>
        </SectionWrap>
      </div>
    </MainLayout>
  );
};

export default withRouter(ReportPage);
