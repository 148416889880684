import PortfolioSimulator from 'business/PortfolioSimulator';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import { primaryPortfolioColor, secondaryPortfolioColor } from 'util/colors';


function buildChartData(portfolioA: AssetClassPortfolio, portfolioB: AssetClassPortfolio, isASelected: boolean, isBSelected: boolean, scaleFactorContribution: number): Chart.ChartData {
    const yearIntervals = ['1', '3', '5', '10', '20'];
    const initialInvestment = 100;
    const simulatorA = new PortfolioSimulator(portfolioA.calculateRisk(), portfolioA.calculateYield());
    const simulatorB = new PortfolioSimulator(portfolioB.calculateRisk(), portfolioB.calculateYield());

    const expectedProfitsA: number[] = [];
    const expectedProfitsB: number[] = [];
    const worstOutcomesA: number[] = [];
    const worstOutcomesB: number[] = [];

    for (const years of yearIntervals) {
        const y = Number(years)
        const expectedProfitA = simulatorA.getExpectedResult(initialInvestment, y) - initialInvestment;
        const worstOutcomeA = simulatorA.getLowResult(initialInvestment, y, scaleFactorContribution) - initialInvestment;
        expectedProfitsA.push(expectedProfitA);
        worstOutcomesA.push(worstOutcomeA);
        const expectedProfitB = simulatorB.getExpectedResult(initialInvestment, y) - initialInvestment;
        const worstOutcomeB = simulatorB.getLowResult(initialInvestment, y, scaleFactorContribution) - initialInvestment;
        expectedProfitsB.push(expectedProfitB);
        worstOutcomesB.push(worstOutcomeB);
    }

    const expectedDatasetA: Chart.ChartDataSets = {};
    expectedDatasetA.label = 'Expected Profit' + (isBSelected ? ' A' : '');
    expectedDatasetA.backgroundColor = primaryPortfolioColor;
    expectedDatasetA.borderColor = primaryPortfolioColor;
    expectedDatasetA.borderWidth = 1;
    expectedDatasetA.hoverBackgroundColor = primaryPortfolioColor;
    expectedDatasetA.hoverBorderColor = primaryPortfolioColor;
    expectedDatasetA.data = expectedProfitsA;

    const expectedDatasetB: Chart.ChartDataSets = {};
    expectedDatasetB.label = 'Expected Profit' + (isASelected ? ' B' : '');
    expectedDatasetB.backgroundColor = secondaryPortfolioColor;
    expectedDatasetB.borderColor = secondaryPortfolioColor;
    expectedDatasetB.borderWidth = 1;
    expectedDatasetB.hoverBackgroundColor = secondaryPortfolioColor;
    expectedDatasetB.hoverBorderColor = secondaryPortfolioColor;
    expectedDatasetB.data = expectedProfitsB;
    
    const worstDatasetA: Chart.ChartDataSets = {};
    worstDatasetA.label = 'Worst Outcome' + (isBSelected ? ' A' : '');
    worstDatasetA.backgroundColor = '#B6BAC2';
    worstDatasetA.borderColor = '#B6BAC2';
    worstDatasetA.borderWidth = 1;
    worstDatasetA.hoverBackgroundColor = '#B6BAC2';
    worstDatasetA.hoverBorderColor = '#B6BAC2';
    worstDatasetA.data = worstOutcomesA;
    
    const worstDatasetB: Chart.ChartDataSets = {};
    worstDatasetB.label = 'Worst Outcome' + (isASelected ? ' B' : '');
    worstDatasetB.backgroundColor = "#87BCDD";
    worstDatasetB.borderColor = "#87BCDD";
    worstDatasetB.borderWidth = 1;
    worstDatasetB.hoverBackgroundColor = "#87BCDD";
    worstDatasetB.hoverBorderColor = "#87BCDD";
    worstDatasetB.data = worstOutcomesB;


    const data: Chart.ChartData = {}
    data.datasets = [];
    data.labels = yearIntervals;
    if(isASelected) {
        data.datasets!.push(expectedDatasetA);
        data.datasets!.push(worstDatasetA);
    }
    if(isBSelected) {
        data.datasets!.push(expectedDatasetB);
        data.datasets!.push(worstDatasetB);
    }
    return data
}

function buildChartOptions(xLabel: string, yLabel: string, isASelected: boolean, isBestSelected: boolean): Chart.ChartOptions {
    return {
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: any, tooltipData: any) => {
                    return `${tooltipData.datasets[tooltipItem.datasetIndex].label}: ${Math.round(tooltipItem.yLabel)}`;
                }
            }
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: xLabel
                }
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: yLabel
                }
            }],
        },
        responsive: true
    };
}

export {
    buildChartData,
    buildChartOptions
}