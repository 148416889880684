import * as React from "react";
import { Button, Grid, Popup, Table, ButtonGroup } from "semantic-ui-react";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { FundsTableColumnType, FundsTableColumnRowData } from "./FundsTableChartsDataBuilder";
import FundsTableChartsDataBuilder from "./FundsTableChartsDataBuilder";
import { ModalData } from "components/CustomPortfolioModal/CustomPortfolioModal";
import { toString } from "util/misc-utils";
import Funds from "apiclient/Funds";

import "./FundsTableChart.css";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";

const builder = FundsTableChartsDataBuilder();

interface IFundsSubTableProps {
  data: FundsTableColumnRowData;
  onClickAdjustFunds(modalData: ModalData, forAssetClassID: string): void;
}

function FundsSubTable(props: IFundsSubTableProps) {
  function generateRows() {
    const items: JSX.Element[] = [];
    for (const fund of props.data.funds) {
      items.push(
        <Table.Row key={fund.name} className={fund.isIndexFund ? "index-fund" : ""}>
          <Popup
            disabled={fund.infoText === undefined || fund.infoText === ""}
            trigger={<Table.Cell>{fund.name}</Table.Cell>}
            content={fund.infoText}
          />
          <Table.Cell textAlign="right">{toString(fund.weight)}%</Table.Cell>
        </Table.Row>
      );
    }
    return items;
  }

  return (
    <Table celled={true}>
      <Table.Header className="TableHeader">
        <Table.Row>
          <Table.HeaderCell width={12}>{props.data.title}</Table.HeaderCell>
          <Table.HeaderCell width={4} textAlign="right">
            {toString(props.data.sumOfWeights())}%
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{generateRows()}</Table.Body>
      <Table.Footer fullWidth={true}>
        <Table.Row>
          <Table.HeaderCell colSpan="12" textAlign="right">
            <Button
              className="carnegie__button__secondary"
              size="small"
              basic={true}
              onClick={e => props.onClickAdjustFunds(props.data.asModalData(), props.data.assetClassID)}
            >
              Adjust
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

interface IFundsTableColumnProps {
  type: FundsTableColumnType;
  portfolio: AssetClassPortfolio;
  funds: Funds;
  onClickAdjustFunds(modalData: ModalData, forAssetClassID: string): void;
}

function FundsTableColumn(props: IFundsTableColumnProps) {
  function generateTables() {
    const items: JSX.Element[] = [];
    for (const d of builder.createData(props.type, props.portfolio, props.funds)) {
      items.push(
        <FundsSubTable
          key={d.title}
          data={d}
          onClickAdjustFunds={(data: ModalData, forAssetClassID: string) => props.onClickAdjustFunds(data, forAssetClassID)}
        />
      );
    }
    return items;
  }

  return (
    <Grid.Column>
      <h2>{props.type}</h2>
      {generateTables()}
    </Grid.Column>
  );
}

interface IFundsTableProps {
  portfolio: AssetClassPortfolio;
  funds: Funds;
  assetClasses: AssetClassData[] | undefined;
  onSwitch: (portfolio: AssetClassPortfolio, splitAmong: "Indexes" | "Funds") => void;
  onClickAdjustFunds(modalData: ModalData, forAssetClassID: string): void;
}

function calculateFundAllocationMode(
  portfolio: AssetClassPortfolio,
  assetClasses: AssetClassData[] | undefined
): "Indexes" | "Funds" | "Custom" {
  let checkResult: { [k: string]: { u: number; i: number; f: number } } = {};
  let scanResult: { indexes: number; funds: number; custom: number } = { indexes: 0, funds: 0, custom: 0 };

  if (assetClasses) {
    assetClasses.forEach(ac => {
      const totalWeight = portfolio.weights.get(ac.id);
      const amountOfFunds = ac.funds.length - 1;
      let customAC = 0;
      let indexSplittedAc = 0;
      let fundSplittedAC = 0;
      if (totalWeight !== 0) {
        //check if ac is an index splitted
        ac.funds.forEach(fundName => {
          const acAllocation = portfolio.getFundAllocationForAssetClass(ac, totalWeight);
          const fundValue = acAllocation.funds[fundName];
          //const fundValue = Object.entries(fundsAllocations.funds).find(fund => fund[0] === fundName)![1];
          if (fundName.startsWith("index")) {
            if (fundValue === totalWeight) {
              //if total wieigh is all in index fund
              indexSplittedAc++;
            } else {
              if (fundValue !== 0) customAC++;
            }
          } else {
            if (fundValue === 0) customAC++;
            else {
              if (fundValue === totalWeight / amountOfFunds) {
                fundSplittedAC++;
              } else customAC++;
            }
          }
        });
        checkResult[ac.id] = { u: customAC, i: indexSplittedAc, f: fundSplittedAC };
        if (fundSplittedAC === 0 && indexSplittedAc !== 0) scanResult.indexes++;
        else if (indexSplittedAc === 0 && fundSplittedAC !== 0) scanResult.funds++;
        else scanResult.custom++;
      }
    });
  }

  if (scanResult.indexes !== 0) {
    if (scanResult.funds === 0 && scanResult.custom === 0) return "Indexes";
    else return "Custom";
  } else if (scanResult.funds !== 0) {
    if (scanResult.indexes === 0 && scanResult.custom === 0) return "Funds";
    else return "Custom";
  } else {
    return "Custom";
  }
}

export const FundsTable: React.FC<IFundsTableProps> = props => {
  const { assetClasses, portfolio, onSwitch, onClickAdjustFunds, funds } = props;
  const [activeButton, setActiveButton] = React.useState<"Indexes" | "Funds" | "Custom" | undefined>(undefined);

  React.useEffect(() => {
    let checkResult = calculateFundAllocationMode(portfolio, assetClasses);
    if (checkResult === "Indexes") setActiveButton("Indexes");
    else if (checkResult === "Funds") setActiveButton("Funds");
    else setActiveButton("Custom");
  }, [portfolio, assetClasses]);

  return (
    <div>
      <Grid stackable={true} columns={3} className="FundsTable">
        <Grid.Row columns={1}>
          <Grid.Column>
            <ButtonGroup className="button-group">
              <Button
                onClick={() => onSwitch(portfolio, "Funds")}
                disabled={!activeButton}
                className={activeButton !== "Funds" ? "carnegie__button__secondary" : "carnegie__button"}
                primary={activeButton === "Funds"}
              >
                Funds
              </Button>
              <Button
                loading={!activeButton}
                className={activeButton !== "Custom" ? "carnegie__button__secondary" : "carnegie__button"}
                disabled
                primary={activeButton === "Custom"}
              >
                Custom
              </Button>
              <Button
                onClick={() => onSwitch(portfolio, "Indexes")}
                disabled={!activeButton}
                primary={activeButton === "Indexes"}
                className={activeButton !== "Indexes" ? "carnegie__button__secondary" : "carnegie__button"}
              >
                Indexes
              </Button>
            </ButtonGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <FundsTableColumn
            type={FundsTableColumnType.FixedIncome}
            portfolio={portfolio}
            funds={funds}
            onClickAdjustFunds={(data: ModalData, forAssetClassID: string) => {
              onClickAdjustFunds(data, forAssetClassID);
            }}
          />
          <FundsTableColumn
            type={FundsTableColumnType.Equities}
            portfolio={portfolio}
            funds={funds}
            onClickAdjustFunds={(data: ModalData, forAssetClassID: string) => {
              onClickAdjustFunds(data, forAssetClassID);
            }}
          />
          <FundsTableColumn
            type={FundsTableColumnType.Alternatives}
            portfolio={portfolio}
            funds={funds}
            onClickAdjustFunds={(data: ModalData, forAssetClassID: string) => {
              onClickAdjustFunds(data, forAssetClassID);
            }}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};
