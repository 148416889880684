import React from "react";
import { Button, Popup } from "semantic-ui-react";

import "./HelpPopupIcon.css";

export const HelpPopupIcon = ({ children, style }) => (
    <div style={style} className="HelpPopupIcon">
        <Popup hoverable trigger={<Button className="HelpPopupIconButton" style={{ color: "#fff" }} icon="help"></Button>}>
            {children}
        </Popup>
    </div>
);
