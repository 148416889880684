import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const dialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      borderBottom: "1px solid lightGrey",
      backgroundColor: "#163665",
      color: "white"
    },
    noPadTop: {
      paddingTop: 0
    },
    dialogActions: {
      padding: "0 25px 10px 25px"
    },
    wrapperGrid: {
      padding: "10px 0 25px 0"
    },
    copied: {
      color: "green",
      float: "right"
    }
  })
);
