// A Redux-connected smart component wrapping app until App can be rewritten
// using React Hooks and Redux.

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';

import { IRouterMatchProps } from 'util/RouterPropsInterface';
import { profileLoad } from 'store/profile/actions';
import { fetchSimulations, simulationsSetUnsaved, updateSavedSimulation } from 'store/simulations/actions';
import Auth from 'modules/Auth';
import { fetchAssetClasses, fetchAssetClassReturns, fetchFundReturns, fetchFunds } from 'store/financedata/actions';
import { AppState } from 'store/reducers';
import { AssetClassesSlice, AssetClassReturnsSlice, FundReturnsSlice, FundsSlice } from 'store/financedata/reducers';
import { Message } from 'semantic-ui-react';
import Spinner from 'util/Spinner';
import { App } from './App';
import { SimulationData } from 'apiclient/responsePayloads/Simulation';
import { SimulationsSliceState } from 'store/simulations/reducers';

interface AppReduxProps extends RouteComponentProps<IRouterMatchProps> {
}

function setupAxios() {
  axios.defaults.headers.common.Authorization = localStorage.getItem("jwtToken");
}

export const AppRedux: React.FC<AppReduxProps> = (props) => {
  const dispatch = useDispatch()
  const assetClassesState = useSelector<AppState, AssetClassesSlice>(state => state.financeData.assetClasses);
  const assetClassReturnsState = useSelector<AppState, AssetClassReturnsSlice>(state => state.financeData.assetClassReturns);
  const fundReturnsState = useSelector<AppState, FundReturnsSlice>(state => state.financeData.fundReturns);
  const fundsState = useSelector<AppState, FundsSlice>(state => state.financeData.funds);
  const unsavedSimulation = useSelector<AppState, SimulationData>(state => state.simulations.unsavedSimulation);
  const simulationsState = useSelector<AppState, SimulationsSliceState>(state => state.simulations);
  const auth = new Auth(localStorage);
  const userID = auth.getUserID();

  const refreshSimulations = () => {
    if (userID !== undefined) {
      dispatch(fetchSimulations(userID));
    }
  }

  useEffect(() => {
    setupAxios();
    dispatch(fetchAssetClasses());
    dispatch(fetchAssetClassReturns());
    dispatch(fetchFundReturns());
    dispatch(fetchFunds());
    dispatch(profileLoad());
    if (userID !== undefined) {
      dispatch(fetchSimulations(userID));
    }
  }, [dispatch, userID]);

  // Load financial data
  if (assetClassesState.errorMessage !== undefined) {
    return (<Message negative={true} header='Error' content={`Could not load financial data from server: ${assetClassesState.errorMessage}`} />)
  }
  if (assetClassReturnsState.errorMessage !== undefined) {
    return (<Message negative={true} header='Error' content={`Could not load financial data from server: ${assetClassReturnsState.errorMessage}`} />)
  }
  if (fundReturnsState.errorMessage !== undefined) {
    return (<Message negative={true} header='Error' content={`Could not load financial data from server: ${assetClassReturnsState.errorMessage}`} />)
  }
  if (fundsState.errorMessage !== undefined) {
    return (<Message negative={true} header='Error' content={`Could not load financial data from server: ${assetClassReturnsState.errorMessage}`} />)
  }
  if (assetClassesState.data === undefined || assetClassReturnsState === undefined || fundReturnsState === undefined || fundsState === undefined) {
    return <Spinner />
  }

  const onChangeUnsavedSimulation = (simulation: SimulationData) => {
    dispatch(simulationsSetUnsaved(simulation))
  }

  const updateSimulation = (simulation: SimulationData) => {
    dispatch(updateSavedSimulation(simulation));
  }

  return (
    <App
      history={props.history}
      match={props.match}
      location={props.location}
      onUnsavedSimulationChange={onChangeUnsavedSimulation}
      unsavedSimulation={unsavedSimulation}
      userSimulations={simulationsState.userSimulations}
      refreshSimulations={refreshSimulations}
      updateSimulation={updateSimulation}
      savingState={simulationsState.savingState}
    />
  );
}
