import { SimulationData, newSimulation } from "apiclient/responsePayloads/Simulation";
import { Reducer } from "redux";
import { AppAction } from "store/actions";
import { SIMULATIONS_FETCH_START, SIMULATIONS_FETCH_SUCCESS, SIMULATIONS_FETCH_FAIL, SIMULATIONS_SET_UNSAVED, SIMULATIONS_SET_SAVED, SIMULATIONS_SET_SAVING_STATE } from "./actions";

export enum SavingState { Saving, Failed, Saved }

export interface SimulationsSliceState {
  userSimulations?: SimulationData[]
  isFetchingSimulations: boolean
  fetchErrorMessage?: string
  unsavedSimulation: SimulationData
  savingState?: SavingState
}

const initialState: SimulationsSliceState = {
  isFetchingSimulations: false,
  unsavedSimulation: newSimulation()
}

export const reduceSimulations: Reducer<SimulationsSliceState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case SIMULATIONS_FETCH_START:
      return { ...state, isFetchingSimulations: true }
    case SIMULATIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSimulations: false,
        userSimulations: action.simulations,
        fetchErrorMessage: undefined
      }
    case SIMULATIONS_FETCH_FAIL:
      return {
        ...state,
        isFetchingSimulations: false,
        fetchErrorMessage: action.message
      }
    case SIMULATIONS_SET_UNSAVED:
      return {
        ...state,
        unsavedSimulation: action.simulation
      }
    case SIMULATIONS_SET_SAVING_STATE:
      return {
        ...state,
        savingState: action.savingState
      }
    case SIMULATIONS_SET_SAVED:
      return {
        ...state,
        userSimulations: (state.userSimulations === undefined ? undefined :
          state.userSimulations.map(sim => {
            if (sim.id === action.simulation.id) {
              return { ...action.simulation }
            }
            return sim
          }))
      }
    default:
      return state
  }
}
