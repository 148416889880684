import * as React from 'react';
import { Button, Label } from 'semantic-ui-react';

import { Simulation } from 'apiclient/responsePayloads/Simulation';
import Auth from 'modules/Auth';
import SaveSimulationModal from './SaveSimulationModal';
import { SavingState } from 'store/simulations/reducers';

import './SimulationNameRibbon.css';

interface ISimulationNameRibbonProps {
  simulation: Simulation;
  savingState?: SavingState;
}

export default function SimulationNameRibbon(props: ISimulationNameRibbonProps) {
  const [savePortfolioModalVisible, setSavePortfolioModalVisible] = React.useState(false);
  const isLoggedIn = new Auth(localStorage).isLoggedIn();

  return (
    <div className="SimulationNameRibbon">
      <div>
        {props.simulation.isSaved() && (
          <div>
            <span className="name">{props.simulation.name}</span>

            <Label size="mini" className="saving-status">
              {(props.savingState === SavingState.Saved || props.savingState === undefined) && 'Saved'}
              {props.savingState === SavingState.Saving && 'Auto-saving...'}
              {props.savingState === SavingState.Failed && 'Failed to save'}
            </Label>
          </div>
        )}

        {!props.simulation.isSaved() && (
          <span>
            <span className="unsaved">Unsaved simulation</span>
            <Button
              onClick={(e) => setSavePortfolioModalVisible(true)}
              size="small"
              content="Save"
              primary={true}
              className="save-btn carnegie__button"
            />

            {isLoggedIn && (
              <SaveSimulationModal
                visible={savePortfolioModalVisible}
                onClose={() => {
                  setSavePortfolioModalVisible(false);
                }}
                simulation={props.simulation}
              />
            )}
          </span>
        )}
      </div>
    </div>
  );
}
