import React from 'react';
import { Button } from 'semantic-ui-react';
import { DeleteForever } from '@material-ui/icons';

interface Props {
  onDelete: () => void;
  loading: boolean;
}

const DeleteButton: React.FC<Props> = (props) => {
  const { onDelete, loading } = props;
  const [wantToDelete, setWantToDelete] = React.useState(false);

  const handleDelete = () => {
    if (!wantToDelete) {
      setWantToDelete(true);
    } else {
      onDelete();
    }
  };

  return (
    <Button
      negative={wantToDelete}
      className={!wantToDelete ? 'secondary-btn carnegie__button__secondary' : 'negative-btn carnegie__button__secondary'}
      size="mini"
      onClick={handleDelete}
      loading={loading}
    >
      {wantToDelete ? 'Sure?' : <DeleteForever />}
    </Button>
  );
};

export default DeleteButton;
