import axios, { AxiosError } from "axios";
import { IAssetClassReturn } from "../apiclient/responsePayloads/AssetClassReturn";
import { IFundReturn } from "apiclient/responsePayloads/FundReturn";

export const updateAssetClassReturn = (acReturn: IAssetClassReturn) => {
  return axios.put("/api/asset-class-returns/" + acReturn.date, acReturn).catch((err: AxiosError) => {
    return err;
  });
};

export const updateFundReturn = (fundReturn: IFundReturn) => {
  return axios.put("/api/fund-returns/" + fundReturn.date, fundReturn).catch((err: AxiosError) => {
    return err;
  });
};

export const deleteAssetClassReturn = (date: string) => {
  return axios.delete("/api/asset-class-returns/" + date).catch((err: AxiosError) => {
    return err;
  });
};

export const deleteFundReturn = (date: string) => {
  return axios.delete("/api/fund-returns/" + date).catch((err: AxiosError) => {
    return err;
  });
};
