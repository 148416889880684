import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const correlationMatrixStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: "16px auto",
      overflowX: "scroll",
      overflowY: "hidden"
    },
    table: {},
    textField: {
      width: 175
    },
    footer: {
      margin: theme.spacing(2),
      marginTop: 0
    },
    tableHeaderCell: {
      backgroundColor: "rgb(22,53,101)",
      color: "#e8ebef",
      textAlign: "center",
      height: "51px !important",
      paddingRight: 16
    },
    tableCell: {
      color: "#4c596a",
      paddingRight: 16
    },
    disabledValue: {
      color: "grey",
      fontSize: 14
    },
    saveButton: {
      color: "rgb(22,53,101)",
      borderColor: "rgb(22,53,101)",
      verticalAlign: "middle",
      lineHeight: "5px"
    },
    justSaved: {
      color: "#00b248",
      borderColor: "#00b248"
    },
    errorBox: {
      flex: 1,
      width: "100%",
      display: "flex",
      marginLeft: 10,
      flexDirection: "column",
      alignItems: "center",
      padding: "2px 10px 2px 10px",
      borderWidth: 1,
      borderRadius: 20,
      borderColor: "#ffcdd2",
      borderStyle: "solid",
      backgroundColor: "#ffebee",
      color: "black",
      outline: "none",
      transition: "border .24s ease-in-out"
    }
  })
);
