import React from "react";
import { IRouterProps } from "util/RouterPropsInterface";
import { Container, Message } from "semantic-ui-react";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { changePassword } from "apiclient/ResetPasswordAPI";
import PageHeader from "components/PageHeader/PageHeader";
import { ResetPasswordForm } from "./ResetPasswordForm";
import "./ResetPasswordPage.css";
import { RouteComponentProps } from "react-router-dom";

interface UrlParams {
  token: string;
}

interface MyProps extends RouteComponentProps<UrlParams> {
  loginUrl: string;
}

export const ResetPasswordPage: React.FC<MyProps & IRouterProps> = props => {
  const [view, setView] = React.useState<"success" | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const { match, loginUrl } = props;
  const token = match.params.token;

  const handleSubmit = (password: string) => {
    changePassword(password, token)
      .then(res => setView("success"))
      .catch(() => setErrorMessage("Error"));
  };

  return (
    <main>
      <div className="ResetPasswordPage">
        <PageHeader text="Reset password" />
        <SectionWrap toned={false}>
          {view !== "success" ? (
            <Container text={true}>
              <div className="reset-password-wrap">
                <h1>Enter new password</h1>
                <ResetPasswordForm onClick={handleSubmit} />
                {errorMessage && <Message warning={true}>{errorMessage}</Message>}
              </div>
            </Container>
          ) : (
            <Container text={true}>
              <div className="reset-password-wrap">
                <h1>Password changed!</h1>
                <p>
                  Your password has been successfully changed. You can now login (<a href={loginUrl}>here</a>).
                </p>
              </div>
            </Container>
          )}
        </SectionWrap>
      </div>
    </main>
  );
};
