import { WorkSheet, CellObject, utils, write, read } from "xlsx";
import { saveAs } from "file-saver";
import { IAssetClassReturn } from "apiclient/responsePayloads/AssetClassReturn";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { IFund } from "apiclient/responsePayloads/Fund";
import _ from "lodash";
import moment from "moment";
import { IFundReturn } from "apiclient/responsePayloads/FundReturn";
import IReturns from "apiclient/responsePayloads/Returns";
import { AxiosResponse, AxiosError } from "axios";

interface IXLParsedToJson {
  title: string;
  headers: string[];
  idList: string[];
  data: IAssetClassReturn[] | IFundReturn[];
}

type DiffStatus = "NOT_CHANGED" | "CHANGED" | "ADDED" | "REMOVED";
type RowDiffStatus = "NOT_CHANGED" | "CHANGED";
export type ParseType = "Asset Class" | "Fund";

export interface RowChangesType {
  status: RowDiffStatus;
  property: string;
}

export interface DiffDataType {
  status: DiffStatus;
  row: IAssetClassReturn | IFundReturn;
  rowChanges?: RowChangesType[];
}

export interface ParsingReturnType {
  result: IFundReturn[] | IAssetClassReturn[];
  idList: string[];
}

const convertWBToBuffer = (s: any) => {
  let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  let view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

const checkIfDataMatchExistingOne = (existingData: AssetClassData[] | IFund[], incomingDataIdList: string[]) => {
  let existingDataIdList: string[] = [];
  existingData.forEach((ac: { id: string }) => {
    if (!ac.id.startsWith("index:")) existingDataIdList.push(ac.id);
  });
  return _.isEqual(existingDataIdList.sort(), incomingDataIdList.sort());
};

export const createXLFromData = (
  data: IAssetClassReturn[] | IFundReturn[],
  columnSource: AssetClassData[] | IFund[],
  title: "Asset Class Return History" | "Fund Return History"
) => {
  const wb = utils.book_new();
  wb.SheetNames.push(title);

  //Create empty worksheet and range for it
  let ws: WorkSheet = {};
  let range = { s: { c: 0, r: 0 }, e: { c: 0, r: 3 } };

  //Manually entering some data in document
  ws["A1"] = { t: "s", v: title };
  ws["A3"] = { t: "s", v: "Date" };
  ws["A4"] = { t: "s", v: "date" };
  let amountOfColumns = 0;
  amountOfColumns = columnSource.length;

  //loops though asset classes and create columns
  for (let i = 1; i !== amountOfColumns + 1; i++) {
    if (range.e.c < i) range.e.c = i;
    let name_cell_ref = utils.encode_cell({ c: i, r: 2 });
    let id_cell_ref = utils.encode_cell({ c: i, r: 3 });

    ws[name_cell_ref] = { t: "s", v: columnSource[i - 1].name };
    ws[id_cell_ref] = { t: "s", v: columnSource[i - 1].id };
  }

  //loop through data and put in respective cells
  for (let R = 4; R !== data.length + 4; R++) {
    if (range.e.r < R) range.e.r = R;
    for (let C = 0; C !== amountOfColumns + 1; C++) {
      if (range.e.c < C) range.e.c = C;

      //adds date to first column
      if (C === 0) {
        let cell: CellObject = { t: "s", v: data[R - 4].date };
        if (cell.v === null) continue;

        let cell_ref = utils.encode_cell({ c: C, r: R });

        ws[cell_ref] = cell;
      } else {
        //else adds value to respective column
        //R-4 is beacuse we offset reading by 4 rows
        //C-1 is because we put date in first column
        let assetId = columnSource[C - 1].id;
        let cell: CellObject = { t: "n", v: data[R - 4].returns[assetId] };
        if (cell.v === null) continue;

        let cell_ref = utils.encode_cell({ c: C, r: R });

        ws[cell_ref] = cell;
      }
    }
  }

  ws["!ref"] = utils.encode_range(range);
  wb.Sheets[title] = ws;

  const content = write(wb, { bookType: "xlsx", type: "binary" });
  saveAs(new Blob([convertWBToBuffer(content)], { type: "application/octet-stream" }), title + ".xlsx");
};

const ExcelDateToJSDate = (excelTimestamp: number): string => {
  return moment(new Date(Math.round((excelTimestamp - 25569) * 86400 * 1000))).format("YYYY-MM-DD");
};

export const parseXLToData = (
  incomingDataBuffer: ArrayBuffer,
  existingData: AssetClassData[] | IFund[],
  parseAs: ParseType
): Promise<ParsingReturnType> => {
  return new Promise(function (resolve, reject) {
    const workbook = read(incomingDataBuffer, { type: "array" });
    let sheetName = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[sheetName];
    let headers: { [key in string]: any } = {};
    let checkedDates: string[] = [];
    let jsonData: IXLParsedToJson = { title: "", headers: [], idList: [], data: [] };
    let tempData: IAssetClassReturn | IFundReturn | undefined = undefined;

    try {
      Object.keys(worksheet).forEach((z, i, arr) => {
        if (z[0] === "!") return;

        //check title
        if (z === "A1") {
          if (typeof worksheet[z].v === "string") {
            if (parseAs === "Asset Class") {
              if (worksheet[z].v !== "Asset Class Return History") {
                throw new Error("Title in this file is incorrect");
              } else {
                jsonData["title"] = worksheet[z].v;
              }
            } else if (parseAs === "Fund") {
              if (worksheet[z].v !== "Fund Return History") {
                throw new Error("Title in this file is incorrect");
              } else {
                jsonData["title"] = worksheet[z].v;
              }
            }
          }
          return;
        }

        //check date column
        if (z === "A3") {
          if (worksheet[z].v !== "Date") {
            throw new Error('Column A3 must be "Date"');
          }
        } else if (z === "A4") {
          if (worksheet[z].v !== "date") {
            throw new Error('Column A4 must be "date"');
          }
        }

        let tt = 0;
        for (let i = 0; i < z.length; i++) {
          if (!isNaN(parseInt(z[i]))) {
            tt = i;
            break;
          }
        }

        let col = z.substring(0, tt);
        let row = parseInt(z.substring(tt));
        let value: string | number = worksheet[z].v;

        //store header names
        if (row === 3 && value) {
          if (typeof value === "string") jsonData["headers"].push(value);
          return;
        } else if (row === 4 && value) {
          headers[col] = value;
          if (typeof value === "string") jsonData["idList"].push(value);
          return;
        }

        //check date format
        if (col === "A") {
          if (!tempData) {
            tempData = { date: "", returns: {} };
          } else {
            jsonData.data.push(tempData);
            tempData = { date: "", returns: {} };
          }

          if (typeof value === "string") {
            if (moment(value, "YYYY-MM-DD", true).isValid()) {
              if (checkedDates.find(d => d === value)) {
                throw new Error("Date in cell " + col + row + " is a dublicate");
              } else {
                checkedDates.push(value);
                tempData.date = value;
              }
            } else {
              throw new Error("Date in cell " + col + row + " is invalid");
            }
          } else if (typeof value === "number") {
            const dateString = ExcelDateToJSDate(value);
            if (moment(dateString, "YYYY-MM-DD", true).isValid()) {
              if (checkedDates.find(d => d === dateString)) {
                throw new Error("Date in cell " + col + row + " is a dublicate");
              } else {
                checkedDates.push(dateString);
                tempData.date = dateString;
              }
            } else {
              throw new Error("Date in cell " + col + row + " is in wrong format");
            }
          }
        } else if (col !== "A" && typeof value === "number") {
          //check for out ouf bounds values
          if (headers[col] === undefined) {
            throw new Error("Some of values in this file are out of bounds");
          }
          if (tempData) tempData.returns[headers[col]] = value;
        } else throw new Error("Value in cell " + col + row + " is in wrong format");

        //push last row when predicting end of loop
        if ((tempData && arr[i + 1] === "!margins") || (arr[i + 1] === undefined && tempData)) {
          jsonData.data.push(tempData);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        reject(e.message);
      } else {
        reject("Something went wrong");
      }
    }
    jsonData.idList.shift();

    //check if data is OK
    let dataIsOK = false;
    dataIsOK = checkIfDataMatchExistingOne(existingData, jsonData.idList);
    if (dataIsOK) resolve({ result: jsonData.data, idList: jsonData.idList });
    else reject("Data in this file is invalid");
  });
};

export const shortenText = (str: string, length?: number) => {
  if (!length) {
    length = 100;
  }
  if (str.length > length) {
    return str.substring(0, length - 3) + "...";
  } else {
    return str;
  }
};

/* Diff algorithm */

export const checkDiffInXL = (
  incomingReturns: IAssetClassReturn[] | IFundReturn[],
  existingReturns: IAssetClassReturn[] | IFundReturn[],
  idList: string[]
): Promise<DiffDataType[]> => {
  return new Promise(function (resolve, reject) {
    let result: DiffDataType[] = [];
    let nxtIncomingIndex = 0;
    let nxtExistingIndex = 0;
    let resultIndex = 0;

    incomingReturns.sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    existingReturns.sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    while (
      nxtIncomingIndex <= incomingReturns.length &&
      nxtExistingIndex <= existingReturns.length &&
      resultIndex <= incomingReturns.length + existingReturns.length + 10
    ) {
      if (incomingReturns[nxtIncomingIndex] && existingReturns[nxtExistingIndex]) {
        const incDate = new Date(incomingReturns[nxtIncomingIndex].date).toJSON().slice(0, 10);
        const exstDate = new Date(existingReturns[nxtExistingIndex].date).toJSON().slice(0, 10);
        let rowChanges: RowChangesType[] = [];

        if (exstDate === incDate) {
          rowChanges = checkObjectDiff(existingReturns[nxtExistingIndex].returns, incomingReturns[nxtIncomingIndex].returns, idList);
          if (rowChanges.find(r => r.status === "CHANGED")) {
            result.push({ status: "CHANGED", row: incomingReturns[nxtIncomingIndex], rowChanges });
          } else {
            result.push({ status: "NOT_CHANGED", row: incomingReturns[nxtIncomingIndex] });
          }
          nxtIncomingIndex++;
          nxtExistingIndex++;
        } else {
          if (exstDate < incDate) {
            result.push({ status: "REMOVED", row: existingReturns[nxtExistingIndex] });
            nxtExistingIndex++;
          } else if (exstDate > incDate) {
            result.push({ status: "ADDED", row: incomingReturns[nxtIncomingIndex] });
            nxtIncomingIndex++;
          }
        }
      } else {
        if (!existingReturns[nxtExistingIndex] && incomingReturns[nxtIncomingIndex]) {
          result.push({ status: "ADDED", row: incomingReturns[nxtIncomingIndex] });
          nxtIncomingIndex++;
        } else if (!incomingReturns[nxtIncomingIndex] && existingReturns[nxtExistingIndex]) {
          result.push({ status: "REMOVED", row: existingReturns[nxtExistingIndex] });
          nxtExistingIndex++;
        }
      }

      resultIndex++;
    }
    if (resultIndex === incomingReturns.length + existingReturns.length + 10) reject("Diff algorithm check exceeded limit, stuck in loop");
    nxtIncomingIndex = 0;
    nxtExistingIndex = 0;
    resultIndex = 0;

    resolve(result);
  });
};

export const requestProgress = (proms: Promise<AxiosResponse<any> | AxiosError>[], progress_cb: (value: number) => void) => {
  for (const p of proms) {
    p.then(() => {
      progress_cb(parseInt(((proms.indexOf(p) * 100) / proms.length).toFixed(0)));
    });
  }
  return Promise.all(proms);
};

const checkObjectDiff = function (existingReturns: IReturns, incomingReturns: IReturns, idList: string[]) {
  let diffs: RowChangesType[] = [];
  for (let i = 0; i < idList.length; i++) {
    if (existingReturns[idList[i]] === incomingReturns[idList[i]]) {
      diffs.push({ status: "NOT_CHANGED", property: idList[i] });
    } else {
      diffs.push({ status: "CHANGED", property: idList[i] });
    }
  }
  return diffs;
};
