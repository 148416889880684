import React, { useState } from 'react';
import { Form, Button, Container, Grid } from 'semantic-ui-react';

import './RiskProfileButtons.css';

interface IRiskProfileButtonsProps {
  riskLevel?: number;
  onChange: (newLevel: number) => void;
}

export default function RiskProfileButtons(props: IRiskProfileButtonsProps) {
  const [riskLevel, setRiskLevel] = useState<number | undefined>(props.riskLevel ? props.riskLevel : undefined);

  function handleRiskPreference(newValue: number) {
    setRiskLevel(newValue);
    props.onChange(newValue);
  }

  return (
    <div className="RiskProfileButtons">
      <Container className="button-row">
        <Form>
          <Grid className="buttons-grid">
            <Grid.Row columns={5}>
              <Grid.Column>
                <Button active={riskLevel === 1} onClick={(e) => handleRiskPreference(1)} fluid={true}>
                  1
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button active={riskLevel === 2} onClick={(e) => handleRiskPreference(2)} fluid={true}>
                  2
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button active={riskLevel === 3} onClick={(e) => handleRiskPreference(3)} fluid={true}>
                  3
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button active={riskLevel === 4} onClick={(e) => handleRiskPreference(4)} fluid={true}>
                  4
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button active={riskLevel === 5} onClick={(e) => handleRiskPreference(5)} fluid={true}>
                  5
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="labels-grid">
            <Grid.Row columns={2}>
              <Grid.Column>
                <label className="horizonlabel">Less risk</label>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <label className="horizonlabel">More risk</label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="risk-gradient" />
        </Form>
      </Container>
    </div>
  );
}
