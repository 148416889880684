import { getPortfolioName, roundTwoDecimals } from "util/misc-utils";
import { primaryPortfolioColor, secondaryPortfolioColor } from "util/colors";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { generateLabelsFillWithBorder } from "util/chart-utils";

function buildDataset(portfolio: AssetClassPortfolio, color: any, label: any): Chart.ChartDataSets {
    return {
        backgroundColor: color,
        borderWidth: 0,
        data: [
            {
                x: 100.0 * portfolio.calculateRisk(),
                y: 100.0 * portfolio.calculateYield(),
            },
        ],
        fill: false,
        label,
        pointBackgroundColor: color,
        pointBorderColor: color,
        borderColor: color,
        pointBorderWidth: 3,
        pointHitRadius: 14,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 3,
        pointHoverRadius: 14,
        pointRadius: 10,
    };
}

function buildRiskReturnScatterplotData(portfolio: AssetClassPortfolio, comparisonPortfolio?: AssetClassPortfolio): Chart.ChartData {
    const hasMultiplePortfolios = !!comparisonPortfolio;

    const datasets = [buildDataset(portfolio, primaryPortfolioColor, getPortfolioName("A", hasMultiplePortfolios))];
    if (comparisonPortfolio) {
        datasets.push(buildDataset(comparisonPortfolio, secondaryPortfolioColor, getPortfolioName("B", hasMultiplePortfolios)));
    }

    const data = {
        datasets,
    };

    return data;
}

function calculateBoundaries(data: Chart.ChartData) {
    let minX;
    let maxX;
    let minY;
    let maxY;

    for (const dataset of data.datasets!) {
        for (const datapoint of dataset.data as Chart.ChartPoint[]) {
            if (minX === undefined || datapoint.x! < minX) {
                // TODO: Don't force unwrap !
                minX = datapoint.x;
            }
            if (minY === undefined || datapoint.y! < minY) {
                // TODO: Don't force unwrap !
                minY = datapoint.y;
            }
            if (maxX === undefined || datapoint.x! > maxX) {
                // TODO: Don't force unwrap !
                maxX = datapoint.x;
            }
            if (maxY === undefined || datapoint.y! > maxY) {
                // TODO: Don't force unwrap !
                maxY = datapoint.y;
            }
        }
    }
    return { minX, maxX, minY, maxY };
}

function buildRiskReturnScatterplotChartOptions(maxX: number, maxY: number): Chart.ChartOptions {
    return {
        legend: {
            display: true,
            position: "bottom",
            labels: {
                generateLabels: generateLabelsFillWithBorder,
            },
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Expected risk (%)",
                    },
                    ticks: {
                        min: 0,
                        max: Math.ceil(maxX * 1.1),
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Expected annual return (%)",
                    },
                    ticks: {
                        min: 0,
                        max: Math.ceil(maxY * 1.1),
                    },
                },
            ],
        },
        tooltips: {
            mode: "point",
            callbacks: {
                title: (tooltipItems: any, tooltipData: any) => {
                    return tooltipData.datasets[tooltipItems[0].datasetIndex].label;
                },
                label: (tooltipItem: any, tooltipData: any) => {
                    return `Risk ${roundTwoDecimals(tooltipItem.xLabel)}%, return ${roundTwoDecimals(tooltipItem.yLabel)}%`;
                },
            },
        },
    };
}

export { buildRiskReturnScatterplotData, calculateBoundaries, buildRiskReturnScatterplotChartOptions };
