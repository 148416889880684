import { CreateUserRequest, UpdateUserRequest, UserProfile } from "apiclient/responsePayloads/User";
import axios, { AxiosError } from "axios";

export const updateUser = async (id: string, userData: UpdateUserRequest, refresh: () => void) => {
  return axios
    .put("/api/users/" + id, userData)
    .then(res => {
      refresh();
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      return err;
    });
};

export const createUser = async (userData: CreateUserRequest, refresh: () => void): Promise<void | AxiosError> => {
  return axios
    .post("/api/users", userData)
    .then(res => {
      refresh();
    })
    .catch((err: AxiosError) => {
      console.log(err);
      return err;
    });
};

export const getUsers = async (): Promise<UserProfile[]> => {
  return axios
    .get("/api/users")
    .then(res => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      return err;
    });
};

export const getProfile = async (): Promise<UserProfile> => {
  return axios
    .get("/api/profile")
    .then(res => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      return err;
    });
};
