// Helper functions to get various URLs
import { Simulation } from "apiclient/responsePayloads/Simulation";

function getURL(page: string, simulation: Simulation, lang: string = 'en') {
  return `${simulation.getBasePath(lang)}${page}`
}

export function getRiskProfileURL(simulation: Simulation, lang: string = 'en') {
  return getURL('riskprofile', simulation, lang)
}

export function getSimulationURL(simulation: Simulation, lang: string = 'en') {
  return getURL('simulation', simulation, lang)
}

export function getFundsURL(simulation: Simulation, lang: string = 'en') {
  return getURL('funds', simulation, lang)
}

export function getReportURL(simulation: Simulation, lang: string = 'en') {
  return getURL('report', simulation, lang)
}
