const graphColors = [
  "#081519",
  "#366D9D",
  "#87BCDD",
  "#f7e5c5",
  "#b2cbc7",
  "#6cb1c9",
  "#D86666",
  "#EDA9A9",

  // Same colors once more in the case of many bars in the graph
  "#081519",
  "#366D9D",
  "#87BCDD",
  "#f7e5c5",
  "#b2cbc7",
  "#6cb1c9",
  "#D86666",
  "#EDA9A9"
];

const primaryPortfolioColor = "#081519";
const primaryPortfolioColorTransparent = "rgba(8, 21, 25, 0.5)";
const primaryBackgroundColor = "rgba(8, 21, 25, 0.15)";
const secondaryPortfolioColor = "#366D9D";
const secondaryPortfolioColorTransparent = "rgba(54, 109, 157, 0.5)";
const secondaryBackgroundColor = "rgba(54, 109, 157, 0.15)";
const fontColor = "#4e4e4e";

function hexToRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

function rgbaToHex(orig: string): string {
  // tslint:disable:no-bitwise
  const rgb: any = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  const alpha = ((rgb && rgb[4]) || "").trim();

  let a = alpha !== "" ? alpha : "01";
  a = ((Number(a) * 255) | (1 << 8)).toString(16).slice(1);
  let hex = rgb
    ? (rgb[1] | (1 << 8)).toString(16).slice(1) + (rgb[2] | (1 << 8)).toString(16).slice(1) + (rgb[3] | (1 << 8)).toString(16).slice(1)
    : orig;
  hex = hex + a;

  return "#" + hex;
  // tslint:enable:no-bitwise
}

const transparentColor = (color: string, alpha = 0.5) => rgbaToHex(hexToRgba(color, alpha));

export { graphColors, fontColor, transparentColor, primaryPortfolioColor, primaryPortfolioColorTransparent, primaryBackgroundColor, secondaryPortfolioColor, secondaryPortfolioColorTransparent, secondaryBackgroundColor };
