import axios, { AxiosError, AxiosResponse } from "axios";
import { AssetClassData } from "../apiclient/responsePayloads/AssetClass";

export const updateAssetClassOrder = async (assetClass: AssetClassData, newOrder: number): Promise<AxiosResponse | AxiosError> => {
  const updatedAssetClass: AssetClassData = {
    id: assetClass.id,
    name: assetClass.name,
    yield: assetClass.yield,
    risk: assetClass.risk,
    order: newOrder,
    funds: assetClass.funds,
    correlations: assetClass.correlations
  };
  return await axios
    .put(`/api/asset-classes/${assetClass.id}`, updatedAssetClass)
    .then(res => {
      return res;
    })
    .catch((err: AxiosError) => {
      console.log(err);
      return err;
    });
};

export const updateAssetClass = async (updatedAssetClass: AssetClassData): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .put(`/api/asset-classes/${updatedAssetClass.id}`, updatedAssetClass)
    .then(res => {
      return res;
    })
    .catch((err: AxiosError) => {
      console.log(err);
      return err;
    });
};

export const createAssetClass = async (newAssetClass: AssetClassData): Promise<AxiosResponse | AxiosError> => {
  return await axios
    .post(`/api/asset-classes/${newAssetClass.id}`, newAssetClass)
    .then(res => {
      return res;
    })
    .catch((err: AxiosError) => {
      console.log(err);
      return err;
    });
};
