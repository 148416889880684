import React, { useState } from "react";
import { TextField, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Zoom } from "@material-ui/core";
import { fundsStyles } from "./FundsStyles";
import { IFund } from "apiclient/responsePayloads/Fund";
import { Container } from "semantic-ui-react";

interface EditFundProps {
  open: boolean;
  fund: IFund;
  handleUpdateFund: (fund: IFund) => void;
  handleClose: () => void;
}

export const EditFund: React.FC<EditFundProps> = props => {
  const { fund, open, handleUpdateFund, handleClose } = props;
  const classes = fundsStyles();
  const [name, setName] = useState(fund.name);
  const [description, setDescription] = useState(fund.description ? fund.description : "");

  const handleSaveButtonClick = () => {
    const updatedFund: IFund = {
      id: fund.id,
      name,
      description
    };
    handleUpdateFund(updatedFund);
    handleClose();
  };

  const isChanged = () => {
    if (!fund.description) return name !== fund.name || description !== "";
    else return name !== fund.name || description !== fund.description;
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="edit-dialog-title" aria-describedby="edit-dialog-description">
        <DialogTitle className={classes.borderBot} id="edit-dialog-title">
          Edit fund
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="overline">Name</Typography>
              <TextField id="name" type="text" size="small" variant="outlined" fullWidth value={name} required onChange={e => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline">Description</Typography>
              <TextField
                id="description"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                placeholder="Description(optional)"
                rows="5"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Zoom in={isChanged()} mountOnEnter unmountOnExit>
            <Typography variant="overline" color="secondary">
              You have unsaved changes
            </Typography>
          </Zoom>
          <Button onClick={() => handleClose()}>Close</Button>
          <Button
            disabled={!isChanged()}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSaveButtonClick();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
