import React from "react";
import { Bar } from "react-chartjs-2";
import { DesktopOrTablet, Mobile } from "components/util/responsive";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { buildData, buildOptions, makeAssetClassesLabelsAndWeights, makeFundsLabelsAndWeights } from "./PortfolioContentChartDataBuilders";
import Funds from "apiclient/Funds";
import "./PortfolioContentChart.css";

interface IPortfolioContentBarChartProps {
  labels: string[];
  weights: number[];
}

class PortfolioContentBarChart extends React.Component<IPortfolioContentBarChartProps> {
  public render(): React.ReactNode {
    const data = buildData(this.props.labels, this.props.weights);
    const chartOpts = buildOptions(this.props.labels);

    return (
      <div>
        <Mobile>
          <div style={{ maxHeight: "300px" }}>
            <Bar height={300} data={data} options={chartOpts} legend={{ display: false, position: "bottom" }} />
          </div>
        </Mobile>
        <DesktopOrTablet>
          <div style={{ maxHeight: "450px" }}>
            <Bar height={450} data={data} options={chartOpts} legend={{ display: false, position: "left" }} />
          </div>
        </DesktopOrTablet>
      </div>
    );
  }
}

/* Displays a portfolio's asset classes or asset classes plus funds, depending on configuration. */
interface IPortfolioContentChartProps {
  portfolio: AssetClassPortfolio;
  withFunds?: Funds;
  titleSuffix?: string;
}

export class PortfolioContentChart extends React.Component<IPortfolioContentChartProps> {
  public render(): React.ReactNode {
    return (
      <div className="PortfolioContentChart chart-section">
        <h3>Portfolio Content {this.props.titleSuffix}</h3>
        {this.props.withFunds !== undefined ? this.renderFunds(this.props.withFunds) : this.renderAssetClasses()}
      </div>
    );
  }

  private renderAssetClasses(): JSX.Element {
    const assetClassesLabelsAndWeights = makeAssetClassesLabelsAndWeights(this.props.portfolio);
    return <PortfolioContentBarChart labels={assetClassesLabelsAndWeights[0]} weights={assetClassesLabelsAndWeights[1]} />;
  }

  private renderFunds(funds: Funds): JSX.Element {
    const fundsLabelsAndWeights = makeFundsLabelsAndWeights(this.props.portfolio, funds);
    return <PortfolioContentBarChart labels={fundsLabelsAndWeights[0]} weights={fundsLabelsAndWeights[1]} />;
  }
}
