import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    link: {
      color: '#23bcec',
      alignItems: 'center',
      '&:active': {
        backgroundColor: '#e8f8fd',
      },
      '&:hover': {
        backgroundColor: '#f6fcfe',
      },
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    uploadButton: {
      color: 'rgb(22,53,101)',
      borderColor: 'rgb(22,53,101)',
    },
    activeStyle: {
      borderColor: '#2196f3',
    },
    acceptStyle: {
      borderColor: '#00e676',
    },
    rejectStyle: {
      borderColor: '#ff1744',
    },
    table: {
      width: '100%',
    },
    tableHeaderCell: {
      backgroundColor: 'rgb(22,53,101)',
      color: '#e8ebef !important',
      textAlign: 'center',
      maxWidth: 60,
      paddingRight: 16,
    },
    tableCell: {
      color: '#4c596a',
      paddingRight: 16,
      maxWidth: 60,
      overflowX: 'hidden',
    },
    wrapper: {
      margin: theme.spacing(3),
      overflowX: 'scroll',
    },
    tableTitle: {
      paddingLeft: 20,
      paddingTop: 20,
      marginBottom: 20,
    },
    errorBox: {
      flex: 1,
      width: '100%',
      display: 'flex',
      marginBottom: 10,
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2px',
      borderWidth: 1,
      borderRadius: 20,
      borderColor: '#ffcdd2',
      borderStyle: 'solid',
      backgroundColor: '#ffebee',
      color: 'black',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    },
  })
);

interface IDropZone {
  flex: number;
  width: string;
  display: string;
  margin: string;
  flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems: string;
  padding: string;
  borderWidth: number;
  borderRadius: number;
  borderColor: string;
  borderStyle: string;
  backgroundColor: string;
  color: string;
  outline: string;
  transition: string;
}

export const baseStyle: IDropZone = {
  flex: 1,
  width: '100%',
  display: 'flex',
  margin: '20px 0 5px 0',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

export const activeStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#00e676',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};
