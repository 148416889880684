import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { IRouterProps } from 'util/RouterPropsInterface';
import RiskProfileButtons from './RiskProfileButtons';
import { Simulation, SimulationData } from 'apiclient/responsePayloads/Simulation';
import { AssetClasses } from 'apiclient/AssetClasses';
import { NavigationStep, ProcessNavPageHeader } from 'components/ProcessNavigator/ProcessNavigator';
import { SavingState } from 'store/simulations/reducers';
import { useAssetClasses } from 'store/financedata/helpers';
import Spinner from 'util/Spinner';
import { getSimulationURL } from 'urls';
import { IAppLayoutProps } from 'components/App/App';
import MainLayout from 'components/App/MainLayout';

import './RiskProfilePage.css';

interface IRouterMatchProps extends IRouterProps {
  lang: string;
}

interface IRiskProfilePageProps extends RouteComponentProps<IRouterMatchProps> {
  simulation: Simulation;
  savingState?: SavingState;
  onSimulationChange(simulation: SimulationData): void;
  layoutProps: IAppLayoutProps;
}

const RiskProfilePage: React.FC<IRiskProfilePageProps> = (props) => {
  const [riskLevel, setRiskLevel] = useState<number | undefined>(props.simulation.riskLevel ? props.simulation.riskLevel : undefined);
  const nextUrl = getSimulationURL(props.simulation);
  const layoutPropsWithTitle = { ...props.layoutProps, title: 'Select start risk profile' };

  const assetClasses = useAssetClasses();
  if (assetClasses === undefined) {
    return <Spinner />;
  }

  function handleNextClick(ac: AssetClasses) {
    if (riskLevel !== undefined) {
      const suggestedPortfolio = riskLevel;
      const newSim = props.simulation.clone();
      newSim.riskLevel = riskLevel;
      newSim.updatePortfolioAWithNumber(suggestedPortfolio, ac);
      newSim.updatePortfolioBWithNumber(suggestedPortfolio, ac);
      props.onSimulationChange(newSim.getSimulationData()); // TODO add callback to that navigation to next page happens on success
      props.history.push(nextUrl);
    }
  }

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="RiskProfilePage">
        <ProcessNavPageHeader
          onNewSimulation={props.layoutProps.onNewSimulation}
          step={NavigationStep.RiskProfile}
          simulation={props.simulation}
          nextUrl={nextUrl}
          onNext={() => {
            handleNextClick(assetClasses);
          }}
          nextDisabled={riskLevel === undefined}
          savingState={props.savingState}
        />
        <RiskProfileButtons riskLevel={props.simulation.riskLevel} onChange={(newLevel: number) => setRiskLevel(newLevel)} />
      </div>
    </MainLayout>
  );
};

export default withRouter(RiskProfilePage);
