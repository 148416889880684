import React, { useState } from "react";
import { Menu } from 'semantic-ui-react';

import './PortfolioSwitcher.css';

export const PortfolioSwitcher: React.FC = props => {
    const [activeIndex, setActiveIndex] = useState(0)
    const children = React.Children.toArray(props.children)

    function activeTab(): React.ReactNode {
        return children[activeIndex]
    }

    function renderTabsMenu() {
        const letters = ['A', 'B', 'C', 'D', 'E'];
        const items: JSX.Element[] = [];
        for (let i = 0; i < children.length; i++) {
            items.push(
                <Menu.Item
                    key={i}
                    active={activeIndex === i}
                    onClick={() => setActiveIndex(i)}>
                    {`Portfolio ${letters[i]}`}
                </Menu.Item>
            );
        }
        return (
            <Menu compact={true} className='switcher-tabs'>
                {items}
            </Menu>
        );
    }

    return (
        <div className={children.length > 1 ? 'PortfolioSwitcher' : ''}>
            {children.length > 1 && renderTabsMenu()}
            {activeTab()}
        </div>
    );
}
export default PortfolioSwitcher;
