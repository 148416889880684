import React from "react";
import { Form, Button } from "semantic-ui-react";

interface Props {
  onClick: (password: string) => void;
}

export const ResetPasswordForm: React.FC<Props> = props => {
  const { onClick } = props;
  const [pass1, setPass1] = React.useState("")
  const [pass2, setPass2] = React.useState("")

  const canSubmit = () => pass1 !== "" && pass2 !== "" && pass1 === pass2;

  return (
    <Form>
      <Form.Group>
        <Form.Input
          label='New password' placeholder='New password'
          width={8} value={pass1} type='password'
          onChange={(e) => { setPass1(e.target.value); }} />
        <Form.Input
          label='Confirm new password' placeholder='Confirm new password'
          width={8} value={pass2} type='password'
          error={pass1 !== pass2}
          onChange={(e) => { setPass2(e.target.value); }} />
      </Form.Group>
      <Form.Group style={{ justifyContent: 'center' }}>
        <Button
          primary={true}
          disabled={!canSubmit()}
          onClick={() => onClick(pass1)}>
          Update Password
        </Button>
      </Form.Group>
    </Form>
  )
}
