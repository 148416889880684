import * as React from 'react';

import { RiskReturnScatterplotChart } from 'components/charts/RiskReturnScatterplot/RiskReturnScatterplot';
import { HistoricReturnGraph } from 'components/charts/HistoricReturnGraph/HistoricReturnGraph';
import { PortfolioContentChart } from 'components/charts/PortfolioContentChart/PortfolioContentChart';
import { ExpectedProfitAndWorstOutcomeChart } from 'components/charts/ExpectedProfitAndWorstOutcomeChart/ExpectedProfitAndWorstOutcomeChart';
import { DrawdownChartSection } from 'components/charts/DrawdownChart/DrawdownChart';
import { RiskAllocationChart } from 'components/charts/RiskAllocationChart/RiskAllocationChart';
import { AssetClassReturns } from 'apiclient/AssetClassReturns';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import { ChartSection } from 'components/ChartSection/ChartSection';
import { JoinedForwardSimulationGraph } from 'components/charts/JoinedForwardSimulationGraph/JoinedForwardSimulationGraph';

// This component switches contents depending on whether returns is funds or assets.
interface ISimulationChartsProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  scaleFactorContribution: number;
  assetClassReturns: AssetClassReturns;
  onScaleFactorContributionChange(newContribution: number): void;
}

export class SimulationCharts extends React.Component<ISimulationChartsProps> {
  public render(): React.ReactNode {
    const { scaleFactorContribution, portfolioA, portfolioB } = this.props;
    return (
      <div className="simulation">
        <ChartSection toned={false}>
          <PortfolioContentChart portfolio={portfolioA} />
          <PortfolioContentChart portfolio={portfolioB} />
        </ChartSection>

        <ChartSection toned={true}>
          <JoinedForwardSimulationGraph
            onScaleFactorContributionChange={this.props.onScaleFactorContributionChange}
            scaleFactorContribution={scaleFactorContribution || 0}
            portfolioA={portfolioA}
            portfolioB={portfolioB}
          />
        </ChartSection>

        <ChartSection toned={false}>
          <ExpectedProfitAndWorstOutcomeChart
            portfolioA={portfolioA}
            portfolioB={portfolioB}
            isASelected={true}
            isBSelected={true}
            scaleFactorContribution={scaleFactorContribution}
            onScaleFactorContributionChanged={this.props.onScaleFactorContributionChange}
          />
        </ChartSection>

        <ChartSection toned={true} className="padding-top">
          <RiskReturnScatterplotChart portfolio={portfolioA} comparisonPortfolio={portfolioB} />
        </ChartSection>

        <ChartSection toned={false} className="padding-top">
          <HistoricReturnGraph
            portfolio={portfolioA}
            comparisonPortfolio={portfolioB}
            assetClassReturns={this.props.assetClassReturns}
            showDisclaimerIcon={true}
          />
        </ChartSection>

        <DrawdownChartSection
          portfolio={portfolioA}
          comparisonPortfolio={portfolioB}
          assetClassReturns={this.props.assetClassReturns}
          toned={true}
        />

        <ChartSection toned={false}>
          <RiskAllocationChart portfolio={portfolioA} showDisclaimerIcon={true} />
          <RiskAllocationChart portfolio={portfolioB} showDisclaimerIcon={true} />
        </ChartSection>
      </div>
    );
  }
}
