import React from "react";
import { RouteComponentProps } from "react-router";
import { Form, InputOnChangeData, Message, Container } from "semantic-ui-react";
import Auth from "modules/Auth";
import { IRouterProps } from "util/RouterPropsInterface";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { Link } from "react-router-dom";
import MainLayout from "components/App/MainLayout";
import { IAppLayoutProps } from "components/App/App";

import "./LoginPage.css";

interface IRouterMatchProps extends IRouterProps {
  lang: string;
}

interface ILoginPageProps extends RouteComponentProps<IRouterMatchProps> {
  nextUrl: string;
  onForgotPassClick: () => void;
  forgotPassUrl: string;
  layoutProps: IAppLayoutProps;
}

const LoginPage: React.FC<ILoginPageProps> = props => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);

  const layoutPropsWithTitle = { ...props.layoutProps, title: "Inst simulation - login" };

  const handleChangeEmail = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => setEmail(data.value);

  const handleChangePassword = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => setPassword(data.value);

  const isSubmittable = () => email.length > 0 && password.length > 0;

  const onLogin = () => {
    props.history.push(props.nextUrl);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!isSubmittable()) {
      return;
    }

    const auth = new Auth(localStorage);

    auth
      .login(email, password)
      .then(token => {
        setErrorMessage(null);
        onLogin();
      })
      .catch(error => {
        console.log(error.response);
        if (error.response && error.response.status === 422) {
          setErrorMessage("Wrong email format.");
        } else {
          setErrorMessage("Oops! Wrong e-mail or password.");
        }
      });
  };

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="LoginPage">
        <SectionWrap className="main huge-padding-top" toned={true}>
          <Container>
            <div className="login-wrap">
              <Form onSubmit={handleSubmit}>
                <Form.Input
                  fluid={true}
                  className="carnegie__input"
                  label="E-mail"
                  name="email"
                  size="large"
                  value={email}
                  onChange={handleChangeEmail}
                />
                <Form.Input
                  fluid={true}
                  className="carnegie__input"
                  label="Password"
                  type="password"
                  name="password"
                  size="large"
                  value={password}
                  onChange={handleChangePassword}
                />
                <Form.Group>
                  <button className="ui button carnegie__button" style={{ marginLeft: 7 }} type="submit" disabled={!isSubmittable()}>
                    Log in
                  </button>
                </Form.Group>
                <div style={{ marginTop: 20 }}>
                  <Link className="forgot-link" to={props.forgotPassUrl}>
                    Forgot your password?
                  </Link>
                </div>
              </Form>

              {errorMessage && <Message warning={true}>{errorMessage}</Message>}

              <div className="new-customer-wrap">
                <p>
                  No account yet? <br /> Get in touch with us to <Link to="/request-account">request one</Link>!
                </p>
                <div className="ui horizontal divider">
                  <span style={{ fontSize: "smaller" }}>Or</span>
                </div>
                Try out our <Link to="/">demo simulation</Link>!
              </div>
            </div>
          </Container>
        </SectionWrap>
      </div>
    </MainLayout>
  );
};

export default LoginPage;
