import React from 'react';
import { Input } from 'semantic-ui-react';
import './DistributionSlider.css';

interface IDistributionSliderProps {
  extraStyle?: any;
  distribution: number;
  onDistributionChanged?: (distribution: number) => void;
  label1: string;
  label2: string;
}

export const DistributionSlider = ({ extraStyle, distribution, onDistributionChanged, label1, label2 }: IDistributionSliderProps) => (
  <div className="DistributionSlider" style={extraStyle}>
    <label onClick={() => onDistributionChanged && onDistributionChanged(0)}>{label1}</label>
    <Input
      type="range"
      min="0"
      max="100"
      step="1"
      value={distribution}
      onChange={(e, data) => onDistributionChanged && onDistributionChanged(Number(data.value))}
    />
    <label onClick={() => onDistributionChanged && onDistributionChanged(100)}>{label2}</label>
  </div>
);
