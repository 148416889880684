import { UserProfile } from "apiclient/responsePayloads/User";
import { Reducer } from "redux";
import { AppAction } from "store/actions";
import { PROFILE_LOAD_SUCCESS, PROFILE_IS_LOADING, PROFILE_LOAD_HAS_ERRORED, PROFILE_IS_SAVING, PROFILE_SAVE_HAS_ERRORED, PROFILE_JUST_SAVED, PROFILE_PWD_CHANGING, PROFILE_PWD_JUST_CHANGED, PROFILE_PWD_CHANGE_FAILED } from "./actions";

export interface ProfileSliceState {
  user?: UserProfile
  isLoading: boolean
  isSaving: boolean
  justSaved: boolean
  loadHasErrored: boolean
  saveHasErrored: boolean
  passwordIsChanging: boolean
  passwordJustChanged: boolean
  passwordChangeError?: string
}

const initialState: ProfileSliceState = {
  user: undefined,
  isLoading: false,
  isSaving: false,
  justSaved: false,
  loadHasErrored: false,
  saveHasErrored: false,
  passwordIsChanging: false,
  passwordJustChanged: false,
  passwordChangeError: undefined
}

const reduceProfilePassword: Reducer<ProfileSliceState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_PWD_CHANGING:
      return { ...state, passwordIsChanging: action.isChanging }
    case PROFILE_PWD_JUST_CHANGED:
      return { ...state, passwordJustChanged: action.justChanged, passwordChangeError: undefined }
    case PROFILE_PWD_CHANGE_FAILED:
      return { ...state, passwordChangeError: action.message }
    default:
      return state;
  }
}

export const reduceProfile: Reducer<ProfileSliceState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case PROFILE_LOAD_HAS_ERRORED:
      return {
        ...state,
        loadHasErrored: action.hasErrored
      }
    case PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        user: action.user
      }
    case PROFILE_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving
      }
    case PROFILE_SAVE_HAS_ERRORED:
      return {
        ...state,
        saveHasErrored: action.hasErrored
      }
    case PROFILE_JUST_SAVED:
      return {
        ...state,
        justSaved: action.justSaved
      }
    case PROFILE_PWD_CHANGING:
    case PROFILE_PWD_JUST_CHANGED:
    case PROFILE_PWD_CHANGE_FAILED:
      return reduceProfilePassword(state, action);
    default:
      return state
  }
}
