import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Container } from "semantic-ui-react";
import { dialogStyles } from "./FundsStyles";
import { AddFundForm } from "./AddFundForm";
import { IFund } from "apiclient/responsePayloads/Fund";

interface AddFundDialogProps {
  open: boolean;
  handleClose: () => void;
  handleCreateFund: (fund: IFund) => void;
}

export const AddFundDialog: React.FC<AddFundDialogProps> = props => {
  const { open, handleClose, handleCreateFund } = props;
  const classes = dialogStyles();

  const handleCreateFundClick = (fund: IFund) => {
    handleCreateFund(fund);
    handleClose();
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle className={classes.dialogTitle} id="add-fund-dialog">
          Create fund
        </DialogTitle>
        <DialogContent>
          <div className={classes.wrapperGrid}>
            <AddFundForm handleCreateFund={handleCreateFundClick} />
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
