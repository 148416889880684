import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { ModalData } from "components/CustomPortfolioModal/CustomPortfolioModal";
import { AssetWeights } from "business/AssetWeights";
import Funds from "apiclient/Funds";
import { isFundIdIndexFund } from "apiclient/responsePayloads/Fund";
import { useAssetClasses } from "store/financedata/helpers";
import _ from "lodash";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";

export enum FundsTableColumnType {
  FixedIncome = "Fixed Income",
  Equities = "Equities",
  Alternatives = "Alternatives"
}

export class FundData {
  public readonly id: string;
  public readonly name: string;
  public readonly weight: number;
  public readonly isIndexFund: boolean;
  public readonly infoText: string;

  constructor(id: string, name: string, weight: number, isIndexFund: boolean, infoText: string) {
    this.id = id;
    this.name = name;
    this.weight = weight;
    this.isIndexFund = isIndexFund;
    this.infoText = infoText;
  }
}

export class FundsTableColumnRowData {
  public readonly assetClassID: string;
  public readonly title: string;
  public readonly funds: FundData[];

  constructor(assetClassID: string, title: string, funds: FundData[]) {
    this.assetClassID = assetClassID;
    this.title = title;
    this.funds = funds;
  }

  public sumOfWeights(): number {
    return this.funds.map(x => x.weight).reduce((total, amount) => total + amount);
  }

  public asModalData(): ModalData {
    const ids = this.funds.map(x => x.id);
    const names = this.funds.map(x => x.name);
    const weights = this.funds.map(x => x.weight);
    const classNames = this.funds.map(x => (x.isIndexFund ? "index-fund" : ""));
    const assetWeights = new AssetWeights(ids.reduce((o, k, i) => ({ ...o, [k]: weights[i] }), {}));
    return new ModalData(ids, names, assetWeights, classNames, this.title);
  }
}

export default function FundsTableChartsDataBuilder() {
  function createData(type: FundsTableColumnType, portfolio: AssetClassPortfolio, funds: Funds) {
    const data = [];
    const ids = getAssetIds(type);
    for (const id of ids) {
      const allocations = portfolio.allocations.filter(x => x.assetClass.id === id);
      if (allocations && allocations.length > 0) {
        const allocation = allocations[0];
        const fundAllocations = portfolio.getFundAllocationForAssetClass(allocation.assetClass, allocation.normalizedWeightInPercent);
        const fundList: FundData[] = [];
        const indexFundList: FundData[] = [];
        Object.keys(fundAllocations.funds).forEach(fundID => {
          const fundListToAppend = isFundIdIndexFund(fundID) ? indexFundList : fundList;
          fundListToAppend.push(
            new FundData(
              fundID,
              funds.getFund(fundID).name,
              fundAllocations.funds[fundID],
              isFundIdIndexFund(fundID),
              funds.getFund(fundID).description
            )
          );
        });
        data.push(new FundsTableColumnRowData(allocation.assetClass.id, allocation.assetClass.name, fundList.concat(indexFundList)));
      }
    }
    return data;
  }

  function getAssetIds(type: FundsTableColumnType) {
    const defaultAssets = [
      "tbills",
      "tbonds",
      "credits-inv-grade",
      "credits-high-yield",
      "swedish-equitites",
      "global-equities",
      "em-equities",
      "private-equity",
      "hedge-funds",
      "infrastructure",
      "commodities",
      "real-estate"
    ];
    let addedAssets: string[] = [];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const allAssets = useAssetClasses();
    if (allAssets) {
      const sortedDefaultAssetIDs = defaultAssets.sort();
      const sortedAssetIDs = allAssets.data.map((ac: AssetClassData) => ac.id).sort();

      if (!_.isEqual(sortedDefaultAssetIDs, sortedAssetIDs)) {
        sortedAssetIDs.forEach((ac: string) => {
          if (!sortedDefaultAssetIDs.find(d => d === ac)) addedAssets.push(ac);
        });
      }
    }

    switch (type) {
      case FundsTableColumnType.FixedIncome:
        return ["tbills", "tbonds", "credits-inv-grade", "credits-high-yield"];
      case FundsTableColumnType.Equities:
        return ["swedish-equitites", "global-equities", "em-equities"];
      case FundsTableColumnType.Alternatives:
        return ["private-equity", "hedge-funds", "infrastructure", "commodities", "real-estate"].concat(addedAssets);
      default:
        return [];
    }
  }

  return { createData };
}
