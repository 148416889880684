import { IFund } from "./responsePayloads/Fund";

export default class Funds {
    public readonly data: { [id: string]: IFund; }

    constructor(fundList: IFund[]) {
        this.data = {};
        fundList.forEach(fund => {
            this.data[fund.id] = fund;
        });
    }

    public getFundName(id: string): string {
        return this.data[id].name;
    }

    public getFund(id: string): IFund {
        return this.data[id]
    }
}
