import React from "react";
import { Container, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SimulationsAPI from "apiclient/SimulationsAPI";
import Auth from "modules/Auth";
import { Desktop } from "components/util/responsive";
import { formatPercentage, formatDateTime } from "util/misc-utils";
import { AssetClassesSlice } from "store/financedata/reducers";
import { AssetClasses } from "apiclient/AssetClasses";
import Spinner from "util/Spinner";
import { getSimulationURL } from "urls";
import { SimulationData, Simulation } from "apiclient/responsePayloads/Simulation";
import { useAssetClasses } from "store/financedata/helpers";
import { useDispatch } from "react-redux";
import { fetchSimulations } from "store/simulations/actions";
import { IAppLayoutProps } from "components/App/App";
import MainLayout from "components/App/MainLayout";

import "./MySavedSimulationsPage.css";
import DeleteButton from "./DeleteButtonComponent";
import { PictureAsPdf } from "@material-ui/icons";
import { SectionWrap } from "components/SectionWrap/SectionWrap";

interface IMySavedSimulationsPageProps {
  simulations?: SimulationData[];
  assetClassesRedux: AssetClassesSlice;
  layoutProps: IAppLayoutProps;
}

const MySavedSimulationsPage: React.FC<IMySavedSimulationsPageProps> = props => {
  const [isDeletingSimulationID, setIsDeletingSimulationID] = React.useState<string | null>(null);
  const assetClasses = useAssetClasses();
  const dispatch = useDispatch();
  const layoutPropsWithTitle = { ...props.layoutProps, title: "My Saved Simulations" };

  if (assetClasses === undefined) {
    return <Spinner />;
  }

  function handleDelete(simulationId?: string) {
    const auth = new Auth(localStorage);
    const userID = auth.getUserID();
    if (userID === undefined || simulationId === undefined) {
      throw new Error("cannot delete portfolio unless logged in");
    }
    setIsDeletingSimulationID(simulationId);
    SimulationsAPI.deleteSimulation(
      userID,
      simulationId,
      () => {
        dispatch(fetchSimulations(userID));
        setIsDeletingSimulationID(null);
      },
      (err: Error) => {
        alert("failed to delete simulation");
        console.log("failed to delete simulation", err);
      }
    );
  }

  function renderSimulationRows(assetClasses: AssetClasses): React.ReactNode[] {
    if (props.simulations === undefined) {
      return [];
    }
    const rows: React.ReactNode[] = [];
    for (const sim of props.simulations) {
      const simWrapper = new Simulation(sim);
      const portf = simWrapper.portfolioA(assetClasses);
      const portfANum = simWrapper.getPortfolioANumber();
      const risk = portf.calculateRisk();
      const rtrn = portf.calculateYield();

      rows.push(
        <Table.Row key={sim.id}>
          <Table.Cell>
            <Link to={getSimulationURL(simWrapper)}>{sim.name}</Link>
          </Table.Cell>
          <Desktop>
            <Table.Cell>{portfANum === null ? "Custom" : portfANum}</Table.Cell>
            <Table.Cell>{sim.modifiedAt ? formatDateTime(new Date(sim.modifiedAt)) : "-"}</Table.Cell>
            <Table.Cell>{formatPercentage(rtrn)}</Table.Cell>
            <Table.Cell>{formatPercentage(risk)}</Table.Cell>
          </Desktop>
          <Table.Cell textAlign="center">
            <Button.Group>
              <Button className="PDFButton carnegie__button" size="mini" as="a" href={simWrapper.pdfURL()} target="_pdfreport">
                <PictureAsPdf />
              </Button>
              <DeleteButton onDelete={() => handleDelete(sim.id)} loading={sim.id === isDeletingSimulationID} />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    }
    return rows;
  }

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <SectionWrap toned={true}>
        <div className="MySavedSimulationsPage">
          <Container>
            <Table celled={true} unstackable={true}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Desktop>
                    <Table.HeaderCell>Portfolio</Table.HeaderCell>
                    <Table.HeaderCell>Last Saved</Table.HeaderCell>
                    <Table.HeaderCell>Return</Table.HeaderCell>
                    <Table.HeaderCell>Risk</Table.HeaderCell>
                  </Desktop>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>{renderSimulationRows(assetClasses)}</Table.Body>
            </Table>
          </Container>
        </div>
      </SectionWrap>
    </MainLayout>
  );
};

export default MySavedSimulationsPage;
