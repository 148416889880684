import * as React from "react";
import { Route, Redirect } from "react-router";
import Auth from "modules/Auth";
import { IAppLayoutProps } from "./App";

interface IAppRouteProps {
    component: any;
    extraProps: any;
    layoutProps: IAppLayoutProps;
    protect?: boolean;
    exact: boolean;
    path: string;
}

const AppRoute = ({ component: Component, extraProps, layoutProps, protect, ...rest }: IAppRouteProps) => {
    return (
        <Route
            {...rest}
            exact={true}
            render={(props) => {
                const propsToPass = Object.assign({}, props, extraProps, { layoutProps: layoutProps });
                if (protect && !new Auth(localStorage).isLoggedIn()) {
                    return <Redirect to={"/en/login"} />;
                }
                return <Component {...propsToPass} />;
            }}
        />
    );
};

export default AppRoute;
