import React from 'react'
import './SectionWrap.css'

interface ISectionWrapsProps {
    toned: boolean
    className?: string
}

const SectionWrap: React.SFC<ISectionWrapsProps> = (props) => (
    <div className={'SectionWrap ' + (props.toned ? 'toned ' : '') + (props.className ? props.className : '')}>
        {props.children}
    </div>
)

export {
    SectionWrap
}