import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { defaults as chartJSDefaults } from "react-chartjs-2";
import { Container } from "semantic-ui-react";

import { SimulationData, Simulation } from "apiclient/responsePayloads/Simulation";
import { IRouterProps } from "util/RouterPropsInterface";
import SimulationsAPI from "apiclient/SimulationsAPI";
import { AssetClassAllocationReportSection } from "./AssetClassAllocationReportSection";
import { FundAllocationReportSection } from "./FundAllocationReportSection";
import { ReportHeaderDetailsTable } from "./ReportHeaderSection";
import { DisclaimerFooter } from "components/DisclaimerFooter/DisclaimerFooter";
import { useAssetClasses, useAssetClassReturns, useFundReturns, useFunds } from "store/financedata/helpers";
import { REACT_APP_FUNDS_ON } from "components/App/isFundsOn";
import Spinner from "util/Spinner";

import "./ReportPDFPage.css";
import { logo } from "components/TopMenu/TopMenu";

interface IRouterMatchProps extends IRouterProps {
  lang: string;
  token: string;
}

interface IReportPageProps {
  simulation: Simulation;
}

const ReportPDFPage: React.FC<IReportPageProps> = props => {
  const assetClasses = useAssetClasses();
  const assetClassReturns = useAssetClassReturns();
  const fundReturns = useFundReturns();
  const funds = useFunds();

  if (assetClasses === undefined || assetClassReturns === undefined || fundReturns === undefined || funds === undefined) {
    return <Spinner />;
  }

  const portfolioA = props.simulation.portfolioA(assetClasses);
  const portfolioB = props.simulation.portfolioB(assetClasses);

  // Disable animating charts by default.
  const chartDefaults: any = chartJSDefaults;
  chartDefaults.global.animation.duration = 0;

  return (
    <div className="ReportPDFPage">
      <section className="report-header">
        <div className="title">
          <h1>Simulation Report</h1>
        </div>
        <div className="logo">{logo}</div>
      </section>

      <Container text={true}>
        <ReportHeaderDetailsTable simulation={props.simulation} />
      </Container>
      <Container text={true}>
        <AssetClassAllocationReportSection
          portfolioA={portfolioA}
          portfolioB={portfolioB}
          assetClassReturns={assetClassReturns}
          scaleFactorContribution={props.simulation.scaleFactorContribution}
        />
        {REACT_APP_FUNDS_ON && (
          <FundAllocationReportSection
            simulation={props.simulation}
            assetClasses={assetClasses}
            assetClassReturns={assetClassReturns}
            fundReturns={fundReturns}
            funds={funds}
          />
        )}
        <div className="pdf-disclaimer-footer">
          <DisclaimerFooter />
        </div>
      </Container>
    </div>
  );
};

interface IReportPDFPageLoaderProps extends RouteComponentProps<IRouterMatchProps> {}

interface IReportPDFPageLoaderState {
  loading: boolean;
  simulation: SimulationData | null;
}

class ReportPDFPageLoader extends React.Component<IReportPDFPageLoaderProps, IReportPDFPageLoaderState> {
  constructor(props: IReportPDFPageLoaderProps) {
    super(props);

    this.state = {
      loading: true,
      simulation: null
    };

    const token = this.props.match.params.token;
    SimulationsAPI.fetchSimulationByToken(
      token,
      simulation => {
        this.setState({ loading: false, simulation }); // Successfully loaded simulation
      },
      (err: Error) => {
        // tslint:disable-next-line:no-console
        console.log("Failed to load simulation", err);
        this.setState({ loading: false, simulation: null }); // Failed to load simulation
      }
    );
  }

  public render(): React.ReactNode {
    if (this.state.loading) {
      return <div>Loading</div>;
    }
    if (this.state.simulation === null) {
      return <div>No such simulation</div>;
    }
    return <ReportPDFPage simulation={new Simulation(this.state.simulation)} />;
  }
}

export default withRouter(ReportPDFPageLoader);
