import * as React from "react";
import { Container } from "semantic-ui-react";

import { PortfolioContentChart } from "components/charts/PortfolioContentChart/PortfolioContentChart";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { ForwardSimulationReportGraph } from "components/charts/ForwardSimulationGraph/ForwardSimulationGraph";
import { ExpectedProfitAndWorstOutcomeReportChart } from "components/charts/ExpectedProfitAndWorstOutcomeChart/ExpectedProfitAndWorstOutcomeChart";
import { RiskReturnScatterplotChart } from "components/charts/RiskReturnScatterplot/RiskReturnScatterplot";
import { HistoricReturnGraph } from "components/charts/HistoricReturnGraph/HistoricReturnGraph";
import { DrawdownChart } from "components/charts/DrawdownChart/DrawdownChart";
import { RiskAllocationChart } from "components/charts/RiskAllocationChart/RiskAllocationChart";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import ReportRiskReturnSection from "./ReportRiskReturnSection";

import "./AssetClassAllocationReportSection.css";

interface AssetClassAllocationReportSectionProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  scaleFactorContribution: number;
  assetClassReturns: AssetClassReturns;
}

export const AssetClassAllocationReportSection: React.SFC<AssetClassAllocationReportSectionProps> = ({
  portfolioA,
  portfolioB,
  scaleFactorContribution,
  assetClassReturns
}) => {
  return (
    <div className="AssetClassAllocationReportSection">
      <Container>
        <Container className="page">
          <h1>Asset Class Allocation</h1>

          <h2>Risk and Return</h2>
          <ReportRiskReturnSection portfolioA={portfolioA} portfolioB={portfolioB} />

          <PortfolioContentChart portfolio={portfolioA} titleSuffix={"- A"} />
          <PortfolioContentChart portfolio={portfolioB} titleSuffix={"- B"} />
        </Container>
        <Container className="page">
          <ForwardSimulationReportGraph portfolio={portfolioA} scaleFactorContribution={scaleFactorContribution} titleSuffix={"- A"} />
          <ForwardSimulationReportGraph
            portfolio={portfolioB}
            scaleFactorContribution={scaleFactorContribution}
            titleSuffix={"- B"}
            isSecondary
          />
        </Container>
        <Container className="page">
          <ExpectedProfitAndWorstOutcomeReportChart
            portfolioA={portfolioA}
            portfolioB={portfolioB}
            isASelected={true}
            isBSelected={false}
            scaleFactorContribution={scaleFactorContribution}
          />
          <ExpectedProfitAndWorstOutcomeReportChart
            portfolioA={portfolioA}
            portfolioB={portfolioB}
            isASelected={false}
            isBSelected={true}
            scaleFactorContribution={scaleFactorContribution}
          />
        </Container>
        <Container className="page">
          <RiskReturnScatterplotChart portfolio={portfolioA} comparisonPortfolio={portfolioB} />

          <HistoricReturnGraph portfolio={portfolioA} assetClassReturns={assetClassReturns} comparisonPortfolio={portfolioB} />

          <DrawdownChart portfolio={portfolioA} assetClassReturns={assetClassReturns} comparisonPortfolio={portfolioB} />
        </Container>
        <RiskAllocationChart portfolio={portfolioA} titleSuffix={"- A"} />
        <RiskAllocationChart portfolio={portfolioB} titleSuffix={"- B"} />
      </Container>
    </div>
  );
};
