import React, { useState } from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import { assetClassesStyles } from "./AssetClassesStyles";
import { AddFundSelector } from "admin/components/Assumptions/AssetClasses/AddFundSelector";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers";
import { ListFunds } from "./ListFunds";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import IStringNumberMap from "util/StringNumberMap";

interface AddAssetClassFormProps {
  existingAssetClasses?: AssetClassData[];
  handleCreateAssetClass: (assetClass: AssetClassData) => void;
}

export const AddAssetClassForm: React.FC<AddAssetClassFormProps> = props => {
  const { handleCreateAssetClass, existingAssetClasses } = props;
  const classes = assetClassesStyles();
  const assetClassesCopy: AssetClassData[] = [];
  Object.assign(assetClassesCopy, existingAssetClasses ? existingAssetClasses : []);
  const highestOrderClass: AssetClassData = assetClassesCopy.sort((a: AssetClassData, b: AssetClassData) => b.order - a.order)[0];
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [risk, setRisk] = useState(0);
  const [yld, setYield] = useState(0);
  const existingFunds = useSelector((state: AppState) => state.financeData.funds.data);
  const [funds, setFunds] = useState<string[]>([]);
  const [displayErrors, setDisplayErrors] = useState(false);

  const handleChangeYield = (value: string) => {
    setDisplayErrors(true);
    if (value === "") setYield(0);
    else setYield(parseFloat(value));
  };

  const handleChangeRisk = (value: string) => {
    setDisplayErrors(true);
    if (value === "") setRisk(0);
    else setRisk(parseFloat(value));
  };

  const handleAddFund = (fund: string) => {
    setDisplayErrors(true);
    if (fund !== "") {
      setFunds([...funds, fund]);
    }
  };

  const handleRemoveFund = (fund: string) => {
    setDisplayErrors(true);
    setFunds(funds.filter(f => f !== fund));
  };

  const handleChangeName = (name: string) => {
    setDisplayErrors(true);
    setName(name);
  };

  const handleChangeId = (id: string) => {
    setDisplayErrors(true);
    setId(id);
  };

  const handleCreateClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let correlations: IStringNumberMap = {};
    correlations[id] = 1;

    if (existingAssetClasses) {
      existingAssetClasses.forEach(ac => (correlations[ac.id] = 0));
    }

    const newAssetClass: AssetClassData = {
      id: id,
      name: name,
      yield: yld,
      risk: risk,
      order: highestOrderClass ? highestOrderClass.order + 1 : 1,
      funds: funds,
      correlations: correlations
    };

    handleCreateAssetClass(newAssetClass);
  };

  const formIsValid = (): boolean => {
    if (name !== "" && id !== "" && risk !== 0 && yld !== 0 && funds.length > 0) return true;
    else return false;
  };

  return (
    <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleCreateClick(e)}>
      <Grid container item xs={12} className={classes.form} spacing={2}>
        <Grid item xs={12}>
          <h3 className={classes.blueColor}>Add asset class</h3>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            error={name === "" && displayErrors}
            helperText={name === "" && displayErrors && "Name cannot be empty"}
            size="small"
            label="Name"
            placeholder="Ex: Hedgefonder"
            type="text"
            required
            value={name}
            onChange={e => handleChangeName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            error={id === "" && displayErrors}
            helperText={id === "" && displayErrors && "ID cannot be empty"}
            size="small"
            label="ID"
            required
            placeholder="Ex: hedge-funds"
            type="text"
            value={id}
            onChange={e => handleChangeId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={risk === 0 && displayErrors}
            helperText={risk === 0 && displayErrors && "Risk cannot be 0"}
            fullWidth
            variant="outlined"
            size="small"
            label="Risk"
            type="number"
            value={risk}
            onChange={e => handleChangeRisk(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={yld === 0 && displayErrors}
            helperText={yld === 0 && displayErrors && "Yield cannot be 0"}
            fullWidth
            variant="outlined"
            size="small"
            label="Yield"
            type="number"
            value={yld}
            onChange={e => handleChangeYield(e.target.value)}
          />
        </Grid>
        <ListFunds handleRemoveFund={handleRemoveFund} funds={funds.filter(f => !f.startsWith("index:"))} />
        {existingFunds && (
          <AddFundSelector handleAddFund={handleAddFund} existingFunds={existingFunds.filter(f => !f.id.startsWith("index:"))} selectedFunds={funds} />
        )}
        <Grid item xs={12}>
          <Button style={{ float: "right" }} type="submit" disabled={!formIsValid()} variant="outlined" color="primary">
            Add asset class
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
