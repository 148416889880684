import React, { useMemo, useCallback } from "react";
import { useStyles, baseStyle, activeStyle, acceptStyle, rejectStyle } from "./styles";
import { Container } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { Paper, Grid, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Zoom } from "@material-ui/core";
import { IAssetClassReturn } from "apiclient/responsePayloads/AssetClassReturn";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import { createXLFromData, parseXLToData, shortenText, checkDiffInXL, DiffDataType, ParsingReturnType, requestProgress } from "admin/XLUtil";
import { fetchAssetClassReturns, fetchFundReturns } from "store/financedata/actions";
import { updateAssetClassReturn, updateFundReturn, deleteAssetClassReturn, deleteFundReturn } from "admin/HistoricReturnsAPI";
import CustomLegend from "./CustomLegend";
import { RouteComponentProps } from "react-router-dom";
import { IFundReturn } from "apiclient/responsePayloads/FundReturn";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface HistoricReturnsProps extends RouteComponentProps {}
type AssetClassOrFundType = "AssetClass" | "Fund";

export const HistoricReturns: React.FC<HistoricReturnsProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const assetClassOrFund: AssetClassOrFundType = props.location.pathname.includes("assetclasses") ? "AssetClass" : "Fund";
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined);
  const [uploadingReturns, setUploadingReturns] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [loadingProgress, setLoadingProgress] = React.useState(0);
  const [diffInXL, setDiffInXL] = React.useState<DiffDataType[] | undefined>(undefined);
  const assetClassesSlice = useSelector((store: AppState) => store.financeData.assetClasses);
  const assetClasses = assetClassesSlice.data;
  const assetClassReturnsSlice = useSelector((store: AppState) => store.financeData.assetClassReturns);
  const assetClassReturns = assetClassReturnsSlice.data;
  const fundSlice = useSelector((store: AppState) => store.financeData.funds);
  const funds = fundSlice.data;
  const fundReturnsSlice = useSelector((store: AppState) => store.financeData.fundReturns);
  const fundReturns = fundReturnsSlice.data;
  const [previewTable, setPreviewTable] = React.useState(false);
  const [readingFile, setReadingFile] = React.useState(false);
  let reader = new FileReader();

  React.useEffect(() => {
    handleCleanUp();
  }, [assetClassOrFund]);

  const progress_cb = (value: number) => {
    setLoadingProgress(value);
  };

  const handleSuccesfullUpload = () => {
    setErrorMessage(undefined);
    setSuccessMessage("Successfully applied historic returns");
    if (assetClassOrFund === "AssetClass") dispatch(fetchAssetClassReturns());
    else if (assetClassOrFund === "Fund") dispatch(fetchFundReturns());
  };

  const handleCleanUp = () => {
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
    setSelectedFile(undefined);
    setDiffInXL(undefined);
    setPreviewTable(false);
  };

  const handleFailedUpload = () => {
    setSuccessMessage(undefined);
    setErrorMessage("Could not upload historic returns");
  };

  const handleCompleteUpload = () => {
    setUploadingReturns(false);
    setDiffInXL(undefined);
    setSelectedFile(undefined);
  };

  const handleReadFileStart = () => {
    setReadingFile(true);
    setDiffInXL(undefined);
    setPreviewTable(false);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
  };

  const handleParseSuccess = (res: ParsingReturnType, existingReturns: IAssetClassReturn[] | IFundReturn[]) => {
    setDiffInXL(undefined);

    checkDiffInXL(res.result, existingReturns, res.idList)
      .then(res => {
        setDiffInXL(res);
        setPreviewTable(true);
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
      })
      .catch(err => {
        setDiffInXL(undefined);
        setSuccessMessage(undefined);
        setErrorMessage(err);
      });
  };

  const handleParseFail = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setSuccessMessage(undefined);
  };

  const handleReadDataOrXLFail = () => {
    setErrorMessage("Could not read XL or historic returns data");
    setSuccessMessage(undefined);
  };

  const handleStartUpload = () => {
    setUploadingReturns(true);
    setPreviewTable(false);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleApplyChangesClick = () => {
    if (diffInXL && diffInXL.filter(data => data.status === "REMOVED").length !== 0) return setOpenConfirmationDialog(true);
    else return handleUpdateReturns();
  };

  const handleUpdateReturns = () => {
    if (diffInXL) {
      handleStartUpload();

      requestProgress(
        diffInXL
          .filter(data => data.status !== "NOT_CHANGED")
          .map(data => {
            if (data.status === "CHANGED" || data.status === "ADDED") {
              if (assetClassOrFund === "AssetClass") return updateAssetClassReturn(data.row);
              else return updateFundReturn(data.row);
            } else {
              if (assetClassOrFund === "AssetClass") return deleteAssetClassReturn(data.row.date);
              else return deleteFundReturn(data.row.date);
            }
          }),
        progress_cb
      )
        .then(res => {
          if (!res.find(result => result instanceof Error)) {
            handleSuccesfullUpload();
          } else {
            handleFailedUpload();
          }
        })
        .finally(() => {
          handleCompleteUpload();
        });
    } else {
      setSuccessMessage(undefined);
      setErrorMessage("There is no data to upload");
    }
  };

  const onDownloadClick = () => {
    if (assetClassOrFund === "AssetClass") {
      if (assetClassReturnsSlice.data && assetClassesSlice.data) {
        createXLFromData(assetClassReturnsSlice.data, assetClassesSlice.data, "Asset Class Return History");
      }
    } else if (assetClassOrFund === "Fund") {
      if (fundReturns && funds) {
        createXLFromData(
          fundReturns,
          funds.filter(f => !f.id.startsWith("index:")),
          "Fund Return History"
        );
      }
    }
  };

  const StyledDropzone = () => {
    const onDrop = useCallback((files: File[]) => {
      if (files.length > 0) {
        const file = files[0];

        setSelectedFile(file);
        reader.readAsArrayBuffer(file);

        reader.onloadstart = () => {
          handleReadFileStart();
        };

        reader.onloadend = () => {
          setReadingFile(false);
          let data: ArrayBuffer;
          if (reader.result !== null && typeof reader.result !== "string") {
            data = new Uint8Array(reader.result);
            if (assetClassOrFund === "AssetClass") {
              if (assetClasses && data && assetClassReturns) {
                parseXLToData(data, assetClasses, "Asset Class")
                  .then((res: ParsingReturnType) => {
                    handleParseSuccess(res, assetClassReturns);
                  })
                  .catch((err: string) => {
                    handleParseFail(err);
                  });
              } else {
                handleReadDataOrXLFail();
              }
            } else if (assetClassOrFund === "Fund") {
              if (funds && data && fundReturns) {
                parseXLToData(
                  data,
                  funds.filter(f => !f.id.startsWith("index:")),
                  "Fund"
                )
                  .then((res: ParsingReturnType) => {
                    handleParseSuccess(res, fundReturns);
                  })
                  .catch((err: string) => {
                    handleParseFail(err);
                  });
              } else {
                handleReadDataOrXLFail();
              }
            }
          }
        };
      }
    }, []);
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
      onDrop,
      multiple: false,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }),
      [isDragActive, isDragReject, isDragAccept]
    );

    return (
      <div>
        <div {...getRootProps({ style })}>
          <input id="drop" {...getInputProps()} />
          {isDragActive && isDragAccept && <p>Drop the file here ...</p>}
          {isDragReject && isDragActive && <p>Unsupported file type ...</p>}
          {!isDragActive && <p>Drag 'n' drop file here, or click to select file</p>}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Container style={{ marginBottom: "2em" }}>
        <h2>Historic returns for {assetClassOrFund === "AssetClass" ? "asset classes" : "funds"}</h2>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.title} variant="h6">
                    Download
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button size="small" className={classes.link} onClick={onDownloadClick} startIcon={<FileIcon />}>
                    <Typography variant="overline" align="center">
                      Download Excel file with history
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} variant="h6">
                Upload Excel file with historic returns for {assetClassOrFund === "AssetClass" ? "asset classes" : "funds"}
              </Typography>
              <Typography variant="subtitle1">First, download the file above and use as template. Then upload the new file in the same format.</Typography>

              <Grid container direction="column" spacing={1}>
                <Grid item xs={6}>
                  <StyledDropzone />
                </Grid>
                <Grid item xs={6}>
                  {errorMessage && (
                    <Zoom in={errorMessage !== undefined}>
                      <Typography noWrap className={classes.errorBox} variant="overline">
                        {errorMessage}
                      </Typography>
                    </Zoom>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    disabled={diffInXL === undefined || (diffInXL === undefined && !readingFile) || uploadingReturns}
                    onClick={() => handleApplyChangesClick()}
                    startIcon={<PublishIcon />}
                    className={classes.uploadButton}
                    variant="outlined"
                    component="label"
                  >
                    {uploadingReturns ? "Applying changes..." : "Apply these changes"}
                  </Button>
                  {selectedFile && !uploadingReturns && !readingFile && !errorMessage && (
                    <Typography noWrap style={{ marginLeft: 15 }} variant="overline">
                      {selectedFile.name}
                    </Typography>
                  )}
                  {!readingFile && !successMessage && !uploadingReturns && errorMessage && (
                    <Typography noWrap style={{ marginLeft: 15 }} variant="overline">
                      No files selected...
                    </Typography>
                  )}
                  {uploadingReturns && !readingFile && (
                    <Typography noWrap style={{ marginLeft: 15 }} variant="overline">
                      Uploading...{" " + loadingProgress}%
                    </Typography>
                  )}
                  {readingFile && (
                    <Typography noWrap style={{ marginLeft: 15 }} variant="overline">
                      Reading file...
                    </Typography>
                  )}
                  {successMessage && !readingFile && (
                    <Typography noWrap style={{ marginLeft: 15 }} variant="overline">
                      <span style={{ color: "#4caf50" }}>{successMessage}</span>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      {diffInXL && previewTable && assetClasses && assetClassReturns && funds && fundReturns && (
        <Paper className={classes.wrapper}>
          <h1 className={classes.tableTitle}>{assetClassOrFund === "AssetClass" ? "Asset class " : "Funds "} Return History</h1>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomLegend
                data={[
                  { name: "No changes", color: "white" },
                  { name: "Changed value", color: "#ffcc80" },
                  { name: "Added row", color: "#c8e6c9" },
                  { name: "Removed row", color: "#ffcdd2" }
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                Summary:
              </Typography>
              <Typography variant="overline" style={{ marginRight: 10 }}>
                Rows Changed: {diffInXL.filter(d => d.status === "CHANGED").length}
              </Typography>
              <Typography variant="overline" style={{ marginRight: 10 }}>
                Rows Added: {diffInXL.filter(d => d.status === "ADDED").length}
              </Typography>
              <Typography variant="overline" style={{ marginRight: 10 }}>
                Rows Removed: {diffInXL.filter(d => d.status === "REMOVED").length}
              </Typography>
            </Grid>
          </Grid>

          <Table size="small" className={classes.table} aria-labelledby="previewTable">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Date</TableCell>
                {assetClassOrFund === "AssetClass"
                  ? assetClasses.map((assetClass, index) => (
                      <Tooltip interactive title={<span style={{ fontSize: "1.5em" }}>{assetClass.name}</span>} placement="top" key={index}>
                        <TableCell className={classes.tableHeaderCell}>{shortenText(assetClass.name, 15)}</TableCell>
                      </Tooltip>
                    ))
                  : funds
                      .filter(f => !f.id.startsWith("index:"))
                      .map((fund, index) => (
                        <Tooltip interactive title={<span style={{ fontSize: "1.5em" }}>{fund.name}</span>} placement="top" key={index}>
                          <TableCell className={classes.tableHeaderCell}>{shortenText(fund.name, 15)}</TableCell>
                        </Tooltip>
                      ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ width: 900 }}>
              {diffInXL.map((rowData, i) => {
                let rowColor = "white";
                if (rowData.status === "ADDED") rowColor = "#c8e6c9";
                else if (rowData.status === "REMOVED") rowColor = "#ffcdd2";
                return (
                  <TableRow style={{ backgroundColor: rowColor }} key={i}>
                    <TableCell className={classes.tableCell}>{rowData.row.date}</TableCell>

                    {assetClassOrFund === "AssetClass"
                      ? assetClasses.map((key, index) => {
                          let cellColor = rowColor;
                          if (rowData.rowChanges) {
                            if (rowData.rowChanges.find(c => c.status === "CHANGED" && c.property === key.id)) cellColor = "#ffcc80";
                          }
                          return (
                            <Tooltip
                              interactive
                              title={rowData.row.returns[key.id] ? <span style={{ fontSize: "1.5em" }}>{rowData.row.returns[key.id]}</span> : ""}
                              placement="top"
                              key={index}
                            >
                              <TableCell style={{ backgroundColor: cellColor }} className={classes.tableCell}>
                                {rowData.row.returns[key.id]}
                              </TableCell>
                            </Tooltip>
                          );
                        })
                      : funds
                          .filter(f => !f.id.startsWith("index:"))
                          .map((key, index) => {
                            let cellColor = rowColor;
                            if (rowData.rowChanges) {
                              if (rowData.rowChanges.find(c => c.status === "CHANGED" && c.property === key.id)) cellColor = "#ffcc80";
                            }
                            return (
                              <Tooltip
                                interactive
                                title={rowData.row.returns[key.id] ? <span style={{ fontSize: "1.5em" }}>{rowData.row.returns[key.id]}</span> : ""}
                                placement="top"
                                key={index}
                              >
                                <TableCell style={{ backgroundColor: cellColor }} className={classes.tableCell}>
                                  {rowData.row.returns[key.id]}
                                </TableCell>
                              </Tooltip>
                            );
                          })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
      {openConfirmationDialog && (
        <ConfirmationDialog
          open={openConfirmationDialog}
          message="These changes will permanently remove data. Are you sure you want to apply them?"
          handleClose={handleCloseConfirmationDialog}
          handleConfirm={handleUpdateReturns}
        />
      )}
    </div>
  );
};
