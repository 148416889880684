var isArray = Array.isArray ? Array.isArray : function(value) {
    return Object.prototype.toString.call(value) === '[object Array]';
}

function generateLabelsFillWithBorder(chart) {
    var data = chart.data;
    return isArray(data.datasets) ? data.datasets.map(function (dataset, i) {
        let style = {
            datasetIndex: i,
            fillStyle: (!isArray(dataset.borderColor) ? dataset.borderColor : dataset.borderColor[0]),
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: 0,
            pointStyle: dataset.pointStyle,
            strokeStyle: dataset.borderColor,
            text: dataset.label,
        };
        return style;
    }, this) : [];
}

export {
    generateLabelsFillWithBorder,
};
