import { SimulationData } from "apiclient/responsePayloads/Simulation";
import { Dispatch } from "redux";
import SimulationsAPI from "apiclient/SimulationsAPI";
import { SavingState } from "./reducers";

/*
 * Action types
 */
export const SIMULATIONS_FETCH_START = 'simulations.SIMULATIONS_FETCH_START';
export const SIMULATIONS_FETCH_FAIL = 'simulations.SIMULATIONS_FETCH_FAIL';
export const SIMULATIONS_FETCH_SUCCESS = 'simulations.SIMULATIONS_FETCH_SUCCESS';
export const SIMULATIONS_SET_UNSAVED = 'simulations.SIMULATIONS_SET_UNSAVED'
export const SIMULATIONS_SET_SAVED = 'simulations.SIMULATIONS_SET_SAVED'
export const SIMULATIONS_SET_SAVING_STATE = 'simulations.SIMULATIONS_SET_SAVING_STATE'

interface SimulationsFetchStartAction {
  type: typeof SIMULATIONS_FETCH_START
}

interface SimulationsFetchFailAction {
  type: typeof SIMULATIONS_FETCH_FAIL
  message: string
}

interface SimulationsFetchSuccessAction {
  type: typeof SIMULATIONS_FETCH_SUCCESS
  simulations: SimulationData[]
}

interface SimulationsSetUnsaved {
  type: typeof SIMULATIONS_SET_UNSAVED
  simulation: SimulationData
}

interface SimulationsSetSaved {
  type: typeof SIMULATIONS_SET_SAVED
  simulation: SimulationData
}

interface SimulationsSetSavingState {
  type: typeof SIMULATIONS_SET_SAVING_STATE
  savingState?: SavingState
}

export type SimulationsAction = SimulationsFetchStartAction | SimulationsFetchFailAction |
  SimulationsFetchSuccessAction | SimulationsSetUnsaved |
  SimulationsSetSaved | SimulationsSetSavingState;

/*
 * Action creators
 */

export function simulationsFetchStart(): SimulationsFetchStartAction {
  return { type: SIMULATIONS_FETCH_START }
}

export function simulationsFetchFail(message: string): SimulationsFetchFailAction {
  return { type: SIMULATIONS_FETCH_FAIL, message }
}

export function simulationsFetchSuccess(simulations: SimulationData[]): SimulationsFetchSuccessAction {
  return { type: SIMULATIONS_FETCH_SUCCESS, simulations }
}

export function simulationsSetUnsaved(simulation: SimulationData): SimulationsSetUnsaved {
  return { type: SIMULATIONS_SET_UNSAVED, simulation }
}

export function simulationsSetSaved(simulation: SimulationData): SimulationsSetSaved {
  return { type: SIMULATIONS_SET_SAVED, simulation }
}

export function simulationsSetSavingState(savingState: SavingState): SimulationsSetSavingState {
  return { type: SIMULATIONS_SET_SAVING_STATE, savingState }
}

/*
 * Thunks
 */

function fetchSimulationsAndDispatch(dispatch: Dispatch, userId: string, onSuccess?: () => void, onError?: (err: Error) => void) {
  dispatch(simulationsFetchStart())

  SimulationsAPI.fetchSimulations(userId, (simulations) => {
    dispatch(simulationsFetchSuccess(simulations));
    if (onSuccess !== undefined) onSuccess();
  }, (err) => {
    console.log('Error fetching user simulations', err);
    dispatch(simulationsFetchFail('Error fetching simulations'))
    if (onError) onError(err);
  })
}

export function fetchSimulations(userId: string, onSuccess?: () => void) {
  return (dispatch: Dispatch) => {
    fetchSimulationsAndDispatch(dispatch, userId, onSuccess);
  }
}

export function createNewSimulation(userID: string, simulation: SimulationData, onSuccess?: (id: string) => void, onError?: (err: Error) => void) {
  return (dispatch: Dispatch) => {
    SimulationsAPI.createNewSimulation(
      simulation,
      (id: string) => {
        fetchSimulationsAndDispatch(
          dispatch,
          userID,
          () => {
            if (onSuccess) onSuccess(id);
          },
          (err: Error) => {
            if (onError) onError(err);
          }
        );
      },
      (err: any) => {
        if (err.response && err.response.status === 409) {
          alert("Failed to save simulation! Name already exist.");
        } else {
          alert("Failed to save simulation");
        }
        console.log("Failed to save simulation", err);
      }
    );
  };
}

export function updateSavedSimulation(simulation: SimulationData) {
  return (dispatch: Dispatch) => {
    const ownerId = simulation.ownerId
    if (ownerId === undefined) {
      return
    }
    dispatch(simulationsSetSavingState(SavingState.Saving));
    dispatch(simulationsSetSaved(simulation));
    const t0 = performance.now();
    const showSavingTime = 500.0; // Show "Saving..." label in milliseconds
    SimulationsAPI.updateSimulation(
      simulation,
      () => {
        fetchSimulationsAndDispatch(dispatch, ownerId);
        const t1 = performance.now();
        const elapsedTime = t1 - t0;
        const waitTime = Math.max(showSavingTime - elapsedTime, 0);
        setTimeout(() => {
          dispatch(simulationsSetSavingState(SavingState.Saved));
        }, waitTime)
      },
      (err: Error) => {
        dispatch(simulationsSetSavingState(SavingState.Failed));
        console.log("failed to update simulation:", err);
      }
    )
  }
}
