import React, { useState } from "react";
import { Paper, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider, Button } from "@material-ui/core";
import { Container } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SuccessIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Warning";
import { assetClassesStyles } from "./AssetClassesStyles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers";
import { EditAssetClass } from "./EditAssetClass";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { updateAssetClass, createAssetClass } from "admin/AssetClassesAPI";
import { fetchAssetClasses, fetchFunds } from "store/financedata/actions";
import { AddAssetClassDialog } from "./AddAssetClassDialog";

interface AssetClassesComponentProps {}

export const AssetClassesComponent: React.FC<AssetClassesComponentProps> = _ => {
  const classes = assetClassesStyles();
  const dispatch = useDispatch();
  const assetClassesSlice = useSelector((store: AppState) => store.financeData.assetClasses);
  const assetClasses = assetClassesSlice.data;
  const [assetClassToEdit, setAssetClassToEdit] = useState<AssetClassData | undefined>(undefined);
  const [createResult, setCreateResult] = useState<"Success" | "Fail" | undefined>(undefined);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setAssetClassToEdit(undefined);
  };

  const handleCreateAssetClass = (newAssetClass: AssetClassData) => {
    createAssetClass(newAssetClass)
      .then(res => {
        if (res instanceof Error) handleCreateFail();
        else handleCreateSuccess();
      })
      .catch((error: Error) => {
        handleCreateFail();
      });
  };

  const handleCreateSuccess = () => {
    dispatch(fetchAssetClasses());
    dispatch(fetchFunds());
    handleCreateStateChange("Success");
  };

  const handleCreateFail = () => {
    handleCreateStateChange("Fail");
  };

  const handleCreateStateChange = (state: "Success" | "Fail") => {
    setCreateResult(state);

    setTimeout(() => {
      setCreateResult(undefined);
    }, 3000);
  };

  const handleUpdateAssetClass = (assetClass: AssetClassData) => {
    updateAssetClass(assetClass)
      .then(() => dispatch(fetchAssetClasses()))
      .catch(err => console.log(err));
    setOpenEditDialog(false);
    setAssetClassToEdit(undefined);
  };

  const handleEditAssetClassClick = (assetClass: AssetClassData) => {
    setAssetClassToEdit(assetClass);
    setOpenEditDialog(true);
  };

  return (
    <div>
      <Container>
        <h2>Asset Classes</h2>
        <Paper className={classes.paper}>
          {assetClasses && (
            <List style={{ backgroundColor: "lightGrey", padding: "1px 1px 0 1px" }} component="div">
              {assetClasses.map((assetClass, index) => (
                <div key={index}>
                  <ListItem ContainerComponent="div" style={{ backgroundColor: "white" }}>
                    <ListItemIcon>
                      <IconButton onClick={() => handleEditAssetClassClick(assetClass)} edge="end">
                        <EditIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText primary={assetClass.name} secondary={assetClass.order} />
                  </ListItem>
                  <Divider component="div" />
                </div>
              ))}
            </List>
          )}
        </Paper>
        <Button
          variant="outlined"
          style={{ verticalAlign: "middle", lineHeight: "5px" }}
          startIcon={!createResult ? <AddIcon /> : createResult === "Success" ? <SuccessIcon /> : <ErrorIcon />}
          className={!createResult ? classes.normalButton : createResult === "Success" ? classes.successButton : classes.errorButton}
          onClick={() => setOpenAddDialog(true)}
        >
          {!createResult && "Create Asset Class"}
          {createResult === "Success" && "Created"}
          {createResult === "Fail" && "Failed Creating"}
        </Button>
      </Container>
      {openAddDialog && (
        <AddAssetClassDialog open={openAddDialog} handleClose={() => setOpenAddDialog(false)} handleCreateAssetClass={handleCreateAssetClass} />
      )}
      {openEditDialog && assetClassToEdit && assetClasses && (
        <EditAssetClass
          assetClass={assetClassToEdit}
          assetClasses={assetClasses}
          handleClose={handleCloseEditDialog}
          open={openEditDialog}
          handleUpdateAssetClass={handleUpdateAssetClass}
        />
      )}
    </div>
  );
};
