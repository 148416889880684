import * as React from "react";
import { SimulationData } from "apiclient/responsePayloads/Simulation";
import SavedSimulationWrapper, { ISimulationComponentProps } from "./SavedSimulationWrapper";
import { Route } from "react-router";
import { IAppLayoutProps } from "./App";

interface ISavedSimulationRouteProps {
    simulations?: SimulationData[];
    layoutProps: IAppLayoutProps;
    extraProps: any;
    // component: new (props: any) => React.Component<ISimulationComponentProps>
    component: new (props: any) => React.Component<ISimulationComponentProps>;
    path: string;
    onSetSavedSimulation: (s: SimulationData) => void;
}

/** Route that will load a saved simulation and pass it on to the component
 * once loaded, as well as ensure the user is logged in.
 */
export const SavedSimulationRoute: React.FC<ISavedSimulationRouteProps> = (props) => (
    <Route
        exact={true}
        path={props.path}
        render={(innerProps: any) => {
            const simulationId = innerProps.match.params.id || '';
            const lang = innerProps.match.params.lang || '';
            return (
                <SavedSimulationWrapper
                    component={props.component}
                    layoutProps={props.layoutProps}
                    extraProps={props.extraProps}
                    simulations={props.simulations}
                    simulationId={simulationId}
                    lang={lang}
                    location={innerProps.location}
                    onSetSavedSimulation={props.onSetSavedSimulation}
                />
            );
        }}
    />
);
