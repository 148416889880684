import React from "react";
import { Container } from "semantic-ui-react";

const AdminIndex = () => (
  <Container text={true}>
    <h3>Frigg Admin</h3>
    <p>Use the menu above to navigate to what you want to administrate.</p>
  </Container>
);
export default AdminIndex;
