import { roundTwoDecimals } from "util/misc-utils";
import { graphColors } from "util/colors";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import Allocation from "business/Allocation";
import Funds from "apiclient/Funds";

export function buildData(labels: string[], weights: number[]): Chart.ChartData {
  const data: Chart.ChartData = {
    labels,
    datasets: [
      {
        data: weights,
        backgroundColor: graphColors,
        hoverBackgroundColor: graphColors,
        borderWidth: 1
      }
    ]
  };
  return data;
}

export function buildOptions(labels: string[]): Chart.ChartOptions {
  const chartOpts: Chart.ChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) => {
          const dataset = data.datasets![tooltipItem.datasetIndex!];
          const currentValue = dataset.data![tooltipItem.index!] as number;
          const precentage = roundTwoDecimals(currentValue);
          return labels[tooltipItem.index!] + " (" + precentage + "%)";
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: false
          }
        }
      ]
    }
  };
  return chartOpts;
}

export function makeAssetClassesLabelsAndWeights(portfolio: AssetClassPortfolio): [string[], number[]] {
  const filteredAllocation = portfolio.allocations.filter((component: Allocation) => component.weightInPercent > 0);
  return [
    filteredAllocation.map((component: Allocation) => component.getTranslatedName()),
    filteredAllocation.map((component: Allocation) => component.weightInPercent)
  ];
}

export function makeFundsLabelsAndWeights(portfolio: AssetClassPortfolio, funds: Funds): [string[], number[]] {
  const labels: string[] = [];
  const weights: number[] = [];
  const fundAllocation = portfolio.getFundAllocation();
  Object.keys(fundAllocation.funds).forEach(fundID => {
    if (fundAllocation.funds[fundID] > 0) {
      labels.push(funds.getFundName(fundID));
      weights.push(fundAllocation.funds[fundID]);
    }
  });
  return [labels, weights];
}
