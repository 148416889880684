import React from 'react';
import { Grid } from 'semantic-ui-react';

import { DesktopOrTablet, Mobile } from 'components/util/responsive';

import './JoinedForwardSimulationNumbersPane.css'

interface IJoinedForwardSimulationNumbersPaneProps {
    investedAmount: number
    expectedAmountA: number
    lowAmountA: number
    highAmountA: number
    expectedAmountB: number
    lowAmountB: number
    highAmountB: number
    year: number
}

export default function JoinedForwardSimulationNumbersPane(props: IJoinedForwardSimulationNumbersPaneProps) {
    const makeYearValueColumn = (amountA: number, amountB:number, year: number, txt: string, addAsterisk: boolean): JSX.Element => {
        if (amountA === null || amountB === null || year === null) {
            return <Grid.Column />
        }
        const asterisk = (addAsterisk === true) ? <sup>*</sup> : '';
        return (
            <Grid.Column>
                <span className={'big-value'}>A {Math.round(amountA)}</span><span className={'big-value secondary'}>B {Math.round(amountB)}</span>
                {txt}{asterisk}
            </Grid.Column>
        );
    }

    return (
        <Grid textAlign='center' className='JoinedForwardSimulationNumbersPane tight'>
            <DesktopOrTablet>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <span className={'big-value'}>A {props.investedAmount}</span><span className={'big-value secondary'}>B {props.investedAmount}</span>
                        Invested amount
                    </Grid.Column>
                    {makeYearValueColumn(props.expectedAmountA, props.expectedAmountB, props.year, `Expected value year ${props.year}`, false)}
                    {makeYearValueColumn(props.lowAmountA, props.lowAmountB, props.year, `Worst outcome year ${props.year}`, true)}
                    {makeYearValueColumn(props.highAmountA, props.highAmountB, props.year, `Best outcome year ${props.year}`, true)}
                </Grid.Row>
            </DesktopOrTablet>
            <Mobile>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <span className={'big-value'}>A {props.investedAmount}</span><span className={'big-value secondary'}>B {props.investedAmount}</span>
                    </Grid.Column>
                    {makeYearValueColumn(props.expectedAmountA, props.expectedAmountB, props.year, `Expected value year ${props.year}`, false)}
                </Grid.Row>
                <Grid.Row columns={2}>
                    {makeYearValueColumn(props.lowAmountA,props.lowAmountB, props.year, `Worst outcome year ${props.year}`, true)}
                    {makeYearValueColumn(props.highAmountA, props.highAmountB, props.year, `Best outcome year ${props.year}`, true)}
                </Grid.Row>
            </Mobile>
        </Grid>
    )
}
