import { AssetClassData } from 'apiclient/responsePayloads/AssetClass'

export default class Allocation {
    public readonly assetClass: AssetClassData
    public readonly normalizedWeightInPercent: number
    public readonly weightInPercent: number

    constructor(assetClass: AssetClassData, weightInPercent: number, totalWeight: number) {
        this.assetClass = assetClass
        this.normalizedWeightInPercent = (weightInPercent / totalWeight) * 100.0
        this.weightInPercent = weightInPercent
    }

    public getTranslatedName(): string {
        return this.assetClass.name;
    }
}
