import * as React from "react";

import "./DisclaimerFooter.css";

export const DisclaimerFooter: React.FC = () => (
  <div className="DisclaimerFooter">
    Historical returns are no guarantee of future returns. Money invested in the fund may both increase or decrease in value and there is no
    guarantee that you will be repaid all of your invested capital.
  </div>
);
