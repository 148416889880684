import { primaryPortfolioColor, secondaryPortfolioColor } from 'util/colors';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import { AssetClassReturns } from 'apiclient/AssetClassReturns';
import { FundReturns } from 'apiclient/FundReturns';
import DateReturn from 'business/DateReturn';
import { generateLabelsFillWithBorder } from 'util/chart-utils';

function calculateCumulativeDataPoints(initialInvestment: number, dateReturns: DateReturn[]): Chart.ChartPoint[] {
    const points: Chart.ChartPoint[] = [];
    let cumulativeAmount = initialInvestment;
    for (const dateReturn of dateReturns) {
        cumulativeAmount = cumulativeAmount * (1.0 + dateReturn.returnOfInvestment)
        points.push({
            x: dateReturn.date,
            y: cumulativeAmount,
        });
    }
    return points;
}

function buildHistoricReturnGraphData(portfolio: AssetClassPortfolio, comparisonPortfolio: AssetClassPortfolio | undefined, assetClassReturns: AssetClassReturns, fundReturns?: FundReturns): Chart.ChartData {
    const data: Chart.ChartData = {}
    data.datasets = []

    const isFunds = fundReturns !== undefined
    const labelSuffix = (comparisonPortfolio ? ` (Portfolio A)` : '');
    const label = isFunds ? 'Fund returns' : 'Asset class returns'
    const history = portfolio.calculateReturnHistory(assetClassReturns, fundReturns)
    data.datasets.push({
        label: label + labelSuffix,
        backgroundColor: primaryPortfolioColor,
        borderColor: primaryPortfolioColor,
        fill: false,
        data: calculateCumulativeDataPoints(100, history),
    });

    if (comparisonPortfolio) {
        const comparisonHistory = comparisonPortfolio.calculateReturnHistory(assetClassReturns, fundReturns)
        data.datasets.push({
            label: label + ` (Portfolio B)`,
            backgroundColor: secondaryPortfolioColor,
            borderColor: secondaryPortfolioColor,
            fill: false,
            data: calculateCumulativeDataPoints(100, comparisonHistory),
        });
    }

    return data;
}

function buildChartOptions(): Chart.ChartOptions {
    return {
        responsive: true,
        tooltips: {
            mode: 'index',
            intersect: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                generateLabels: generateLabelsFillWithBorder,
            }
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            xAxes: [{
                type: "time",
                display: true
            }],
            yAxes: [{
                display: true,
            }]
        }
    };
}

export {
    buildHistoricReturnGraphData,
    buildChartOptions
}