import _ from 'lodash';
import IStringNumberMap from 'util/StringNumberMap';
import { copyObject } from 'util/misc-utils';

const preProgrammedAssetWeightsList: IStringNumberMap[] = [
    {
        "tbills": 20,
        "tbonds": 30,
        "credits-inv-grade": 15,
        "credits-high-yield": 0,
        "swedish-equitites": 5,
        "global-equities": 15,
        "private-equity": 0,
        "em-equities": 0,
        "hedge-funds": 10,
        "infrastructure": 5,
        "commodities": 0,
        "real-estate": 0
    },
    {
        "tbills": 16,
        "tbonds": 24,
        "credits-inv-grade": 12,
        "credits-high-yield": 0,
        "swedish-equitites": 8,
        "global-equities": 19,
        "private-equity": 4,
        "em-equities": 2,
        "hedge-funds": 8,
        "infrastructure": 6,
        "commodities": 0,
        "real-estate": 1
    },
    {
        "tbills": 12,
        "tbonds": 18,
        "credits-inv-grade": 9,
        "credits-high-yield": 0,
        "swedish-equitites": 11,
        "global-equities": 23,
        "private-equity": 8,
        "em-equities": 4,
        "hedge-funds": 6,
        "infrastructure": 7,
        "commodities": 0,
        "real-estate": 2
    },
    {
        "tbills": 7,
        "tbonds": 10,
        "credits-inv-grade": 5,
        "credits-high-yield": 0,
        "swedish-equitites": 15,
        "global-equities": 28,
        "private-equity": 14,
        "em-equities": 7,
        "hedge-funds": 3,
        "infrastructure": 8,
        "commodities": 0,
        "real-estate": 3
    },
    {
        "tbills": 0,
        "tbonds": 0,
        "credits-inv-grade": 0,
        "credits-high-yield": 0,
        "swedish-equitites": 20,
        "global-equities": 35,
        "private-equity": 20,
        "em-equities": 10,
        "hedge-funds": 0,
        "infrastructure": 10,
        "commodities": 0,
        "real-estate": 5
    }
];

const totalNumberOfPreProgrammedPortfolios = preProgrammedAssetWeightsList.length

class AssetWeights {
    public readonly data: IStringNumberMap

    constructor(data: IStringNumberMap) {
        data = _.mapValues(data, w => Number(w))
        this.data = data
    }

    public get(id: string): number {
        return this.data[id]
    }

    public total(): number {
        return _.sum(_.values(this.data))
    }

    public isValid(): boolean {
        const totalWeights = this.total()
        return (Object.keys(this.data).length > 0) && ((totalWeights === 100) || (totalWeights === 0))
    }

    public updateWith(id: string, newValue: number): AssetWeights {
        const updatedData = copyObject(this.data)
        updatedData[id] = newValue
        return new AssetWeights(updatedData)
    }
}

function getPreProgrammedAssetWeights(index: number): AssetWeights {
    return new AssetWeights(preProgrammedAssetWeightsList[index])
}

function getAllPreProgrammedAssetWeights(): AssetWeights[] {
    return preProgrammedAssetWeightsList.map(w => new AssetWeights(w))
}

export {
    getPreProgrammedAssetWeights,
    getAllPreProgrammedAssetWeights,
    AssetWeights,
    totalNumberOfPreProgrammedPortfolios
}