import { primaryPortfolioColor, secondaryPortfolioColor, transparentColor } from 'util/colors';
import { generateLabelsFillWithBorder } from 'util/chart-utils';
import { getPortfolioName } from 'util/misc-utils';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import { AssetClassReturns } from 'apiclient/AssetClassReturns';
import DateReturn from 'business/DateReturn';
import { FundReturns } from 'apiclient/FundReturns';

function buildDrawdownData(returnHistory: DateReturn[]) {
    let cumulativeValue = 100.0;
    let maxSoFar = cumulativeValue;

    return returnHistory.map((row: DateReturn) => {
        cumulativeValue = cumulativeValue * (1 + row.returnOfInvestment);
        maxSoFar = Math.max(maxSoFar, cumulativeValue);
        const drawDown = (cumulativeValue / maxSoFar) - 1;

        return {
            x: row.date,
            y: drawDown,
        }
    });
}

function buildDrawdownDataset(assetClassReturns: AssetClassReturns, fundReturns: FundReturns | undefined, label: string, portfolio: AssetClassPortfolio, color: string): Chart.ChartDataSets {
    return {
        label,
        backgroundColor: transparentColor(color, 0.25),
        borderColor: color,
        fill: 'origin',
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        lineTension: .1,
        data: buildDrawdownData(portfolio.calculateReturnHistory(assetClassReturns, fundReturns)),
    }
}

function buildDrawdownChartData(portfolio: AssetClassPortfolio, comparisonPortfolio: AssetClassPortfolio | undefined, assetClassReturns: AssetClassReturns, fundReturns?: FundReturns): Chart.ChartData {
    const data = {
        datasets: [
            buildDrawdownDataset(assetClassReturns, fundReturns, getPortfolioName('A', !!comparisonPortfolio), portfolio, primaryPortfolioColor),
        ]
    };
    if (!!comparisonPortfolio) {
        data.datasets.push(
            buildDrawdownDataset(assetClassReturns, fundReturns, getPortfolioName('B', !!comparisonPortfolio), comparisonPortfolio, secondaryPortfolioColor)
        );
    }
    return data;
}

function buildChartOptions(): Chart.ChartOptions {
    return {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                generateLabels: generateLabelsFillWithBorder,
            }
        },
        scales: {
            xAxes: [{
                type: "time",
                display: true
            }],
            yAxes: [{
                display: true,
            }]
        },
        responsive: true,
    };
}

export {
    buildDrawdownData,
    buildDrawdownChartData,
    buildChartOptions
}