import * as React from "react";
import { Container } from "semantic-ui-react";

import { PortfolioContentChart } from "components/charts/PortfolioContentChart/PortfolioContentChart";
import { HistoricReturnGraph } from "components/charts/HistoricReturnGraph/HistoricReturnGraph";
import { FundReturns } from "apiclient/FundReturns";
import { DrawdownChart } from "components/charts/DrawdownChart/DrawdownChart";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import { AssetClasses } from "apiclient/AssetClasses";
import Funds from "apiclient/Funds";
import { Simulation } from "apiclient/responsePayloads/Simulation";

import "./FundAllocationReportSection.css";

interface FundAllocationReportSectionProps {
  simulation: Simulation;
  assetClasses: AssetClasses;
  assetClassReturns: AssetClassReturns;
  fundReturns: FundReturns;
  funds: Funds;
}

export const FundAllocationReportSection: React.SFC<FundAllocationReportSectionProps> = props => {
  const { simulation, assetClasses, assetClassReturns, fundReturns, funds } = props;
  const portfolio = simulation.portfolioA(assetClasses);
  return (
    <div className="FundAllocationReportSection">
      <Container>
        <h1>Fund Allocation</h1>
        <PortfolioContentChart portfolio={portfolio} withFunds={funds} />
        <HistoricReturnGraph portfolio={portfolio} assetClassReturns={assetClassReturns} fundReturns={fundReturns} />
        <DrawdownChart portfolio={portfolio} assetClassReturns={assetClassReturns} fundReturns={fundReturns} />
      </Container>
    </div>
  );
};
