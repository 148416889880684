import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import MainLayout from 'components/App/MainLayout';
import { IRouterMatchProps } from 'util/RouterPropsInterface';
import { CustomPortfolioModal, ModalData } from 'components/CustomPortfolioModal/CustomPortfolioModal';
import { SimulationCharts } from './SimulationCharts';
import DoubleSuggesterPortfolioSelector from 'components/DoubleSuggesterPortfolioSelector/DoubleSuggesterPortfolioSelector';
import { AssetClasses } from 'apiclient/AssetClasses';
import { Simulation, SimulationData } from 'apiclient/responsePayloads/Simulation';
import { AssetWeights } from 'business/AssetWeights';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import { NavigationStep, ProcessNavPageHeader, ProcessNavPageFooter } from 'components/ProcessNavigator/ProcessNavigator';
import { useAssetClasses, useAssetClassReturns } from 'store/financedata/helpers';
import Spinner from 'util/Spinner';
import { getFundsURL, getRiskProfileURL, getReportURL } from 'urls';
import { SavingState } from 'store/simulations/reducers';
import { REACT_APP_FUNDS_ON } from 'components/App/isFundsOn';
import { IAppLayoutProps } from 'components/App/App';

import './SimulationPage.css';
import { SectionWrap } from 'components/SectionWrap/SectionWrap';

interface ISimulationPageProps extends RouteComponentProps<IRouterMatchProps> {
  simulation: Simulation;
  savingState?: SavingState;
  onSimulationChange(simulation: SimulationData): void;
  layoutProps: IAppLayoutProps;
}

const SimulationPage: React.FC<ISimulationPageProps> = (props) => {
  const [portfModalAVisible, setPortfModalAVisible] = React.useState(false);
  const [portfModalBVisible, setPortfModalBVisible] = React.useState(false);
  const assetClasses = useAssetClasses();
  const assetClassReturns = useAssetClassReturns();

  const riskProfileURL = getRiskProfileURL(props.simulation);
  const fundsURL = getFundsURL(props.simulation);
  const reportURL = getReportURL(props.simulation);

  const layoutPropsWithTitle = { ...props.layoutProps, title: 'Asset allocation simulation' };

  if (props.simulation.riskLevel === undefined) {
    props.history.push(riskProfileURL);
  }

  if (assetClasses === undefined || assetClassReturns === undefined) {
    return <Spinner />;
  }

  const suggestedPortfolioNo = props.simulation.getSuggestedPortfolioNumber();
  const suggestedPortfolioIndex = suggestedPortfolioNo !== undefined ? suggestedPortfolioNo - 1 : undefined;
  const portfolioA = props.simulation.portfolioA(assetClasses);
  const portfolioB = props.simulation.portfolioB(assetClasses);

  function doubleSuggesterPortfolioContainer(
    portfolioA: AssetClassPortfolio,
    portfolioB: AssetClassPortfolio,
    suggestedPortfolioIndex: number | undefined,
    simulation: Simulation,
    ac: AssetClasses
  ) {
    return (
      <SectionWrap toned>
        <DoubleSuggesterPortfolioSelector
          portfolio={portfolioA}
          comparisonPortfolio={portfolioB}
          suggestedIndex={suggestedPortfolioIndex}
          activeIndex={portfolioA.index! - 1}
          isCustom={portfolioA.isCustom}
          onChangePortfolioA={(portfolioIndex: number) => {
            const newSim = simulation.clone();
            newSim.updatePortfolioAWithNumber(portfolioIndex + 1, ac);
            props.onSimulationChange(newSim.getSimulationData());
          }}
          onChooseCustomPortfolioA={() => setPortfModalAVisible(true)}
          suggestedComparisonIndex={suggestedPortfolioIndex}
          activeComparisonIndex={portfolioB.index! - 1}
          isCustomComparison={portfolioB.isCustom}
          onChangePortfolioB={(portfolioIndex: number) => {
            const newSim = simulation.clone();
            newSim.updatePortfolioBWithNumber(portfolioIndex + 1, ac);
            props.onSimulationChange(newSim.getSimulationData());
          }}
          onChooseCustomPortfolioB={() => setPortfModalBVisible(true)}
        />
      </SectionWrap>
    );
  }

  function renderCustomPortfolioModal(portfolioA: AssetClassPortfolio, portfolioB: AssetClassPortfolio, ac: AssetClasses) {
    return [
      <CustomPortfolioModal
        key={1}
        visible={portfModalAVisible}
        onClose={() => setPortfModalAVisible(false)}
        onSave={(weights) => handleSaveNewCustomPortfolioA(weights, ac)}
        data={
          new ModalData(
            portfolioA.allocations.map((x) => x.assetClass.id),
            portfolioA.allocations.map((x) => x.assetClass.name),
            portfolioA.weights
          )
        }
        totalWeights={100}
        warningText="The total does not add up 100%. Adjust your allocation so that your portfolio adds up to 100% before saving."
        title="Portfolio A"
      />,

      <CustomPortfolioModal
        key={2}
        visible={portfModalBVisible}
        onClose={() => setPortfModalBVisible(false)}
        onSave={(weights) => handleSaveNewCustomPortfolioB(weights, ac)}
        data={
          new ModalData(
            portfolioB.allocations.map((x) => x.assetClass.id),
            portfolioB.allocations.map((x) => x.assetClass.name),
            portfolioB.weights
          )
        }
        totalWeights={100}
        warningText="The total does not add up 100%. Adjust your allocation so that your portfolio adds up to 100% before saving."
        title="Portfolio B"
      />,
    ];
  }

  function handleSaveNewCustomPortfolioA(weights: AssetWeights, ac: AssetClasses) {
    const newSim = props.simulation.clone();
    newSim.updatePortfolioAWithWeights(weights, ac);
    props.onSimulationChange(newSim.getSimulationData());
    setPortfModalAVisible(false);
  }

  function handleSaveNewCustomPortfolioB(weights: AssetWeights, ac: AssetClasses) {
    const newSim = props.simulation.clone();
    newSim.updatePortfolioBWithWeights(weights, ac);
    props.onSimulationChange(newSim.getSimulationData());
    setPortfModalBVisible(false);
  }

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="SimulationPage">
        <div className="portfolio-simulation" id="portfolio-simulation">
          <ProcessNavPageHeader
            step={NavigationStep.Simulation}
            backUrl={riskProfileURL}
            nextUrl={REACT_APP_FUNDS_ON ? fundsURL : reportURL}
            simulation={props.simulation}
            onLogout={props.layoutProps.onLogout}
            onNewSimulation={props.layoutProps.onNewSimulation}
            savingState={props.savingState}
          />

          {props.simulation.hasRiskLevel() &&
            doubleSuggesterPortfolioContainer(portfolioA, portfolioB, suggestedPortfolioIndex, props.simulation, assetClasses)}
          {renderCustomPortfolioModal(portfolioA, portfolioB, assetClasses)}
          <SimulationCharts
            portfolioA={portfolioA}
            portfolioB={portfolioB}
            scaleFactorContribution={props.simulation.scaleFactorContribution}
            onScaleFactorContributionChange={(newContribution: number) => {
              const newSimulation: SimulationData = { ...props.simulation.getSimulationData() };
              newSimulation.scaleFactorContribution = newContribution;
              props.onSimulationChange(newSimulation);
            }}
            assetClassReturns={assetClassReturns}
          />
          <div style={{ textAlign: 'right' }}>
            <ProcessNavPageFooter
              step={NavigationStep.Simulation}
              backUrl={riskProfileURL}
              onNewSimulation={props.layoutProps.onNewSimulation}
              nextUrl={REACT_APP_FUNDS_ON ? fundsURL : reportURL}
              simulation={props.simulation}
              onLogout={props.layoutProps.onLogout}
            />
          </div>
          {/*<Container style={{ marginTop: '20px' }}>
            <DisclaimerFooter />
        </Container>*/}
        </div>
      </div>
    </MainLayout>
  );
};
export default withRouter(SimulationPage);
