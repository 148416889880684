import { combineReducers } from "redux";
import { AppAction } from "./actions";
import { reduceProfile, ProfileSliceState } from "./profile/reducers";
import { reduceSimulations, SimulationsSliceState } from "./simulations/reducers";
import { FinanceDataSliceState, reduceFinanceData } from "./financedata/reducers";

export interface AppState {
  profile: ProfileSliceState,
  simulations: SimulationsSliceState,
  financeData: FinanceDataSliceState,
}

export const reduceApp = combineReducers<AppState, AppAction>({
  profile: reduceProfile,
  simulations: reduceSimulations,
  financeData: reduceFinanceData,
})
