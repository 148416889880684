import React from "react";
import MediaQuery from "react-responsive";

const Desktop = (props) => <MediaQuery {...props} minWidth={1024} />;
const DesktopOrTablet = (props) => <MediaQuery {...props} minWidth={768} />;
const Mobile = (props) => <MediaQuery {...props} maxWidth={767} />;
const MobileOrTablet = (props) => <MediaQuery {...props} maxWidth={1023} />;
const Tablet = (props) => <MediaQuery {...props} minWidth={768} maxWidth={1023} />;

export { Desktop, DesktopOrTablet, Mobile, MobileOrTablet, Tablet };
