import React from "react";
import { IRouterProps } from "util/RouterPropsInterface";
import { Container } from "semantic-ui-react";
import { UserDetailsForm } from "./UserDetailsForm";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import Spinner from "util/Spinner";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers";
import { ProfileSliceState } from "store/profile/reducers";
import { profileSave, changePassword } from "store/profile/actions";
import { IAppLayoutProps } from "components/App/App";
import MainLayout from "components/App/MainLayout";

import "./AccountPage.css";

interface Props {
  layoutProps: IAppLayoutProps;
}

export const AccountPage: React.FC<Props & IRouterProps> = props => {
  const profile = useSelector<AppState, ProfileSliceState>(state => state.profile);
  const { user } = profile;
  const dispatch = useDispatch();
  const layoutPropsWithTitle = { ...props.layoutProps, title: "My Account" };

  if (user === undefined) {
    return <Spinner />;
  }

  const handleProfileSaveClicked = (firstName: string, lastName: string, organization: string) => {
    dispatch(profileSave(firstName, lastName, organization));
  };

  const handlePasswordChangeClicked = (currentPassword: string, newPassword: string) => {
    dispatch(changePassword(currentPassword, newPassword));
  };

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="AccountPage">
        <SectionWrap toned={true} className="huge-padding-top">
          <Container text={true}>
            <h1>User Details</h1>
            <UserDetailsForm
              user={user}
              onSaveClicked={handleProfileSaveClicked}
              isSaving={profile.isSaving}
              saveHasErrored={profile.saveHasErrored}
              justSaved={profile.justSaved}
            />

            <h1>Change Password</h1>
            <ChangePasswordForm
              passwordIsChanging={profile.passwordIsChanging}
              passwordJustChanged={profile.passwordJustChanged}
              passwordChangeError={profile.passwordChangeError}
              onChangeClicked={handlePasswordChangeClicked}
            />
          </Container>
        </SectionWrap>
      </div>
    </MainLayout>
  );
};
