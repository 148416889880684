import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import _ from "lodash";
import { HelpPopupIcon } from "components/HelpPopupIcon/HelpPopupIcon";
import { percentageTooltipX } from "util/misc-utils";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { buildRiskAllocationChartData } from "./RiskAllocationChartDataBuilders";

interface IRiskAllocationChartProps {
  portfolio: AssetClassPortfolio;
  showDisclaimerIcon?: boolean;
  titleSuffix?: string;
}

export function RiskAllocationChart({ portfolio, showDisclaimerIcon, titleSuffix }: IRiskAllocationChartProps) {
  const opts: any = {
    legend: {
      display: true,
      position: "bottom"
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Share of portfolio (%)"
          }
        }
      ]
    },
    responsive: true
  };
  _.set(opts, "tooltips.callbacks.label", percentageTooltipX);

  const data = buildRiskAllocationChartData(portfolio);

  return (
    <div className="RiskAllocationChart chart-section">
      {showDisclaimerIcon && (
        <HelpPopupIcon style={{ float: "right", marginBottom: 10 }}>
          The graph displays the allocation of capital in contrast to the allocation of risk (as a share of total portfolio risk) for each
          asset class. How much an asset class contributes to total portfolio risk varies with its absolute risk (standard deviation) and
          its co-variation with the other asset classes in the portfolio.
        </HelpPopupIcon>
      )}
      <h3 className="header-first-child">
        {"Risk & Return Allocation"} {titleSuffix}
      </h3>

      <div>
        <HorizontalBar data={data} options={opts} />
      </div>
    </div>
  );
}
