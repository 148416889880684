import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { Simulation, SimulationData } from 'apiclient/responsePayloads/Simulation';
import { IRouterProps } from 'util/RouterPropsInterface';
import { FundsCharts } from './FundsCharts';
import { NavigationStep, ProcessNavPageHeader, ProcessNavPageFooter } from 'components/ProcessNavigator/ProcessNavigator';
import { AssetWeights } from 'business/AssetWeights';
import { CustomPortfolioModal } from 'components/CustomPortfolioModal/CustomPortfolioModal';
import { ModalData } from 'components/CustomPortfolioModal/CustomPortfolioModal';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import { FundAllocation } from 'business/FundAllocation';
import { useAssetClasses, useAssetClassReturns, useFundReturns, useFunds } from 'store/financedata/helpers';
import { getSimulationURL, getReportURL, getRiskProfileURL } from 'urls';
import { SavingState } from 'store/simulations/reducers';
import Spinner from 'util/Spinner';
import { AppState } from 'store/reducers';
import { connect } from 'react-redux';
import { AssetClassData } from 'apiclient/responsePayloads/AssetClass';
import { IAppLayoutProps } from 'components/App/App';
import MainLayout from 'components/App/MainLayout';

import './FundsPage.css';

interface IFundsPageProps extends RouteComponentProps<IRouterProps> {
  simulation: Simulation;
  savingState?: SavingState;
  assetClasses: AssetClassData[] | undefined;
  onSimulationChange(simulation: SimulationData): void;
  layoutProps: IAppLayoutProps;
}

enum SimulationPortfolio {
  A,
  B,
}

interface CustomFundAllocationModalInfo {
  modalData: ModalData;
  portfolio: AssetClassPortfolio;
  assetClassID: string;
  whichPortfolio: SimulationPortfolio;
}

const FundsPage: React.FC<IFundsPageProps> = (props) => {
  const [portfModalInfo, setPortfModalInfo] = React.useState<CustomFundAllocationModalInfo | null>(null);
  const assetClasses = useAssetClasses();
  const assetClassReturns = useAssetClassReturns();
  const fundReturns = useFundReturns();
  const funds = useFunds();
  const layoutPropsWithTitle = { ...props.layoutProps, title: 'Fund Allocation simulation' };

  if (props.simulation.riskLevel === undefined) {
    props.history.push(getRiskProfileURL(props.simulation));
  }

  if (assetClasses === undefined || assetClassReturns === undefined || fundReturns === undefined || funds === undefined) {
    return <Spinner />;
  }

  const portfolioA = props.simulation.portfolioA(assetClasses);
  const portfolioB = props.simulation.portfolioB(assetClasses);

  const handleSaveNewCustomPortfolio = (weights: AssetWeights, modalInfo: CustomFundAllocationModalInfo) => {
    const newSim = props.simulation.clone();
    const fundAllocation = new FundAllocation(weights.data);
    if (modalInfo.whichPortfolio === SimulationPortfolio.A) {
      newSim.updatePortfolioAWithCustomFundAllocation(modalInfo.assetClassID, fundAllocation);
    } else {
      newSim.updatePortfolioBWithCustomFundAllocation(modalInfo.assetClassID, fundAllocation);
    }
    props.onSimulationChange(newSim.getSimulationData());
    setPortfModalInfo(null);
  };

  const handleSwitchAllocation = (portfolio: AssetClassPortfolio, splitAmong: 'Indexes' | 'Funds') => {
    const newSim = props.simulation.clone();
    const allAssetClasses = props.assetClasses;

    if (allAssetClasses) {
      allAssetClasses.forEach((ac) => {
        const allocations = portfolio.allocations.filter((x) => x.assetClass.id === ac.id);
        const allocation = allocations[0];
        const fundAllocation = portfolio.getFundAllocationForAssetClass(ac, allocation.normalizedWeightInPercent, splitAmong);

        if (portfolio === portfolioA) {
          newSim.updatePortfolioAWithCustomFundAllocation(ac.id, fundAllocation);
        } else {
          newSim.updatePortfolioBWithCustomFundAllocation(ac.id, fundAllocation);
        }
      });
    }
    props.onSimulationChange(newSim.getSimulationData());
    setPortfModalInfo(null);
  };

  function renderCustomPortfolioModal(allocationModalInfo: CustomFundAllocationModalInfo) {
    return [
      <CustomPortfolioModal
        key={1}
        visible={true}
        onClose={() => setPortfModalInfo(null)}
        onSave={(weights: AssetWeights) => handleSaveNewCustomPortfolio(weights, allocationModalInfo)}
        data={allocationModalInfo.modalData}
        totalWeights={allocationModalInfo.modalData.totalWeights()}
        warningText="The sum of fund weights must equal the chosen strategic allocation of the asset class."
        title={allocationModalInfo.modalData.title || ''}
      />,
    ];
  }

  return (
    <MainLayout {...layoutPropsWithTitle}>
      <div className="FundsPage">
        <ProcessNavPageHeader
          onNewSimulation={props.layoutProps.onNewSimulation}
          step={NavigationStep.Funds}
          simulation={props.simulation}
          backUrl={getSimulationURL(props.simulation)}
          nextUrl={getReportURL(props.simulation)}
          onLogout={props.layoutProps.onLogout}
          savingState={props.savingState}
        />

        <FundsCharts
          portfolioA={portfolioA}
          portfolioB={portfolioB}
          funds={funds}
          onSwitch={(portfolio: AssetClassPortfolio, splitAmong: 'Indexes' | 'Funds') => handleSwitchAllocation(portfolio, splitAmong)}
          assetClasses={props.assetClasses}
          assetClassReturns={assetClassReturns}
          fundReturns={fundReturns}
          onChooseCustomFundAllocation={(data: ModalData, forAssetClassID: string, portfolio: AssetClassPortfolio) =>
            setPortfModalInfo({
              modalData: data,
              portfolio,
              assetClassID: forAssetClassID,
              whichPortfolio: portfolio === portfolioA ? SimulationPortfolio.A : SimulationPortfolio.B,
            })
          }
        />

        {portfModalInfo && renderCustomPortfolioModal(portfModalInfo)}
        <div style={{ textAlign: 'right' }}>
          <ProcessNavPageFooter
            step={NavigationStep.Funds}
            simulation={props.simulation}
            onNewSimulation={props.layoutProps.onNewSimulation}
            backUrl={getSimulationURL(props.simulation)}
            nextUrl={getReportURL(props.simulation)}
            onLogout={props.layoutProps.onLogout}
          />
        </div>
        {/*<Container style={{ marginTop: "20px" }}>
                    <DisclaimerFooter />
                </Container>*/}
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  assetClasses: state.financeData.assetClasses.data,
});

export default withRouter(connect(mapStateToProps)(FundsPage));
