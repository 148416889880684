import React from 'react';
import { Container } from 'semantic-ui-react';

import { SectionWrap } from 'components/SectionWrap/SectionWrap';
import PortfolioSwitcher from 'components/PortfolioSwitcher/PortfolioSwitcher';

interface IChartSectionProps {
  toned: boolean;
  className?: string;
}

export const ChartSection: React.FC<IChartSectionProps> = (props) => {
  return (
    <SectionWrap toned={props.toned} className={props.className}>
      <Container>
        <PortfolioSwitcher>{props.children}</PortfolioSwitcher>
      </Container>
    </SectionWrap>
  );
};
