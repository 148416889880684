import { createStore, Store, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { reduceApp, AppState } from './reducers'
import { AppAction } from './actions';
import { UnsavedSimulationsSyncer } from './simulations/localstoragesync';

const composeEnhancers =
  typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // other store enhancers if any
);


export function setupStore(): Store<AppState, AppAction> {
  const store = createStore(reduceApp, enhancer);
  const unsavedSimSyncer = new UnsavedSimulationsSyncer(store);
  unsavedSimSyncer.restoreUnsavedSimulation();
  store.subscribe(unsavedSimSyncer.onStateChange)
  return store;
}
