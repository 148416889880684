import React from "react";

export var logo = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 831.5 564.4"
    xmlSpace="preserve"
    width={50}
    height={50}
  >
    <g fill="currentColor">
      <g>
        <g>
          <path
            d="M102.8,382.1v18c-12,6.5-25.8,9.8-41.5,9.8c-12.8,0-23.6-2.6-32.5-7.7c-8.9-5.1-15.9-12.2-21.1-21.2
  c-5.2-9-7.8-18.7-7.8-29.2c0-16.6,5.9-30.6,17.8-41.9c11.8-11.3,26.5-17,43.9-17c12,0,25.3,3.1,40,9.4v17.6
  c-13.4-7.6-26.4-11.4-39.2-11.4c-13.1,0-23.9,4.1-32.6,12.4c-8.6,8.3-13,18.6-13,31c0,12.5,4.2,22.8,12.7,30.9
  c8.5,8.1,19.4,12.1,32.6,12.1C76.1,394.9,89.6,390.6,102.8,382.1z"
          ></path>
        </g>
        <g>
          <path
            d="M165.2,293.8h11.5l51.2,114.8h-16.7L196,374.8h-48.7L133,408.6h-16.8L165.2,293.8z M189.3,360.2l-18.4-41.4l-17.1,41.4
  H189.3z"
          ></path>
        </g>
        <g>
          <path
            d="M245.9,408.6V294.4h28.6c11.5,0,20.7,2.9,27.5,8.6c6.8,5.7,10.2,13.5,10.2,23.2c0,6.6-1.7,12.4-5,17.3
  c-3.3,4.8-8.1,8.5-14.3,10.9c3.7,2.4,7.2,5.7,10.7,9.8c3.5,4.1,8.4,11.4,14.7,21.6c4,6.5,7.2,11.4,9.6,14.6l6.1,8.2h-19.5l-5-7.5
  c-0.2-0.3-0.5-0.7-1-1.4l-3.2-4.5l-5.1-8.3l-5.5-8.9c-3.4-4.7-6.5-8.4-9.3-11.2c-2.8-2.8-5.3-4.8-7.6-6
  c-2.3-1.2-6.1-1.8-11.4-1.8h-4.3v49.7H245.9z M267.2,308.3h-4.9v36h6.2c8.3,0,14-0.7,17-2.1c3.1-1.4,5.5-3.6,7.2-6.4
  c1.7-2.9,2.6-6.1,2.6-9.8c0-3.6-1-6.8-2.9-9.7c-1.9-2.9-4.6-5-8-6.2C280.9,308.9,275.2,308.3,267.2,308.3z"
          ></path>
        </g>
        <g>
          <path d="M442.3,294.4h15.5v114.2h-14.1l-76.3-87.9v87.9h-15.4V294.4h13.2l77,88.7V294.4z"></path>
        </g>
        <g>
          <path d="M490.6,294.4h64.7V309H507v34.8h46.8v14.6H507v35.5h49.9v14.6h-66.3V294.4z"></path>
        </g>
        <g>
          <path
            d="M644.4,352.4h38.3v48.4c-14.1,6.1-28,9.2-41.8,9.2c-18.9,0-34-5.5-45.3-16.6c-11.3-11-16.9-24.6-16.9-40.8
  c0-17.1,5.9-31.2,17.6-42.6c11.7-11.3,26.4-17,44.1-17c6.4,0,12.5,0.7,18.3,2.1c5.8,1.4,13.1,3.9,21.9,7.6v16.7
  c-13.6-7.9-27.1-11.8-40.5-11.8c-12.5,0-23,4.2-31.5,12.7c-8.6,8.5-12.8,18.9-12.8,31.3c0,13,4.3,23.6,12.8,31.8
  c8.5,8.2,19.5,12.3,33,12.3c6.5,0,14.4-1.5,23.5-4.5l1.6-0.5v-23.8h-22V352.4z"
          ></path>
        </g>
        <g>
          <path d="M715.1,294.4h16.4v114.2h-16.4V294.4z"></path>
        </g>
        <g>
          <path d="M765.2,294.4h64.7V309h-48.4v34.8h46.8v14.6h-46.8v35.5h49.9v14.6h-66.3V294.4z"></path>
        </g>
      </g>
      <g>
        <path d="M75.8,448.9h60.9v14.5H92.2v31.9h44.5v14.6H92.2v53.2H75.8V448.9z"></path>
      </g>
      <g>
        <path
          d="M218.6,447.3c17.7,0,32.3,5.6,44.1,16.7c11.7,11.2,17.6,25.2,17.6,42c0,16.8-5.9,30.8-17.8,41.8
  c-11.8,11-26.8,16.5-44.8,16.5c-17.2,0-31.5-5.5-43-16.5c-11.5-11-17.2-24.9-17.2-41.5c0-17.1,5.8-31.2,17.3-42.3
  C186.4,452.9,201,447.3,218.6,447.3z M219.3,462.8c-13.1,0-23.8,4.1-32.2,12.3c-8.4,8.2-12.6,18.6-12.6,31.3
  c0,12.3,4.2,22.6,12.6,30.7c8.5,8.2,19,12.3,31.7,12.3c12.7,0,23.4-4.2,31.9-12.5c8.5-8.3,12.8-18.7,12.8-31.1
  c0-12.1-4.3-22.3-12.8-30.5C242.1,466.9,231.7,462.8,219.3,462.8z"
        ></path>
      </g>
      <g>
        <path d="M396.1,448.9h15.5v114.2h-14.1l-76.3-87.9v87.9h-15.4V448.9h13.2l77,88.7V448.9z"></path>
      </g>
      <g>
        <path
          d="M444.6,562.9v-114h38.5c15.5,0,27.8,2.3,36.9,7c9.1,4.7,16.2,11.5,21.6,20.6c5.3,9,8,18.9,8,29.6c0,7.6-1.5,14.9-4.4,21.9
  c-2.9,7-7.1,13.1-12.6,18.4c-5.6,5.5-12,9.6-19.4,12.3c-4.3,1.7-8.3,2.8-11.9,3.4c-3.6,0.6-10.5,0.8-20.6,0.8H444.6z M481.4,463.5
  H461v84.9h20.9c8.2,0,14.5-0.6,19-1.7c4.5-1.1,8.3-2.5,11.3-4.2c3-1.7,5.8-3.8,8.3-6.3c8.1-8.2,12.1-18.5,12.1-31
  c0-12.3-4.1-22.3-12.4-30c-3-2.9-6.6-5.3-10.5-7.1c-4-1.9-7.7-3.1-11.2-3.7C495,463.8,489.3,463.5,481.4,463.5z"
        ></path>
      </g>
      <g>
        <path d="M575,448.9h64.7v14.5h-48.4v34.8h46.7v14.6h-46.7v35.5h49.9v14.5H575V448.9z"></path>
      </g>
      <g>
        <path
          d="M667.6,563.1V448.9h28.6c11.5,0,20.7,2.8,27.5,8.6c6.8,5.7,10.2,13.5,10.2,23.2c0,6.6-1.7,12.4-5,17.2
  c-3.3,4.8-8.1,8.5-14.3,10.9c3.6,2.4,7.2,5.7,10.7,9.8c3.5,4.1,8.4,11.4,14.7,21.7c4,6.5,7.2,11.4,9.6,14.6l6,8.2h-19.5l-5-7.5
  c-0.2-0.3-0.5-0.7-1-1.4l-3.2-4.5l-5.1-8.3l-5.5-8.9c-3.4-4.7-6.5-8.4-9.3-11.2c-2.8-2.8-5.4-4.8-7.6-6c-2.3-1.2-6.1-1.8-11.4-1.8
  h-4.2v49.7H667.6z M688.8,462.8h-4.9v36h6.2c8.3,0,14-0.7,17-2.1c3.1-1.4,5.5-3.6,7.2-6.4c1.7-2.9,2.6-6.1,2.6-9.8
  c0-3.6-0.9-6.8-2.9-9.8c-1.9-2.9-4.6-5-8-6.2C702.6,463.4,696.9,462.8,688.8,462.8z"
        ></path>
      </g>
      <g>
        <g>
          <path
            d="M226.6,132.8c-0.6,1.8,1.2,2.5,2.3,0.5c12.4-21.6,42.6-26.7,37.5-3.3c-2.1,9.8-9.8,23.4-20.1,31.2
  c-19.5,14.5-39.1,1.9-30.5-23.4c3.7-11,9.1-19.1,19.4-30.6c3.5,0,4.7,0,6.6,0c26.5,0,55.3-15.8,67-36c8-13.1,1.9-20.4-14.3-18.5
  C269.9,55.1,239,75,220,100c-13.8-4.2-21.3-12.4-21.3-22.5c0-9.6,12.4-18.7,29.3-18.7c12.2,0,19.5,2.7,24.4,4.5
  c1.4,0.5,2.6-1.2,0.2-2.3c-7.1-3.4-16.9-4.9-24.6-4.9c-22.2,0-35.1,9.8-35.1,22c0,11.7,8.2,21.6,23.7,26
  c-6.6,9.6-11,19.7-13.1,30c-6.6,33.5,18.5,50.1,44.7,31.6c9.1-6.6,19-20.4,22.5-34.4C277.9,101.9,237.9,105.1,226.6,132.8z
  M297.3,60c7.7-1.2,11.9,2.8,8,9.3c-11,18-42.4,33.5-67,33.5C258,81.1,284.2,62.1,297.3,60z"
          ></path>
        </g>
        <g>
          <path
            d="M644.9,136.8c-5.6,8.4-10.5,13.6-16.1,17.6c-15.9,11.2-31.9,6.8-22.3-14.8l0.3-0.5c11-3.5,25.5-9.6,32.5-16.2
  c7.5-6.8,4.9-16.6-7.3-15.7c-12.4,0.9-26.9,11.3-36.3,26.5c-1.9,3-3.5,6.1-4.4,8.9c-5.4,7-11.2,11.3-14.8,13.4
  c-5.9,3.3-10.3,2.3-7.5-2.6c4.7-9.6,19.4-33.5,24.8-43.3h-13.6c-4,7.3-14.5,24.4-21.1,35.6c-5.2,6.8-10.3,11.9-14,15.2
  c-6.3,5.8-9.4,0.5-7-4.2c13.1-22.7,26.5-46.6,26.5-46.6h-14c0,0-1.2,2.3-3.3,5.6c0-8.7-11-11.3-24.1-4.4
  c-13.3,7.3-23.4,20.4-27.6,32.1c-3.5,4.5-7.5,8.2-11.2,10.8c-16.6,11.5-32.9,8.4-20.9-15.2c11-3.5,24.9-9.4,32.2-16
  c7.5-6.8,4.9-16.6-7.5-15.7c-12.2,0.9-26.9,11.3-36.1,26.5c-3,4.9-4.9,9.4-5.9,13.8c-3.3,3.5-6.3,6.1-11.2,9.1
  c-5.9,3.3-10.1,2.3-7.5-3c4.7-9.4,12.9-21.3,18-30.9c6.6-12.9-3.3-18.3-15-13.8c-6.6,2.6-14.1,7.3-18.7,11l5.6-9.8h-13.8
  l-9.6,16.2c-9.6,6.5-10.8,1.7-10.8-0.7c0-5.4,8.9-8.4,8.9-14.1c0-2.3-1.6-4-5.2-4c-6.3,0-14.1,6.3-23,16.4l8-13.8h-13.1
  c-2.3,4.2-11.7,19.9-16.4,27c-4.7,7-12.6,14.5-17.6,18.7c-8.4,7-12.2,2.1-5.9-8.4c3.7-6.8,14.5-25.3,20.8-37.3h-13.6
  c0,0-0.9,2.1-2.8,5.1c-1.2-8.4-12-11.2-24.8-4.4c-18,9.6-29.3,30.2-29.3,43.8c0,13.3,11.2,22.7,32.6,3.8
  c0,11.9,10.8,18.3,30.9-4.7c-1.4,2.6-5.6,9.4-7.5,12.9h14.3l15.5-27.2l1.2-1.9c4.6-6.5,15.5-17.8,19.7-19.9
  c-5.6,15,4.2,20.4,14.8,14.5l-20.1,34.4h14.7l19-33.7l0.4-0.7l0.5-0.7c5.8-7.5,16.9-13.8,20.8-16.2c6.3-3,8.2,0.9,6.1,5.2
  c-4,8.7-13.1,21.6-17.8,31.2c-4,7.5-3.3,12.4-0.2,15c3.5,3.5,9.6,3,14.3,0.2c5.4-2.8,12.2-8.4,17.1-13.6c0,17.3,19.2,23.2,41.5,4
  c2.1-2.1,4.8-4.2,6.9-7c-0.4,1.6-0.6,3.3-0.6,4.4c0,14.5,13.6,20.1,27.2,8c-0.8,1.4-0.8,1.4-2.3,4
  c-19.2,33.7-24.8,39.6-44.3,39.6c-10.6,0-19.2-7.7-19.2-17.8c0-7,6.1-13,14.5-13.1c2.5,0.1,2.8-1.7,0.5-2.3c-1.1-0.3-3.1-1-5.6-1
  c-11,0-19.7,7.5-19.7,17.3c0,11,10.8,20.4,30.4,20.4c20.6,0,38-8.9,52.9-34.7c2.4-4.1,3.5-5.4,4.9-8.4c2.1,3.3,7,3.8,13.6-1.9
  c2.3-1.9,4.9-4.9,8.2-8.9c-1.4,4.2-1.2,7.5,0.5,9.6c2.3,4,8.9,4.9,14.7,1.6c5.9-3.3,13.8-9.8,19.9-16.4c-2.1,19.4,18,26.9,42.4,7
  c5.9-4.7,12.2-12.6,15.5-19.9C648.2,135.6,646.4,134.7,644.9,136.8z M626.9,112.9c2.1-0.3,4,0.5,4,3.3c0,7.3-11.5,13.1-21.1,16.6
  C615.9,121.6,621.1,113.8,626.9,112.9z M483.1,112.9c2.1-0.3,4,0.5,4,3.3c0,7.3-11.5,13.1-21.1,16.6
  C472.1,121.6,477.2,113.8,483.1,112.9z M305.5,145.7c-1.9,3.6-2.5,4.3-3.1,7.1c-5.6,5.1-10,6-13.3,6c-11.5,0-4-30,12.4-42.4
  c9.1-7,19.7-7.5,18,5.4C314.2,130.7,307.5,142.3,305.5,145.7z M525.7,153.4c-4.9,4.4-9.4,5.4-12.4,5.4
  c-12.9,0-2.8-30.2,12.9-41.9c7.5-5.9,16.2-7.3,17.3,0.2c0.3,2.4,0.2,5.5-0.9,7.5C537.7,132.8,531.3,143.6,525.7,153.4z"
          ></path>
        </g>
        <g>
          <path d="M594.8,97.2c4.4,0,8.7-3.3,8.7-7.5c0-4-2.6-7.5-6.8-7.5c-4,0-8.4,3.5-8.4,7.5C588.3,93.9,590.8,97.2,594.8,97.2z"></path>
        </g>
        <g>
          <path
            d="M415.6,0c-145,0-262.4,54.6-262.4,117.1c0,62.6,116.7,117.1,262.4,117.1c145.2,0,262.6-54.6,262.6-117.1
  C678.2,54.6,560.9,0,415.6,0z M415.6,224.7c-142.2,0-257.9-48-257.9-107.5c0-59.5,115.7-107.5,257.9-107.5
  c142.4,0,258.2,48,258.2,107.5C673.8,176.6,558,224.7,415.6,224.7z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
