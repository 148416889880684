import React from 'react';
import { Grid } from 'semantic-ui-react';

import { DesktopOrTablet, Mobile } from 'components/util/responsive';

import './ForwardSimulationNumbersPane.css'

interface IForwardSimulationNumbersPaneProps {
    investedAmount: number
    expectedAmount: number
    lowAmount: number
    highAmount: number
    year: number
    isSecondary: boolean
}

export default function ForwardSimulationNumbersPane(props: IForwardSimulationNumbersPaneProps) {
    const makeYearValueColumn = (amount: number, year: number, txt: string, addAsterisk: boolean): JSX.Element => {
        if (amount === null || year === null) {
            return <Grid.Column />
        }
        const asterisk = (addAsterisk === true) ? <sup>*</sup> : '';
        return (
            <Grid.Column>
                <span className={'big-value' + (props.isSecondary ? ' secondary' : '')}>{Math.round(amount)}</span>
                {txt}{asterisk}
            </Grid.Column>
        );
    }

    return (
        <Grid textAlign='center' className='ForwardSimulationNumbersPane tight'>
            <DesktopOrTablet>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <span className={'big-value' + (props.isSecondary ? ' secondary' : '')}>{props.investedAmount}</span>
                        Invested amount
                    </Grid.Column>
                    {makeYearValueColumn(props.expectedAmount, props.year, `Expected value year ${props.year}`, false)}
                    {makeYearValueColumn(props.lowAmount, props.year, `Worst outcome year ${props.year}`, true)}
                    {makeYearValueColumn(props.highAmount, props.year, `Best outcome year ${props.year}`, true)}
                </Grid.Row>
            </DesktopOrTablet>
            <Mobile>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <span className={'big-value' + (props.isSecondary ? ' secondary' : '')}>{props.investedAmount}</span>
                        Invested amount
                    </Grid.Column>
                    {makeYearValueColumn(props.expectedAmount, props.year, `Expected value year ${props.year}`, false)}
                </Grid.Row>
                <Grid.Row columns={2}>
                    {makeYearValueColumn(props.lowAmount, props.year, `Worst outcome year ${props.year}`, true)}
                    {makeYearValueColumn(props.highAmount, props.year, `Best outcome year ${props.year}`, true)}
                </Grid.Row>
            </Mobile>
        </Grid>
    )
}
