import React from "react";

import "./PageHeader.css";

interface IPageHeaderProps {
  text: string;
}

function PageHeader(props: IPageHeaderProps) {
  return (
    <div style={{ paddingBottom: "4vh" }} className="PageHeader">
      <div className="wp-block-cover__inner-container" style={{ paddingLeft: 100, paddingRight: 100 }}>
        <div aria-hidden="true" className="wp-block-spacer"></div>
        <h2 className="has-text-align-center has-cream-color has-text-color">
          {props.text} <br />
        </h2>
        <p className="has-text-align-center has-cream-color has-text-color"></p>
      </div>
    </div>
  );
}

export default PageHeader;
