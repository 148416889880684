import React, {useState} from "react";
import { Bar } from "react-chartjs-2";

import { buildChartData, buildChartOptions } from "./ExpectedProfitAndWorstOutcomeChartDataBuilders";
import { DesktopOrTablet, Mobile } from "components/util/responsive";
import { ScaleFactorContributionSlider } from "components/ScaleFactorContributionSlider/ScaleFactorContributionSlider";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";

interface IExpectedProfitAndWorstOutcomeBarGraphProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  isASelected: boolean;
  isBSelected: boolean;
  scaleFactorContribution: number;
}

function ExpectedProfitAndWorstOutcomeBarGraph(props: IExpectedProfitAndWorstOutcomeBarGraphProps) {
  const xAxisLabel = "Investment Period (years)";
  const yAxisLabel = "Expected Profit / Worst Forecasted Outcome";
  const opts = buildChartOptions(xAxisLabel, yAxisLabel, props.isASelected, props.isBSelected);
  const data = buildChartData(props.portfolioA, props.portfolioB, props.isASelected, props.isBSelected, props.scaleFactorContribution);
  return <Bar data={data} options={opts} />;
}

interface IExpectedProfitAndWorstOutcomeChartProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  isASelected: boolean;
  isBSelected: boolean;
  scaleFactorContribution: number;
  onScaleFactorContributionChanged: (newContribution: number) => void;
}

interface IGraphLabelsProps {
  active: number;
  labels: string[];
  setActive: (index: number) => void;
}

function GraphLabels(props: IGraphLabelsProps) {
  return (
    <div className="graph-label-container">
      {props.labels.map((label, index) => (
        <div key={index} onClick={() => props.setActive(index)} className={`graph-label ${index === props.active ? "active" : ""}`}>
          {label}
        </div>
      ))}
    </div>
  );
}

export const ExpectedProfitAndWorstOutcomeChart = (props: IExpectedProfitAndWorstOutcomeChartProps) => {
  const [activeLabel, setActiveLabel] = useState(0);

  return (
    <div style={{ padding: 20 }}>
      <GraphLabels active={activeLabel} labels={["Portfolio A", " Portfolio B", "A & B", ]} setActive={setActiveLabel} />
      <DesktopOrTablet>
        <div>
          {
            <ScaleFactorContributionSlider
              extraStyle={{ float: "right" }}
              scaleFactorContribution={props.scaleFactorContribution}
              onScaleFactorContributionChanged={props.onScaleFactorContributionChanged}
            />
          }
          <h3 className="header-first-child expected-profil-header">
            Expected Profit and Worst Outcome
          </h3>
        </div>
      </DesktopOrTablet>
      <Mobile>
        <div>
          <h3 className="header-first-child">Expected Profit and Worst Outcome</h3>
        </div>
        <div>
          <ScaleFactorContributionSlider
            extraStyle={{ textAlign: "center", marginTop: "0.5em", marginBottom: "1em" }}
            scaleFactorContribution={props.scaleFactorContribution}
            onScaleFactorContributionChanged={props.onScaleFactorContributionChanged}
            />
        </div>
      </Mobile>
      <ExpectedProfitAndWorstOutcomeBarGraph
        portfolioA={props.portfolioA}
        portfolioB={props.portfolioB}
        isASelected={activeLabel !== 1}
        isBSelected={activeLabel !== 0}
        scaleFactorContribution={props.scaleFactorContribution}
      />
    </div>
  );
}

interface IExpectedProfitAndWorstOutcomeReportChartProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
  isASelected: boolean;
  isBSelected: boolean;
  scaleFactorContribution: number;
  titleSuffix?: string;
}

export function ExpectedProfitAndWorstOutcomeReportChart(props: IExpectedProfitAndWorstOutcomeReportChartProps) {
  return (
    <div className="ExpectedProfitAndWorstOutcomeReportChart chart-section">
      <h3 className="header-first-child">Expected Profit and Worst Outcome {props.titleSuffix}</h3>
      <ExpectedProfitAndWorstOutcomeBarGraph
        portfolioA={props.portfolioA}
        portfolioB={props.portfolioB}
        isASelected={props.isASelected}
        isBSelected={props.isBSelected}
        scaleFactorContribution={props.scaleFactorContribution}
      />
    </div>
  );
}
