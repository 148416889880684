import { Store } from "redux";
import { AppState } from "store/reducers";
import { AppAction } from "store/actions";
import { simulationsSetUnsaved } from "./actions";
import { SimulationData } from "apiclient/responsePayloads/Simulation";

export class UnsavedSimulationsSyncer {
  constructor(store: Store<AppState, AppAction>) {
    this.store = store
    this.lastUnsavedSimulation = undefined
  }

  public onStateChange = () => {
    const state = this.store.getState();
    if (state.simulations.unsavedSimulation !== this.lastUnsavedSimulation) {
      // unsavedSimulation has changed
      if (state.simulations.unsavedSimulation === undefined) {
        window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
      } else {
        window.localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(state.simulations.unsavedSimulation))
      }
      this.lastUnsavedSimulation = state.simulations.unsavedSimulation;
    }
  }

  public restoreUnsavedSimulation = () => {
    const json = window.localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (json !== null) {
      const simulation = JSON.parse(json) as SimulationData;
      this.store.dispatch(simulationsSetUnsaved(simulation));
    }
  }

  private store: Store<AppState, AppAction>
  private lastUnsavedSimulation: SimulationData | undefined;
  private LOCAL_STORAGE_KEY = 'unsavedSimulation';
}
