import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, Grid, Button, Typography, TableSortLabel } from "@material-ui/core";
import { Container } from "semantic-ui-react";
import { UserProfile } from "apiclient/responsePayloads/User";
import { EditDialog } from "../EditDialog/EditDialog";
import { updateUser, createUser } from "admin/UserAPI";
import AddIcon from "@material-ui/icons/Add";
import { usersAdminStyles } from "./UsersAdminStyles";
import orderBy from "lodash/orderBy";
import moment from "moment";

interface UsersAdminProps {
  users: UserProfile[];
  onRefresh: () => void;
}

const invertDirection: { [key in Order]: Order } = {
  asc: "desc",
  desc: "asc"
};

type Order = "asc" | "desc";

export const UsersAdmin: React.FC<UsersAdminProps> = props => {
  const { users, onRefresh } = props;
  const classes = usersAdminStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [columnToSort, setColumnToSort] = React.useState<keyof UserProfile>("email");
  const [sortDirection, setSortDirection] = React.useState<Order>("asc");
  const [open, setOpen] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState<UserProfile | null>(null);

  const handleClickOpen = (user?: UserProfile) => {
    if (user) {
      setUserToEdit(user);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserToEdit(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (prop: keyof UserProfile) => {
    setColumnToSort(prop);
    setSortDirection(columnToSort === prop ? invertDirection[sortDirection] : "asc");
  };

  const handleOrderBy = (data: UserProfile[], columnToSort: keyof UserProfile, sortDirection: Order): UserProfile[] => {
    if (columnToSort === "lastActive" || columnToSort === "createdAt") {
      return data.sort(function compare(userA, userB) {
        const a = userA[columnToSort];
        const b = userB[columnToSort];

        if (a !== null) new Date(a);
        if (b !== null) new Date(b);
        // equal items sort equally
        if (a === b) {
          return 0;
        }
        // nulls sort after anything else
        else if (a === null) {
          return 1;
        } else if (b === null) {
          return -1;
        }
        // otherwise, if we're ascending, lowest sorts first
        else if (sortDirection === "asc") {
          return a < b ? -1 : 1;
        }
        // if descending, highest sorts first
        else {
          return a < b ? 1 : -1;
        }
      });
    } else return orderBy(data, columnToSort, sortDirection);
  };

  interface SortHeaderCells {
    id: keyof UserProfile;
    label: string;
  }

  const sortHeaderCells: SortHeaderCells[] = [
    { id: "email", label: "User" },
    { id: "createdAt", label: "Register date" },
    { id: "lastActive", label: "Last active" }
  ];

  return (
    <div>
      <Container>
        <h2>Users</h2>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow style={{ backgroundColor: "rgb(22,53,101)" }}>
                  {sortHeaderCells.map((cell, index) => (
                    <TableCell key={index} className={classes.tableHeaderCell}>
                      <TableSortLabel
                        classes={{
                          root: classes.primaryColor,
                          active: classes.primaryColor,
                          icon: classes.primaryColor
                        }}
                        active={columnToSort === cell.id}
                        hideSortIcon={columnToSort !== cell.id}
                        direction={sortDirection}
                        onClick={() => handleSort(cell.id)}
                      >
                        {cell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell className={classes.tableHeaderCell}>Admin</TableCell>
                  <TableCell className={classes.tableHeaderCell}>Organization</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? handleOrderBy(users, columnToSort, sortDirection).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : orderBy(users, columnToSort, sortDirection)
                ).map((user: UserProfile, index: number) => {
                  return (
                    <TableRow style={index % 2 ? { backgroundColor: "#fafafa" } : { backgroundColor: "white" }} key={user.id}>
                      <TableCell className={classes.tableCell}>
                        <strong onClick={() => handleClickOpen(user)} style={user.isDisabled ? { cursor: "pointer", color: "grey" } : { cursor: "pointer" }}>
                          {user.firstName + " " + user.lastName + " "}
                        </strong>
                        {user.isDisabled && (
                          <span>
                            <Typography style={{ display: "inline-block" }} color="secondary">
                              {" "}
                              (DISABLED)
                            </Typography>
                          </span>
                        )}
                        {!user.isActivated && (
                          <span>
                            <Typography
                              style={{
                                display: "inline-block",
                                color: "darkOrange"
                              }}
                            >
                              {" "}
                              (NOT ACTIVATED)
                            </Typography>
                          </span>
                        )}
                        <br />
                        {user.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.createdAt && moment(user.createdAt.toLocaleString("sv")).format("MMMM Do YYYY")}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.lastActive !== null && user.lastActive !== undefined ? (
                          <div>
                            {moment(user.lastActive.toLocaleString("sv")).fromNow()}{" "}
                            <span style={{ color: "lightGrey" }}>
                              <br />({moment(user.lastActive).format("L")})
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{user.isAdmin ? "Yes" : "No"}</TableCell>
                      <TableCell className={classes.tableCell}>{user.organization}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <Grid container>
            <Grid item xs={4}>
              <Button variant="outlined" color="primary" onClick={() => handleClickOpen()} className={classes.createButton} startIcon={<AddIcon />}>
                Create new user
              </Button>
            </Grid>
            <Grid item xs={8}>
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Paper>
        {open && (
          <EditDialog
            open={open}
            user={userToEdit}
            handleClose={handleClose}
            handleCreateUser={createUserRequest => {
              return createUser(createUserRequest, onRefresh);
            }}
            handleUpdateUser={(id, updateUserRequest) => {
              return updateUser(id, updateUserRequest, onRefresh);
            }}
          />
        )}
      </Container>
    </div>
  );
};
