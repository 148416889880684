import { primaryPortfolioColor, secondaryPortfolioColor } from 'util/colors';
import { AssetClassPortfolio } from 'business/AssetClassPortfolio';
import math from 'mathjs';
import Allocation from 'business/Allocation';

function buildRiskAllocationChartData(portfolio: AssetClassPortfolio): Chart.ChartData {
    const covarianceMap = portfolio.covarianceMap;
    const portfolioRisk: number = portfolio.calculateRisk()
    const portfolioYield: number = portfolio.calculateYield()

    const capitalAllocation = portfolio.allocations.map((component: any) => component.weightInPercent * 0.01);

    const items = portfolio.allocations.filter((item: Allocation) => item.weightInPercent > 0).map((component: Allocation) => {
        const covariances = covarianceMap.get(component.assetClass.id)
        const riskContribution = Number(math.subset(math.multiply([capitalAllocation], math.transpose([covariances])), math.index(0, 0))) /
            portfolioRisk * component.weightInPercent * 0.01;

        const yieldContribution = component.weightInPercent * component.assetClass.yield;
        const yieldAllocation = yieldContribution / portfolioYield;

        return {
            label: component.getTranslatedName(),
            capitalWeightInPercent: component.weightInPercent,
            riskAllocation: riskContribution / portfolioRisk,
            yieldAllocation,
        };
    });

    const data: Chart.ChartData = {
        labels: items.map((item: any) => item.label),
        datasets: [
            {
                label: "Allocation of capital",
                backgroundColor: primaryPortfolioColor,
                borderColor: primaryPortfolioColor,
                borderWidth: 1,
                hoverBackgroundColor: primaryPortfolioColor,
                hoverBorderColor: primaryPortfolioColor,
                data: items.map((item: any) => item.capitalWeightInPercent)
            },
            {
                label: "Allocation of risk",
                backgroundColor: secondaryPortfolioColor,
                borderColor: secondaryPortfolioColor,
                borderWidth: 1,
                hoverBackgroundColor: secondaryPortfolioColor,
                hoverBorderColor: secondaryPortfolioColor,
                data: items.map((item: any) => item.riskAllocation * 100.0)
            },
            {
                label: "Allocation of return",
                backgroundColor: '#ccc',
                borderColor: '#ccc',
                borderWidth: 1,
                hoverBackgroundColor: '#ccc',
                hoverBorderColor: '#ccc',
                data: items.map((item: any) => item.yieldAllocation)
            }
        ]
    };

    return data;
}

export {
    buildRiskAllocationChartData
}