import { Reducer, combineReducers } from "redux";
import { AppAction } from "store/actions";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { ASSET_CLASSES_LOADING, ASSET_CLASSES_LOAD_FAILED, ASSET_CLASSES_LOAD_SUCCESS, ASSET_CLASS_RETURNS_LOADING, ASSET_CLASS_RETURNS_LOAD_FAILED, ASSET_CLASS_RETURNS_LOAD_SUCCESS, FUND_RETURNS_LOADING, FUND_RETURNS_LOAD_FAILED, FUND_RETURNS_LOAD_SUCCESS, FUNDS_LOADING, FUNDS_LOAD_FAILED, FUNDS_LOAD_SUCCESS } from "./actions";
import { IAssetClassReturn } from "apiclient/responsePayloads/AssetClassReturn";
import { IFundReturn } from "apiclient/responsePayloads/FundReturn";
import { IFund } from "apiclient/responsePayloads/Fund";

export interface AssetClassesSlice {
  data?: AssetClassData[]
  loading: boolean
  errorMessage?: string
}

export interface AssetClassReturnsSlice {
  data?: IAssetClassReturn[]
  loading: boolean
  errorMessage?: string
}

export interface FundReturnsSlice {
  data?: IFundReturn[]
  loading: boolean
  errorMessage?: string
}

export interface FundsSlice {
  data?: IFund[]
  loading: boolean
  errorMessage?: string
}

export interface FinanceDataSliceState {
  assetClasses: AssetClassesSlice
  assetClassReturns: AssetClassReturnsSlice
  fundReturns: FundReturnsSlice
  funds: FundsSlice
}

const initialAssetClassesState: AssetClassesSlice = {
  loading: false
}

const initialAssetClassReturnsState: AssetClassReturnsSlice = {
  loading: false
}

const initialFundReturnsSlice: FundReturnsSlice = {
  loading: false
}

const initialFundsSlice: FundsSlice = {
  loading: false
}

const reduceAssetClasses: Reducer<AssetClassesSlice, AppAction> = (state = initialAssetClassesState, action) => {
  switch (action.type) {
    case ASSET_CLASSES_LOADING:
      return { ...state, loading: true }
    case ASSET_CLASSES_LOAD_FAILED:
      return { ...state, loading: false, errorMessage: action.message }
    case ASSET_CLASSES_LOAD_SUCCESS:
      return { ...state, loading: false, data: action.assetClasses, errorMessage: undefined }
    default:
      return state
  }
}

const reduceAssetClassReturns: Reducer<AssetClassReturnsSlice, AppAction> = (state = initialAssetClassReturnsState, action) => {
  switch (action.type) {
    case ASSET_CLASS_RETURNS_LOADING:
      return { ...state, loading: true }
    case ASSET_CLASS_RETURNS_LOAD_FAILED:
      return { ...state, loading: false, errorMessage: action.message }
    case ASSET_CLASS_RETURNS_LOAD_SUCCESS:
      return { ...state, loading: false, data: action.returns, errorMessage: undefined }
    default:
      return state
  }
}

const reduceFundReturns: Reducer<FundReturnsSlice, AppAction> = (state = initialFundReturnsSlice, action) => {
  switch (action.type) {
    case FUND_RETURNS_LOADING:
      return { ...state, loading: true }
    case FUND_RETURNS_LOAD_FAILED:
      return { ...state, loading: false, errorMessage: action.message }
    case FUND_RETURNS_LOAD_SUCCESS:
      return { ...state, loading: false, data: action.returns, errorMessage: undefined }
    default:
      return state
  }
}

const reduceFunds: Reducer<FundsSlice, AppAction> = (state = initialFundsSlice, action) => {
  switch (action.type) {
    case FUNDS_LOADING:
      return { ...state, loading: true }
    case FUNDS_LOAD_FAILED:
      return { ...state, loading: false, errorMessage: action.message }
    case FUNDS_LOAD_SUCCESS:
      return { ...state, loading: false, data: action.funds, errorMessage: undefined }
    default:
      return state
  }
}

export const reduceFinanceData = combineReducers<FinanceDataSliceState, AppAction>({
  assetClasses: reduceAssetClasses,
  assetClassReturns: reduceAssetClassReturns,
  fundReturns: reduceFundReturns,
  funds: reduceFunds,
})
