import * as React from "react";
import { Grid } from "semantic-ui-react";
import { toString } from "util/misc-utils";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";

import "./ReportRiskReturnSection.css";

interface IRiskReturnPart {
  title: string;
  return: string;
  risk: string;
  riskReturnBoxClassName: string;
}

function RiskReturnPart(props: IRiskReturnPart) {
  return (
    <div className="risk-return-part">
      <h3>{props.title}</h3>

      <Grid className="risk-return-box">
        <Grid.Row>
          <Grid.Column width={8}>
            <div className="number return-number">{`${props.return} %`}</div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="number risk-number">{`${props.risk} %`}</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <div className="label return-label">Expected return</div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="label risk-label">Expected risk</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

interface IReportRiskReturnSectionProps {
  portfolioA: AssetClassPortfolio;
  portfolioB: AssetClassPortfolio;
}

const ReportRiskReturnSection: React.SFC<IReportRiskReturnSectionProps> = ({ portfolioA, portfolioB }) => {
  return (
    <div className="ReportRiskReturnSection">
      <RiskReturnPart
        title={"Portfolio A"}
        return={toString(100 * portfolioA.calculateYield())}
        risk={toString(100 * portfolioA.calculateRisk())}
        riskReturnBoxClassName={"RiskReturnBoxA"}
      />
      <RiskReturnPart
        title={"Portfolio B (Comparison / Current Portfolio)"}
        return={toString(100 * portfolioB.calculateYield())}
        risk={toString(100 * portfolioB.calculateRisk())}
        riskReturnBoxClassName={"RiskReturnBoxB"}
      />
    </div>
  );
};
export default ReportRiskReturnSection;
