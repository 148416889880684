import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Modal, Form, Button } from "semantic-ui-react";

import { Simulation } from "apiclient/responsePayloads/Simulation";
import Auth from "modules/Auth";
import { IRouterProps } from "util/RouterPropsInterface";
import { useDispatch } from "react-redux";
import { createNewSimulation } from "store/simulations/actions";

import "./SaveSimulationModal.css";

interface ISaveSimulationModalProps extends RouteComponentProps<IRouterProps> {
  simulation: Simulation;
  visible: boolean;
  onClose(): void;
}

const SIMULATION_PAGES = ["simulation", "funds", "report"];

const calculateRedirectionURL = (id: string, pathname: string) => {
  let page = "simulation";
  if (pathname.length > 0) {
    const parts = pathname.split("/");
    const lastPathPart = parts[parts.length - 1];
    if (SIMULATION_PAGES.indexOf(lastPathPart)) {
      page = lastPathPart;
    }
  }
  return `/en/s/${id}/${page}`;
};

export const SaveSimulationModal: React.FC<ISaveSimulationModalProps> = props => {
  const [name, setName] = React.useState("");
  const dispatch = useDispatch();

  const handleSave = () => {
    const auth = new Auth(localStorage);
    const userID = auth.getUserID();
    if (userID === undefined) {
      throw new Error("cannot save simulation unless logged in");
    }

    const simulation = props.simulation.clone();
    simulation.ownerId = userID;
    simulation.name = name;

    dispatch(
      createNewSimulation(
        userID,
        simulation.getSimulationData(),
        (id: string) => {
          props.onClose();
          props.history.push(calculateRedirectionURL(id, props.location.pathname));
        },
        err => {
          console.log("failed to create new simulation", err);
          props.onClose();
        }
      )
    );
  };

  return (
    <Modal className="SaveSimulationModal" open={props.visible} onClose={props.onClose}>
      <Modal.Header>Save portfolio</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Name"
            width={16}
            className="carnegie__input"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="teal" className="secondary-btn carnegie__button__secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button className="carnegie__button" onClick={handleSave} disabled={name.trim().length === 0} primary={true}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withRouter(SaveSimulationModal);
