import React, { useState } from "react";
import { Paper, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider, Button } from "@material-ui/core";
import { Container } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SuccessIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Warning";
import { fundsStyles } from "./FundsStyles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers";
import { EditFund } from "./EditFunds";
import { IFund } from "apiclient/responsePayloads/Fund";
import { updateFund, createFund } from "admin/FundsAPI";
import { fetchFunds } from "store/financedata/actions";
import { AddFundDialog } from "./AddFundDialog";

interface FundsComponentProps {}

export const FundsComponent: React.FC<FundsComponentProps> = _ => {
  const classes = fundsStyles();
  const dispatch = useDispatch();
  const funds = useSelector((store: AppState) => store.financeData.funds.data);
  const [FundToEdit, setFundToEdit] = useState<IFund | undefined>(undefined);
  const [createResult, setCreateResult] = useState<"Success" | "Fail" | undefined>(undefined);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setFundToEdit(undefined);
  };

  const handleCreateFund = (newFund: IFund) => {
    createFund(newFund)
      .then(res => {
        if (res instanceof Error) handleCreateFail();
        else handleCreateSuccess();
      })
      .catch((error: Error) => {
        handleCreateFail();
      });
  };

  const handleCreateSuccess = () => {
    dispatch(fetchFunds());
    handleCreateStateChange("Success");
  };

  const handleCreateFail = () => {
    handleCreateStateChange("Fail");
  };

  const handleCreateStateChange = (state: "Success" | "Fail") => {
    setCreateResult(state);

    setTimeout(() => {
      setCreateResult(undefined);
    }, 3000);
  };

  const handleUpdateFund = (fund: IFund) => {
    updateFund(fund)
      .then(() => dispatch(fetchFunds()))
      .catch((err: any) => {
        console.log(err.message);
        if (err.response.status === 409) {
          alert("Could not update fund. Name already exist.");
        } else alert("Could not update fund.");
      });
    setOpenEditDialog(false);
    setFundToEdit(undefined);
  };

  const handleEditFundClick = (fund: IFund) => {
    setFundToEdit(fund);
    setOpenEditDialog(true);
  };

  return (
    <div>
      <Container>
        <h2>Funds</h2>
        <Paper className={classes.paper}>
          {funds && (
            <List style={{ backgroundColor: "lightGrey", padding: "1px 1px 0 1px" }} component="div">
              {funds
                .filter(f => !f.id.startsWith("index:"))
                .map((fund, index) => (
                  <div key={index}>
                    <ListItem ContainerComponent="div" style={{ backgroundColor: "white" }}>
                      <ListItemIcon>
                        <IconButton onClick={() => handleEditFundClick(fund)} edge="end">
                          <EditIcon />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText primary={fund.name} secondary={fund.description ? fund.description : ""} />
                    </ListItem>
                    <Divider component="div" />
                  </div>
                ))}
            </List>
          )}
        </Paper>
        <Button
          variant="outlined"
          style={{ verticalAlign: "middle", lineHeight: "5px" }}
          startIcon={!createResult ? <AddIcon /> : createResult === "Success" ? <SuccessIcon /> : <ErrorIcon />}
          className={!createResult ? classes.normalButton : createResult === "Success" ? classes.successButton : classes.errorButton}
          onClick={() => setOpenAddDialog(true)}
        >
          {!createResult && "Create Fund"}
          {createResult === "Success" && "Created"}
          {createResult === "Fail" && "Failed Creating"}
        </Button>
      </Container>
      {openAddDialog && <AddFundDialog open={openAddDialog} handleClose={() => setOpenAddDialog(false)} handleCreateFund={handleCreateFund} />}
      {openEditDialog && FundToEdit && funds && (
        <EditFund fund={FundToEdit} handleClose={handleCloseEditDialog} open={openEditDialog} handleUpdateFund={handleUpdateFund} />
      )}
    </div>
  );
};
