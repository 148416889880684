import { AssetClasses } from "apiclient/AssetClasses";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { IAssetClassReturn } from "apiclient/responsePayloads/AssetClassReturn";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import { FundReturns } from "apiclient/FundReturns";
import { IFundReturn } from "apiclient/responsePayloads/FundReturn";
import { IFund } from "apiclient/responsePayloads/Fund";
import Funds from "apiclient/Funds";

const listToContainer = <T, U>(type: new (d: U) => T, data?: U): T | undefined => {
  return data === undefined ? undefined : new type(data);
};

export const useAssetClasses = (): AssetClasses | undefined => {
  const assetClassesDataList = useSelector<AppState, AssetClassData[] | undefined>(state => state.financeData.assetClasses.data);
  return listToContainer(AssetClasses, assetClassesDataList);
};

export const useAssetClassReturns = (): AssetClassReturns | undefined => {
  const assetClassReturns = useSelector<AppState, IAssetClassReturn[] | undefined>(state => state.financeData.assetClassReturns.data);
  return listToContainer(AssetClassReturns, assetClassReturns);
};

export const useFundReturns = (): FundReturns | undefined => {
  const fundReturns = useSelector<AppState, IFundReturn[] | undefined>(state => state.financeData.fundReturns.data);
  return listToContainer(FundReturns, fundReturns);
};

export const useFunds = (): Funds | undefined => {
  const funds = useSelector<AppState, IFund[] | undefined>(state => state.financeData.funds.data);
  return listToContainer(Funds, funds);
};
