import { AssetClasses } from "apiclient/AssetClasses";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { AssetWeights } from "business/AssetWeights";
import { IFundAllocationOverrides, fundAllocationOverridesFromJSON, FundAllocation } from "business/FundAllocation";
import { getPreProgrammedAssetWeights } from "business/AssetWeights";
import { removeDoubleSlashes } from "util/misc-utils";
import IStringNumberMap from "util/StringNumberMap";

// const simulationJSONSchema = {
//     id: joi.string().min(24),
//     ownerId: joi.string().min(24),
//     createdAt: joi.string().isoDate(),
//     modifiedAt: joi.string().isoDate(),
//     secretToken: joi.string(),
//     name: joi.string(),
//     ownerFirstName: joi.string().allow(''),
//     ownerLastName: joi.string().allow(''),
//     ownerOrganization: joi.string().allow(''),

//     portfolioA: joi.object().keys({
//         portfolioNumber: joi.number().allow(null),
//         customACAllocation: joi.object().allow(null),
//         customFundAllocations: joi.object().allow(null)
//     }),
//     portfolioB: joi.object().keys({
//         portfolioNumber: joi.number().allow(null),
//         customACAllocation: joi.object().allow(null),
//         customFundAllocations: joi.object().allow(null)
//     }),
//     scaleFactorContribution: joi.number().min(0.0).max(1.0),
//     answers: joi.object().keys({
//         investmentHorizon: joi.number(),
//         riskPreferenceAnswers: joi.array().items(joi.number()),
//     }).allow(null), // TODO: answers is deprecated
//     riskLevel: joi.number(),
//     customPortfolioA: joi.object().pattern(/^/, joi.number()).allow(null),
//     customPortfolioB: joi.object().pattern(/^/, joi.number()).allow(null),
// };
interface PortfolioConfiguration {
  portfolioNumber?: number | null;
  customACAllocation?: IStringNumberMap | null;
  customFundAllocations?: { [key: string]: IStringNumberMap } | null;
}

export interface SimulationData {
  id?: string;
  createdAt?: Date;
  modifiedAt?: Date;
  secretToken?: string;
  name?: string;
  ownerId?: string;
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerOrganization?: string;
  portfolioA: PortfolioConfiguration;
  portfolioB: PortfolioConfiguration;
  scaleFactorContribution: number;
  riskLevel?: number;
}

const defaultSimulationData = (): SimulationData => ({
  portfolioA: { portfolioNumber: 3 },
  portfolioB: { portfolioNumber: 3 },
  scaleFactorContribution: 0.0
});
export const newSimulation = () => defaultSimulationData();

function portfolioWithNumber(num: number, assetClasses: AssetClasses, fundAllocations?: IFundAllocationOverrides): AssetClassPortfolio {
  return new AssetClassPortfolio(getPreProgrammedAssetWeights(num - 1), assetClasses, num, fundAllocations);
}

function portfolioWithWeights(
  weights: AssetWeights,
  assetClasses: AssetClasses,
  fundAllocations?: IFundAllocationOverrides
): AssetClassPortfolio {
  return new AssetClassPortfolio(weights, assetClasses, undefined, fundAllocations);
}

function buildPortfolio(
  assetClasses: AssetClasses,
  num?: number,
  weights?: AssetWeights,
  fundAllocations?: IFundAllocationOverrides
): AssetClassPortfolio {
  let portfolio: any = null;
  if (num) {
    portfolio = portfolioWithNumber(num, assetClasses, fundAllocations);
  } else if (weights) {
    portfolio = portfolioWithWeights(weights, assetClasses, fundAllocations);
  } else {
    throw Error("Trying to create portfolio with insufficient data!");
  }
  return portfolio;
}

function customWeights(portfolioConfig: PortfolioConfiguration): AssetWeights | undefined {
  const customACAllocation = portfolioConfig.customACAllocation;
  if (customACAllocation !== null && customACAllocation !== undefined) {
    return new AssetWeights(customACAllocation);
  }
  return undefined;
}

export class Simulation {
  constructor(data: SimulationData = defaultSimulationData()) {
    this.data = data;
  }

  public getSuggestedPortfolioNumber(): number | undefined {
    return this.riskLevel;
  }
  public isSaved = () => this.id !== undefined;
  public clone = (): Simulation => new Simulation({ ...this.data });
  public toJSON(): SimulationData {
    return this.data;
  }

  get id(): string | undefined {
    return this.data.id;
  }
  get name(): string | undefined {
    return this.data.name;
  }
  set name(name: string | undefined) {
    this.data.name = name;
  }
  get ownerId(): string | undefined {
    return this.data.ownerId;
  }
  set ownerId(s: string | undefined) {
    this.data.ownerId = s;
  } // TODO: See if mutability can be avoided
  get ownerFirstName(): string | undefined {
    return this.data.ownerFirstName;
  }
  get ownerLastName(): string | undefined {
    return this.data.ownerLastName;
  }
  get ownerOrganization(): string | undefined {
    return this.data.ownerOrganization;
  }
  get scaleFactorContribution(): number {
    return this.data.scaleFactorContribution;
  }
  get riskLevel(): number | undefined {
    return this.data.riskLevel;
  }
  set riskLevel(level: number | undefined) {
    this.data.riskLevel = level;
  }
  get createdAt(): Date | undefined {
    return this.data.createdAt !== undefined ? new Date(this.data.createdAt) : undefined;
  }
  get modifiedAt(): Date | undefined {
    return this.data.modifiedAt !== undefined ? new Date(this.data.modifiedAt) : undefined;
  }
  get secretToken(): string | undefined {
    return this.data.secretToken;
  }
  get customWeightsA(): AssetWeights | undefined {
    return customWeights(this.data.portfolioA);
  }
  get customWeightsB(): AssetWeights | undefined {
    return customWeights(this.data.portfolioB);
  }

  get portfolioANumber(): number | undefined {
    return this.data.portfolioA.portfolioNumber !== null ? this.data.portfolioA.portfolioNumber : undefined;
  }
  get portfolioBNumber(): number | undefined {
    return this.data.portfolioB.portfolioNumber !== null ? this.data.portfolioB.portfolioNumber : undefined;
  }
  get customFundAllocationsA(): IFundAllocationOverrides {
    return fundAllocationOverridesFromJSON(this.data.portfolioA.customFundAllocations);
  }
  get customFundAllocationsB(): IFundAllocationOverrides {
    return fundAllocationOverridesFromJSON(this.data.portfolioB.customFundAllocations);
  }

  public portfolioA(assetClasses: AssetClasses): AssetClassPortfolio {
    return buildPortfolio(assetClasses, this.portfolioANumber, this.customWeightsA, this.customFundAllocationsA);
  }

  public portfolioB(assetClasses: AssetClasses): AssetClassPortfolio {
    return buildPortfolio(assetClasses, this.portfolioBNumber, this.customWeightsB, this.customFundAllocationsB);
  }

  public hasRiskLevel(): boolean {
    return !!this.riskLevel;
  }

  public getBasePath(lang: string = "en"): string {
    let path = lang !== undefined ? `/${lang}/` : "/";
    if (this.id !== undefined) {
      path += `s/${this.id}/`;
    }
    return removeDoubleSlashes(path);
  }

  public updatePortfolioWithNumber(pc: PortfolioConfiguration, num: number, assetClasses: AssetClasses): AssetClassPortfolio {
    pc.portfolioNumber = num;
    pc.customACAllocation = undefined;
    pc.customFundAllocations = undefined;
    return portfolioWithNumber(num, assetClasses);
  }

  public updatePortfolioAWithNumber(num: number, assetClasses: AssetClasses): AssetClassPortfolio {
    return this.updatePortfolioWithNumber(this.data.portfolioA, num, assetClasses);
  }
  public updatePortfolioBWithNumber(num: number, assetClasses: AssetClasses): AssetClassPortfolio {
    return this.updatePortfolioWithNumber(this.data.portfolioB, num, assetClasses);
  }

  public updatePortfolioWithWeights(pc: PortfolioConfiguration, weights: AssetWeights, assetClasses: AssetClasses) {
    if (pc.portfolioNumber !== undefined) {
      // Switching from a numbered portfolio
      pc.customFundAllocations = undefined;
    }
    pc.portfolioNumber = undefined;
    pc.customACAllocation = weights.data;
    return portfolioWithWeights(weights, assetClasses);
  }

  public updatePortfolioAWithWeights(weights: AssetWeights, assetClasses: AssetClasses) {
    this.updatePortfolioWithWeights(this.data.portfolioA, weights, assetClasses);
  }
  public updatePortfolioBWithWeights(weights: AssetWeights, assetClasses: AssetClasses) {
    this.updatePortfolioWithWeights(this.data.portfolioB, weights, assetClasses);
  }

  public updatePortfolioAWithCustomFundAllocation(forAssetClassId: string, fundAllocation: FundAllocation): void {
    if (this.data.portfolioA.customFundAllocations === undefined || this.data.portfolioA.customFundAllocations === null) {
      this.data.portfolioA.customFundAllocations = {};
    }
    this.data.portfolioA.customFundAllocations[forAssetClassId] = fundAllocation.funds;
  }

  public updatePortfolioBWithCustomFundAllocation(forAssetClassId: string, fundAllocation: FundAllocation): void {
    if (this.data.portfolioB.customFundAllocations === undefined || this.data.portfolioB.customFundAllocations === null) {
      this.data.portfolioB.customFundAllocations = {};
    }
    this.data.portfolioB.customFundAllocations[forAssetClassId] = fundAllocation.funds;
  }

  public getPortfolioANumber = (): number | null => (this.portfolioANumber ? this.portfolioANumber : null);
  public getPortfolioBNumber = (): number | null => (this.portfolioBNumber ? this.portfolioBNumber : null);
  public pdfURL = () => {
    let pdfHost = `${window.location.protocol}//${window.location.host}`; // default
    if (window.location.hostname === "frigg-staging-e1f35da2a362.herokuapp.com") {
      // staging environment
      pdfHost = "https://frigg-staging-e1f35da2a362.herokuapp.com";
    } else if (window.location.hostname === "frigg.carnegiefonder.se" || window.location.hostname === "frigg-prod-81a4cf152730.herokuapp.com") {
      // production environment
      pdfHost = "https://frigg.carnegiefonder.se";
    }
    const result = `${pdfHost}/api/pdf/report?token=${this.secretToken}`;
    return result;
  };

  public getSimulationData() {
    return this.data;
  }

  private data: SimulationData;
}
