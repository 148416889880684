import { UserProfile } from "apiclient/responsePayloads/User";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import Auth from "../../modules/Auth";

/*
 * Action types
 */

export const PROFILE_IS_LOADING = "profile.PROFILE_IS_LOADING";
export const PROFILE_LOAD_SUCCESS = "profile.PROFILE_LOAD_SUCCESS";
export const PROFILE_LOAD_HAS_ERRORED = "profile.PROFILE_LOAD_HAS_ERRORED";
export const PROFILE_IS_SAVING = "profile.PROFILE_IS_SAVING";
export const PROFILE_SAVE_HAS_ERRORED = "profile.PROFILE_SAVE_HAS_ERRORED";
export const PROFILE_JUST_SAVED = "profile.PROFILE_JUST_SAVED";
export const PROFILE_PWD_CHANGING = "profile.PROFILE_PWD_CHANGING";
export const PROFILE_PWD_JUST_CHANGED = "profile.PROFILE_PWD_JUST_CHANGED";
export const PROFILE_PWD_CHANGE_FAILED = "profile.PROFILE_PWD_CHANGE_FAILED";

interface ProfileIsLoading {
  type: typeof PROFILE_IS_LOADING;
  isLoading: boolean;
}

interface ProfileLoadSuccess {
  type: typeof PROFILE_LOAD_SUCCESS;
  user: UserProfile;
}

interface ProfileLoadHasErrored {
  type: typeof PROFILE_LOAD_HAS_ERRORED;
  hasErrored: boolean;
}

interface ProfileIsSaving {
  type: typeof PROFILE_IS_SAVING;
  isSaving: boolean;
}

interface ProfileJustSaved {
  type: typeof PROFILE_JUST_SAVED;
  justSaved: boolean;
}

interface ProfileSaveHasErrored {
  type: typeof PROFILE_SAVE_HAS_ERRORED;
  hasErrored: boolean;
}

interface ProfilePasswordChanging {
  type: typeof PROFILE_PWD_CHANGING;
  isChanging: boolean;
}

interface ProfilePasswordJustChanged {
  type: typeof PROFILE_PWD_JUST_CHANGED;
  justChanged: boolean;
}

interface ProfilePasswordChangeFailed {
  type: typeof PROFILE_PWD_CHANGE_FAILED;
  message?: string;
}

export type ProfileAction =
  | ProfileIsLoading
  | ProfileLoadSuccess
  | ProfileLoadHasErrored
  | ProfileIsSaving
  | ProfileJustSaved
  | ProfileSaveHasErrored
  | ProfilePasswordChanging
  | ProfilePasswordJustChanged
  | ProfilePasswordChangeFailed;

/*
 * Action creators
 */

export function profileIsLoading(isLoading: boolean): ProfileIsLoading {
  return { type: PROFILE_IS_LOADING, isLoading };
}

export function profileLoadSuccess(user: UserProfile): ProfileLoadSuccess {
  return { type: PROFILE_LOAD_SUCCESS, user };
}

export function profileLoadHasErrored(hasErrored: boolean): ProfileLoadHasErrored {
  return { type: PROFILE_LOAD_HAS_ERRORED, hasErrored };
}

export function profileIsSaving(isSaving: boolean): ProfileIsSaving {
  return { type: PROFILE_IS_SAVING, isSaving };
}

export function profileSaveHasErrored(hasErrored: boolean): ProfileSaveHasErrored {
  return { type: PROFILE_SAVE_HAS_ERRORED, hasErrored };
}

export function profileJustSaved(justSaved: boolean): ProfileJustSaved {
  return { type: PROFILE_JUST_SAVED, justSaved };
}

export function profilePasswordChanging(isChanging: boolean): ProfilePasswordChanging {
  return { type: PROFILE_PWD_CHANGING, isChanging };
}

export function profilePasswordJustChanged(justChanged: boolean): ProfilePasswordJustChanged {
  return { type: PROFILE_PWD_JUST_CHANGED, justChanged };
}

export function profilePasswordChangeFailed(message?: string): ProfilePasswordChangeFailed {
  return { type: PROFILE_PWD_CHANGE_FAILED, message };
}

/*
 * Thunks and helpers
 */

function loadProfileAndDispatch(dispatch: Dispatch) {
  dispatch(profileIsLoading(true));

  if (localStorage.getItem("jwtToken")) {
    axios
      .get<UserProfile>("/api/profile")
      .then(res => {
        dispatch(profileIsLoading(false));
        dispatch(profileLoadHasErrored(false));
        dispatch(profileLoadSuccess(res.data));
      })
      .catch((error: AxiosError<{ code: string }>) => {
        if (error.response && error.response.status === 401 && error.response.data.code === "INVALID_ACCESS_TOKEN") {
          // The JWT token is invalid - log user out.
          const auth = new Auth(localStorage);
          auth.logout();
          window.location.reload();
        }
        dispatch(profileIsLoading(false));
        dispatch(profileLoadHasErrored(true));
      });
  }
}

export function profileLoad() {
  return (dispatch: Dispatch) => {
    loadProfileAndDispatch(dispatch);
  };
}

export function profileSave(firstName: string, lastName: string, organization: string) {
  return (dispatch: Dispatch) => {
    dispatch(profileIsSaving(true));
    axios
      .put(`/api/profile`, {
        firstName: firstName,
        lastName: lastName,
        organization: organization
      })
      .then(res => {
        dispatch(profileJustSaved(true));
        dispatch(profileIsSaving(false));
        dispatch(profileSaveHasErrored(false));
        setTimeout(() => {
          dispatch(profileJustSaved(false));
        }, 5000);
        loadProfileAndDispatch(dispatch);
      })
      .catch((error: AxiosError) => {
        dispatch(profileIsSaving(false));
        dispatch(profileSaveHasErrored(true));
        console.log("Save error", error);
      });
  };
}

export function changePassword(currentPassword: string, newPassword: string) {
  return (dispatch: Dispatch) => {
    dispatch(profilePasswordChanging(true));
    axios
      .put(`/api/profile/password`, {
        currentPassword: currentPassword,
        newPassword: newPassword
      })
      .then(res => {
        dispatch(profilePasswordJustChanged(true));
        dispatch(profilePasswordChanging(false));
        setTimeout(() => {
          dispatch(profilePasswordJustChanged(false));
        }, 5000);
      })
      .catch((error: AxiosError<{ message: string }>) => {
        console.log("Save error", error);
        dispatch(profilePasswordChanging(false));
        let message = "Something went wrong when saving password.";
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
        dispatch(profilePasswordChangeFailed(message));
      });
  };
}
// TODO: Write a profilePasswordChange similar to profileSave
