import React from "react";
import { Button, Form, InputOnChangeData, TextAreaProps, Message, Container, Icon } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendRequestAccountEmail } from "apiclient/RequestAccountAPI";
import "./RequestAccountPage.css";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { DesktopOrTablet, Mobile } from "components/util/responsive";
import { IAppLayoutProps } from "components/App/App";
import MainLayout from "components/App/MainLayout";
import { SectionWrap } from "components/SectionWrap/SectionWrap";

interface DialogProps {
  captchaSuccess: boolean;
  captchaToken: string | undefined;
  firstName: string;
  lastName: string;
  currentScreen: "Form" | "Success" | "Fail";
  email: string;
  organization: string;
  message: string;
  errorMessage: string | undefined;
}

export interface RequestAccountPayloadType {
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  message: string;
}

const initialState: DialogProps = {
  captchaSuccess: false,
  captchaToken: undefined,
  firstName: "",
  currentScreen: "Form",
  lastName: "",
  email: "",
  organization: "",
  message: "",
  errorMessage: undefined
};

interface Props {
  layoutProps: IAppLayoutProps;
}

export default class RequestAccountPage extends React.Component<Props> {
  state: DialogProps = initialState;

  public layoutPropsWithTitle = { ...this.props.layoutProps, title: "Request account" };

  handleOpen = () => this.setState({ currentScreen: "Form" });

  handleClose = () => this.setState({ currentScreen: "Form" });

  private isSubmittable = () => {
    return (
      this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.organization.length > 0
    );
  };

  private handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!this.isSubmittable()) {
      return;
    }

    if (!this.state.captchaSuccess) this.setState({ errorMessage: "Complete reCAPTCHA below first" });
    if (this.isSubmittable() && this.state.captchaSuccess && this.state.captchaToken !== null && this.state.captchaToken !== undefined) {
      const sendPayload: RequestAccountPayloadType = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        organization: this.state.organization,
        email: this.state.email,
        message: this.state.message
      };
      sendRequestAccountEmail(sendPayload, this.state.captchaToken)
        .then(() => {
          const state: DialogProps = {
            ...initialState,
            currentScreen: "Success"
          };
          this.setState(state);
        })
        .catch(() => {
          const state: DialogProps = {
            ...this.state,
            captchaSuccess: false,
            captchaToken: undefined,
            currentScreen: "Fail"
          };
          this.setState(state);
        });
    }
  };
  private handleChangeFirstName = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) =>
    this.setState({ firstName: data.value });
  private handleChangeLastName = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) =>
    this.setState({ lastName: data.value });
  private handleChangeEmail = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) =>
    this.setState({ email: data.value });
  private handleChangeOrganization = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) =>
    this.setState({ organization: data.value });
  private handleChangeMessage = (event: React.SyntheticEvent<HTMLTextAreaElement>, data: TextAreaProps) =>
    this.setState({ message: data.value });

  recaptchaOnChange(token: string | null) {
    if (token !== null) {
      this.setState({ captchaSuccess: true, captchaToken: token, errorMessage: undefined });
    } else {
      this.setState({ captchaSuccess: false, captchaToken: undefined, errorMessage: "Verification expired. Please, verify again." });
    }
  }

  render() {
    return (
      <MainLayout {...this.layoutPropsWithTitle}>
        <SectionWrap toned={true}>
          <Container>
            <Container className="content-wrapper">
              {this.state.currentScreen === "Form" && (
                <div className="form-wrapper">
                  <p>
                    We are happy to hear that you want to use our simulation services! Please, fill in form below and we will get back to
                    you!
                  </p>

                  <Form autoComplete="off" id="requestForm" onSubmit={this.handleSubmit}>
                    <DesktopOrTablet>
                      <div className="two fields">
                        <Form.Input
                          className="carnegie__input"
                          label="First Name"
                          name="firstName"
                          required
                          value={this.state.firstName}
                          onChange={this.handleChangeFirstName}
                        />
                        <Form.Input
                          className="carnegie__input"
                          label="Last Name"
                          name="lastName"
                          required
                          value={this.state.lastName}
                          onChange={this.handleChangeLastName}
                        />
                      </div>
                    </DesktopOrTablet>
                    <Mobile>
                      <Form.Input
                        className="carnegie__input"
                        label="First Name"
                        name="firstName"
                        required
                        value={this.state.firstName}
                        onChange={this.handleChangeFirstName}
                      />
                      <Form.Input
                        className="carnegie__input"
                        label="Last Name"
                        name="lastName"
                        required
                        value={this.state.lastName}
                        onChange={this.handleChangeLastName}
                      />
                    </Mobile>

                    <Form.Input
                      fluid={true}
                      label="Email"
                      type="email"
                      name="email"
                      className="carnegie__input"
                      required
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                    />
                    <Form.Input
                      fluid={true}
                      className="carnegie__input"
                      label="Organization"
                      name="organization"
                      required
                      value={this.state.organization}
                      onChange={this.handleChangeOrganization}
                    />
                    <Form.TextArea
                      label="Message (optional)"
                      className="carnegie__input"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChangeMessage}
                    />
                  </Form>

                  {this.state.errorMessage && <Message error>{this.state.errorMessage}</Message>}
                  <div className="recaptcha-wrapper">
                    <p className="robot-label">Prove that you are not a robot</p>
                    <ReCAPTCHA
                      hl="en"
                      sitekey="6LeiftMUAAAAAOTqZEPTKJE9uZpKH-2CC2uzG_G0"
                      onChange={token => this.recaptchaOnChange(token)}
                    />
                  </div>
                  <Grid container>
                    <DesktopOrTablet>
                      <Grid item xs={12}>
                        <Button
                          form="requestForm"
                          style={{ marginTop: 30 }}
                          className="carnegie__button"
                          type="submit"
                          disabled={!this.isSubmittable() || this.state.errorMessage !== undefined}
                        >
                          Send Request
                        </Button>
                      </Grid>
                    </DesktopOrTablet>
                    <Mobile>
                      <Grid item xs={12}>
                        <Button
                          form="requestForm"
                          className="carnegie__button"
                          style={{ marginTop: 30 }}
                          type="submit"
                          disabled={!this.isSubmittable() || this.state.errorMessage !== undefined}
                        >
                          Send Request
                        </Button>
                      </Grid>
                    </Mobile>

                    <Grid item xs={12}>
                      <div className="already-registered">
                        <p>
                          Already have an account? <Link to="/login">Log in</Link>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className="form-wrapper">
                {this.state.currentScreen === "Success" && (
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "inline-block" }}>
                        <Icon name="smile outline" size="massive" />
                      </div>
                      <div style={{ display: "inline-block", marginTop: 20 }}>
                        <h3>Thank you!</h3>
                        <p>We recieved your request and will get back to you as soon as we can!</p>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.currentScreen === "Fail" && (
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "inline-block" }}>
                      <Icon name="frown outline" size="massive" />
                    </div>
                    <div style={{ display: "inline-block", marginTop: 20 }}>
                      <h3>Uh-oh!</h3>
                      <p>Something went wrong... Try again in a few minutes!</p>
                    </div>
                  </div>
                )}
                {this.state.currentScreen === "Fail" && (
                  <Button
                    style={{ float: "right", margin: "10px 0 50px 0" }}
                    onClick={() => this.setState({ currentScreen: "Form" })}
                    primary
                  >
                    Ok
                  </Button>
                )}
                {this.state.currentScreen === "Success" && (
                  <Link to="/en/login">
                    <Button style={{ float: "right", margin: "10px 0 50px 0" }} primary>
                      Ok
                    </Button>
                  </Link>
                )}
              </div>
            </Container>
          </Container>
        </SectionWrap>
      </MainLayout>
    );
  }
}
