import React from "react";
import { Grid, Button, Typography, List, Divider, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import USDIcon from "@material-ui/icons/MonetizationOn";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers";

interface ListFundsProps {
  handleRemoveFund: (fund: string) => void;
  funds: string[];
}

export const ListFunds: React.FC<ListFundsProps> = props => {
  const { handleRemoveFund, funds } = props;
  const existingFunds = useSelector((state: AppState) => state.financeData.funds.data);

  return (
    <Grid container item xs={12}>
      {funds[0] !== undefined ? (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="overline">Funds</Typography>
          </Grid>
          <Grid item xs={12}>
            <List style={{ paddingBottom: 0 }}>
              <Divider />
              {existingFunds &&
                funds.map((fund, index) => {
                  const matchedFund = existingFunds.find(f => f.id === fund);

                  return (
                    <div key={index}>
                      <ListItem>
                        <ListItemIcon>
                          <USDIcon />
                        </ListItemIcon>
                        <ListItemText primary={matchedFund ? matchedFund.name : fund} />
                        <ListItemSecondaryAction>
                          <Button variant="outlined" onClick={() => handleRemoveFund(fund)}>
                            Remove
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="secondary" style={{ marginBottom: 5 }}>
            *Asset class is required to have at least one fund. Add one below.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
