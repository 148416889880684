import React from "react";
import { IRouterProps } from "util/RouterPropsInterface";
import { Container, Message } from "semantic-ui-react";
import { SectionWrap } from "components/SectionWrap/SectionWrap";
import { requestPasswordReset } from "apiclient/ResetPasswordAPI";
import MainLayout from "components/App/MainLayout";
import { RecoverPasswordForm } from "./ForgotPasswordForm";
import { IAppLayoutProps } from "components/App/App";

import "./ForgotPasswordPage.css";

interface Props {
  layoutProps: IAppLayoutProps;
}

export const ForgotPasswordPage: React.FC<Props & IRouterProps> = props => {
  const [view, setView] = React.useState<"success" | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const layoutPropsWithTitle = { ...props.layoutProps, title: "Inst simulation - Reset password" };

  const handleSubmit = (email: string) => {
    requestPasswordReset(email)
      .then(res => setView("success"))
      .catch(() => setErrorMessage("This user does not exist."));
  };

  return (
    <main>
      <MainLayout {...layoutPropsWithTitle}>
        <SectionWrap toned={true} className="huge-padding-top">
          {view !== "success" ? (
            <Container text={true}>
              <div className="reset-password-wrap">
                <h1 className="carnegie__title">Forgot your password?</h1>
                <p style={{ paddingBottom: 20 }}>Enter your email and we'll send you a link to reset your password.</p>
                <RecoverPasswordForm onClick={handleSubmit} />

                {errorMessage && <Message warning={true}>{errorMessage}</Message>}
              </div>
            </Container>
          ) : (
            <Container text={true}>
              <div className="reset-password-wrap">
                <h1>Please check your inbox!</h1>
                <p>An email containing instructions has been sent to you.</p>
                <br /><br />
                <strong>IMPORTANT: If you have not received the email within 2 minutes, be sure to check your spam folder.</strong>
              </div>
            </Container>
          )}
        </SectionWrap>
      </MainLayout>
    </main>
  );
};
