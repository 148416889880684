import PortfolioSimulator from "business/PortfolioSimulator";
import { AssetClassPortfolio } from "business/AssetClassPortfolio";
import { primaryPortfolioColor } from "util/colors";

// TODO: Replace any types with real types !!!

/**
 * Builds graph data for forward simulation of a portfolio's value development.
 *
 * @param numYears Number of years to simulate
 * @param portfolio Portfolio on which to run simulation
 * @param investedAmount Initial value of portfolio at year 0.
 * @param scaleFactorContribution If `scaleFactors` supplied, how much should it contribute? (Value between 0 - 1)
 * @param scaleFactors A list of scale factors to turn forward simulation into empiric distribution instead of normal distribution. Optional.
 * @returns {{labels: string[], datasets: Array}}
 */
function buildForwardSimulationGraphData(
  numYears: number,
  portfolio: AssetClassPortfolio,
  investedAmount: number,
  scaleFactorContribution: number = 0,
  lineColor: any = primaryPortfolioColor,
  borderWidth: number = 3
): Chart.ChartData {
  const data: Chart.ChartData = {};
  data.labels = [];
  data.datasets = [];

  for (let yr = 1; yr <= numYears; yr++) {
    data.labels.push(`Year ${yr}`);
  }

  if (portfolio) {
    const simulator = new PortfolioSimulator(portfolio.calculateRisk(), portfolio.calculateYield());

    const referenceDataset: Chart.ChartDataSets = {};
    referenceDataset.label = "Invested amount";
    referenceDataset.fill = false;
    referenceDataset.backgroundColor = "rgba(170, 170, 170, 1.0)";
    referenceDataset.borderColor = "rgba(170, 170, 170, 1.0)";
    referenceDataset.borderWidth = 1;
    referenceDataset.borderDash = [5, 5];
    referenceDataset.pointRadius = 0;
    referenceDataset.data = [] as number[];

    const expectedDataset: Chart.ChartDataSets = {};
    expectedDataset.label = "Expected result";
    expectedDataset.fill = false;
    expectedDataset.backgroundColor = lineColor;
    expectedDataset.borderColor = lineColor;
    expectedDataset.borderWidth = borderWidth;
    expectedDataset.pointRadius = 2;
    expectedDataset.data = [] as number[];

    const lowDataset: Chart.ChartDataSets = {};
    lowDataset.label = "Worst outcome";
    lowDataset.borderColor = "rgba(170, 170, 170, 1.0)";
    lowDataset.borderWidth = borderWidth;
    lowDataset.backgroundColor = "rgba(170, 170, 170, 0.15)";
    lowDataset.pointRadius = 2;
    lowDataset.fill = false;
    lowDataset.data = [] as number[];

    const highDataset: Chart.ChartDataSets = {};
    highDataset.label = "Best outcome";
    highDataset.borderColor = "rgba(170, 170, 170, 1.0)";
    highDataset.borderWidth = borderWidth;
    highDataset.backgroundColor = "rgba(170, 170, 170, 0.15)";
    highDataset.pointRadius = 2;
    highDataset.fill = "-1"; // fill until previous dataset
    highDataset.data = [] as number[];

    for (let year = 0; year <= numYears; year++) {
      expectedDataset.data.push(simulator.getExpectedResult(investedAmount, year));
      lowDataset.data.push(simulator.getLowResult(investedAmount, year, scaleFactorContribution));
      highDataset.data.push(simulator.getHighResult(investedAmount, year, scaleFactorContribution));
      referenceDataset.data.push(investedAmount);
    }

    data.datasets!.push(referenceDataset);
    data.datasets!.push(expectedDataset);
    data.datasets!.push(lowDataset);
    data.datasets!.push(highDataset);
  }

  return data;
}

function getForwardSimulationKeyValues(portfolio: AssetClassPortfolio, investedAmount: number, year: number) {
  const simulator = new PortfolioSimulator(portfolio.calculateRisk(), portfolio.calculateYield());
  return {
    invested: investedAmount,
    expected: simulator.getExpectedResult(investedAmount, year),
    low: simulator.getLowResult(investedAmount, year),
    high: simulator.getHighResult(investedAmount, year),
    year: year
  };
}

function buildForwardSimChartOptions(
  expectedIndex: number,
  lowIndex: number,
  highIndex: number,
  yAxisMax: number,
  onHoverExpectedAmountCallback?: (year: number, expectedAmount: number) => void,
  onHoverLowAmountCallback?: (year: number, expectedAmount: number) => void,
  onHoverHighAmountCallback?: (year: number, expectedAmount: number) => void
): Chart.ChartOptions {
  return {
    legend: {
      display: false
    },
    animation: {
      duration: 300
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, tooltipData: any) {
          switch (tooltipItem.datasetIndex) {
            case expectedIndex:
              if (onHoverExpectedAmountCallback) {
                onHoverExpectedAmountCallback(tooltipItem.index, tooltipItem.yLabel);
              }
              break;
            case lowIndex:
              if (onHoverLowAmountCallback) {
                onHoverLowAmountCallback(tooltipItem.index, tooltipItem.yLabel);
              }
              break;
            case highIndex:
              if (onHoverHighAmountCallback) {
                onHoverHighAmountCallback(tooltipItem.index, tooltipItem.yLabel);
              }
              break;
          }
          return `${tooltipData.datasets[tooltipItem.datasetIndex].label}: ${Math.round(tooltipItem.yLabel)}`;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: yAxisMax,
            min: 0
          }
        }
      ]
    }
  };
}

export { buildForwardSimulationGraphData, getForwardSimulationKeyValues, buildForwardSimChartOptions };
